import {
  getMaxDD,
  getMaxDDPercent,
} from "../functions/calculations_for_metrics/getmaxDD";
import dollarCostAverage from "./../functions/dollarCostAvg";
import { profitLossCalculation } from "./../functions/profitLossCalculation";
import { returnNetPLDollar } from "./../functions/profitLossCalculation";
import longShortDelineation from "../functions/longShortDelineation";
import { getDateDifferencewSeconds } from "./../functions/getDateDifference";
import { handleNANGOOD } from "./../functions/handleUndefined";
import calculateZScore from "./../functions/zScoreCalculation";
import {
  getEndDateTime,
  getStartDateTime,
} from "./../functions/getStartorEndDateTime";
import getEntryExitLots from "./../functions/getEntryExitLots";
import getStandardDeviation from "./../functions/calculations_for_metrics/standardDeviation";
import linearRegression from "./../functions/calculations_for_metrics/linearRegression";
import pcorr from "./../functions/calculations_for_metrics/pearsonCorrelation";
import STEYX from "./../functions/calculations_for_metrics/steyx";
import DEVSQ from "./../functions/calculations_for_metrics/DEVSQ";
import {
  calculateSharpeRatios,
  calculateSortinoRatios,
} from "./../functions/sharpeRatio";
var { jStat } = require("jstat");

function studentsTCumulativeValue(tValue, degreesOfFreedom) {
  // Calculate the cumulative t-distribution
  let cumulativeValue = jStat.studentt.cdf(tValue, degreesOfFreedom);

  // Return the result
  return cumulativeValue;
}
const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

// Helper function to calculate week of the year
function getWeek(date) {
  let firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  let pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return (
    date.getFullYear() +
    "-" +
    Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
  );
}
function calculateAverage(total, count) {
  let perAvg = 0;
  let totalCount = 0;
  for (let key in total) {
    perAvg += total[key];
    totalCount += 1;
  }
  return perAvg / totalCount;
}
// --------- *********IMPORTANT********* ---------

// ** calculations that are open an have no exit executions can be omitted from all calculations

//------------------------------------------------
//const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);
export const dashboardCalculations = (
  data,
  calctype,
  start_Balance,
  start_DW,
  riskFreeRate,
  dateFilterBy,
  SP500Data
) => {
  let result = {};
  let pLArr = [];
  let pLWinArr = [];
  let pLLossArr = [];
  const sp500Data = SP500Data;
  let rMultipleWins = [];
  let rMultipleLosses = [];
  let rrrarr = [];

  //let totalLossTrades = 0;

  let totalPL_dollar = 0;
  let totalPL_percent = 0;
  let tradefreqperday = 0;
  let tradefreqperweek = 0;
  let tradefreqpermonth = 0;
  let tradefreqperyear = 0;
  var dailyPL = {},
    dailyCount = {},
    weeklyPL = {},
    weeklyCount = {},
    monthlyPL = {},
    monthlyCount = {},
    yearlyPL = {},
    yearlyCount = {};
  let totalProfits = 0;
  let totalLosses = 0;
  let totalTrades = 0;
  let totalWinningTrades = 0;
  let totalLosingTrades = 0;
  let totalStockTrades = 0;
  let totalOptionTrades = 0;
  let totalFutureTrades = 0;
  let totalForexTrades = 0;
  let totalCFDTrades = 0;
  let totalCryptoTrades = 0;

  let tfIndex = 0;
  let totalBEs = 0;
  let avgRRR = 0;
  let avgRRRvalueonly = 0;
  let zScore = 0;
  let percentgainArray = [];
  let percentgainLOSSArray = [];
  let percentgainWINArray = [];
  let winlossesbe = {};
  //let miscellaneousStdDevPerTradePercenttemparray = [];
  //let adjfullaccountbalancearr = [];
  let fullaccountbalancearr = [];
  let fullaccountbalancearrwithDates = [];
  let totalDW = start_DW;
  // Variables to init before the loop
  let riskedPercentArr = [];
  let iterationarray = [];

  let avgEfficiencyArr = [];
  let timeInMarketArr = [];
  let timeInMarket = 0;
  let profitlossRatioArr = [];
  let profitFactorArr = [];
  let recoveryFactorArr = [];
  let expectationArr = [];
  let ulcerIndexArr = [];
  let volatilityArr = [];
  let percentConfidenceArr = [];
  let KRatioArr = [];
  let LRCorrelationArr = [];
  let LRStandardErrorArr = [];
  let arithmeticSharpeRatioArr = [];
  let geometricSharpeRatioArr = [];
  let arithmeticSortinoRatioArr = [];
  let geometricSortinoRatioArr = [];
  let cpcArr = [];
  let positionSizeArr = [];
  let avgpositionSizeArr = [];
  let zScoreArr = [];
  let statisticalZScoreArr = [];

  let avgPLArr = [];
  let avgPLWinArr = [];
  let avgPLLossArr = [];
  let avgHoldingTimeArr = [];
  let tiqGoldenRatioArr = [];
  let twentyfourhrArr = [];
  let tfIndexArr = [];
  let avgRRRArr = [];
  let rMultipleArr = [];
  let probChancearr = [];
  let standardDeviationArr = [];
  let onlyTrades = [];
  const drawdownFunction = (equityCurve) => {
    // Initialisations
    var highWaterMark = -Infinity;

    // Other initialisations
    let ddVectorNew = [];

    // Loop over all the values to compute the drawdown vector
    for (var i = 0; i < equityCurve.length; ++i) {
      highWaterMark =
        equityCurve[i] > highWaterMark ? equityCurve[i] : highWaterMark;
      ddVectorNew.push(
        ((highWaterMark - equityCurve[i]) / highWaterMark) * 100
      );
    }
    // Return the computed vector
    return ddVectorNew;
  };
  const ulcerIndexFunction = (equityCurve) => {
    // Compute the drawdown function
    var ddFunc = drawdownFunction(equityCurve);
    const handleNANs = (value) => {
      if (
        isNaN(value) ||
        value === null ||
        value === Infinity ||
        value === undefined ||
        value === ""
      ) {
        return true;
      } else {
        return false;
      }
    };
    // Compute the sum of squares of this function
    var sumSquares = 0.0;
    for (var i = 0; i < ddFunc.length; ++i) {
      if (handleNANs(ddFunc[i])) {
        sumSquares += 0;
      } else {
        sumSquares += ddFunc[i] * ddFunc[i];
      }
    }
    const returnedValue = Math.sqrt(sumSquares / ddFunc.length);
    // Compute and return the ulcer index
    return returnedValue;
  };

  // Main For Loop
  for (let i = 0, j = data.length; i < j; i++) {
    const thisAccountBalance = Number(data[i].balance);
    const entryId = data[i].entryId;
    let orderType = data[i].entry.orderType;
    let multiExecution = data[i].entry.multiExecution;
    let exitExecution = data[i].entry.exitExecution;
    const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    const correctedSUM = returnNetPLDollar(
      data[i].entry.profitLoss,
      data[i].entry.commissions,
      data[i].entry.fees,
      calctype
    );

    if (
      data[i].entry.orderType === "Deposit" ||
      data[i].entry.orderType === "Withdrawal" ||
      data[i].entry.orderType === "Funding Payment" ||
      data[i].entry.orderType === "Commit" ||
      data[i].entry.orderType === "Approval" ||
      data[i].entry.orderType === "Wrap" ||
      data[i].entry.orderType === "Self"
    ) {
      // only pushing these because the there needs to be values shown on the graphs instead of blanks
      // we can push these because these do not affect calculations. Do not push these values for arrays used in calculations.
      totalDW += correctedSUM;
      //adjfullaccountbalancearr.shift();
      //adjfullaccountbalancearr.unshift(totalDW);
      continue;
    } else if (exitLots === 0) {
      continue; // exclude trades with no exit executions
    } else {
      iterationarray.push(i);
      fullaccountbalancearr.push(thisAccountBalance);
      totalPL_dollar += correctedSUM;
      totalTrades += 1;
      //adjfullaccountbalancearr.push(thisAccountBalance);
      pLArr.push(correctedSUM);
      if (correctedSUM > 0) {
        pLWinArr.push(correctedSUM);
      } else if (correctedSUM < 0) {
        pLLossArr.push(correctedSUM);
      }
      onlyTrades.push(data[i]);
      let percentgain =
        (correctedSUM / (thisAccountBalance - correctedSUM)) * 100;
      percentgainArray.push(percentgain);
      if (percentgain < 0) {
        percentgainLOSSArray.push(percentgain);
      } else if (percentgain > 0) {
        percentgainWINArray.push(percentgain);
      }
      if (correctedSUM > 0) {
        totalWinningTrades += 1;
        totalProfits += correctedSUM;
      } else if (correctedSUM < 0) {
        totalLosingTrades += 1;
        totalLosses += correctedSUM;
      } else if (correctedSUM === 0) {
        totalBEs += 1;
      }

      // Count assetClasses
      if (
        data[i].entry.selectedPortfolioType === "Stocks" ||
        data[i].entry.selectedPortfolioType === "Exercise Stock" ||
        data[i].entry.selectedPortfolioType === "Assign Stock"
      ) {
        totalStockTrades += 1;
      } else if (data[i].entry.selectedPortfolioType === "Options") {
        totalOptionTrades += 1;
      } else if (data[i].entry.selectedPortfolioType === "Futures") {
        totalFutureTrades += 1;
      } else if (data[i].entry.selectedPortfolioType === "Forex") {
        totalForexTrades += 1;
      } else if (data[i].entry.selectedPortfolioType === "CFD") {
        totalCFDTrades += 1;
      } else if (data[i].entry.selectedPortfolioType === "Crypto") {
        totalCryptoTrades += 1;
      }
      // Some metrics must be calculated inside this if statement
      const endDateTimez = getEndDateTime(exitExecution);
      const startDateTimez = getStartDateTime(multiExecution);

      let eachStartDateTime = new Date(startDateTimez); // or startDateTimez
      let eachEndDateTime = new Date(endDateTimez);
      let neutralDateTime =
        dateFilterBy === "Trade Open" ? eachStartDateTime : eachEndDateTime;
      // Format as 'YYYY-MM-DD' using local time
      let day =
        neutralDateTime.getFullYear() +
        "-" +
        ("0" + (neutralDateTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + neutralDateTime.getDate()).slice(-2);

      // Assuming getWeek is defined to calculate the week number
      let week = getWeek(neutralDateTime); // Make sure this calculation is what you expect in local time

      // Format as 'YYYY-MM' using local time
      let month =
        neutralDateTime.getFullYear() +
        "-" +
        ("0" + (neutralDateTime.getMonth() + 1)).slice(-2);
      let year = neutralDateTime.getFullYear().toString();

      // Calculate daily P/L
      dailyPL[day] = (dailyPL[day] || 0) + correctedSUM;
      dailyCount[day] = (dailyCount[day] || 0) + 1;
      // Calculate weekly P/L
      weeklyPL[week] = (weeklyPL[week] || 0) + correctedSUM;
      weeklyCount[week] = (weeklyCount[week] || 0) + 1;

      // Calculate monthly P/L
      monthlyPL[month] = (monthlyPL[month] || 0) + correctedSUM;
      monthlyCount[month] = (monthlyCount[month] || 0) + 1;

      // Yearly calculations
      yearlyPL[year] = (yearlyPL[year] || 0) + correctedSUM;
      yearlyCount[year] = (yearlyCount[year] || 0) + 1;

      // COMPUTE TIME IN MARKET
      let eachTime = (eachEndDateTime - eachStartDateTime) / 1000;
      timeInMarketArr.push(eachTime);

      avgHoldingTimeArr.push({
        value: arrAvg(timeInMarketArr),
        id: entryId,
      });
      timeInMarket += eachTime;
      // ---------------------

      // Initialize stuff for below calculations
      let avgEntry = dollarCostAverage(multiExecution, "entry", orderType);
      let avgExit = dollarCostAverage(exitExecution, "exit", orderType);
      let rmultiple =
        (avgExit - avgEntry) / (avgEntry - Number(data[i].entry.stopLoss));
      let RRR =
        (Number(data[i].entry.takeProfit) - avgEntry) /
        (avgEntry - Number(data[i].entry.stopLoss));
      rrrarr.push(RRR);
      avgRRRArr.push({
        value: arrAvg(rrrarr),
        id: entryId,
      });
      if (correctedSUM > 0) {
        rMultipleWins.push(rmultiple);
      } else if (correctedSUM < 0) {
        rMultipleLosses.push(rmultiple);
      }
      let rmultipleratio = (
        arrAvg(rMultipleWins) / Math.abs(arrAvg(rMultipleLosses))
      ).toFixed(3);
      rMultipleArr.push({
        value: rmultipleratio,
        id: entryId,
      });
      // ---------------------

      // COMPUTE AVG EFFICIENCY
      let efficiency = 0;
      if (
        longShortDelineation(data[i].entry.orderType, multiExecution) === "Long"
      ) {
        efficiency =
          100 *
          ((avgExit - avgEntry) /
            (data[i].entry.maxFavEx - data[i].entry.maxAdEx));
      } else if (
        longShortDelineation(data[i].entry.orderType, multiExecution) ===
        "Short"
      ) {
        efficiency =
          100 *
          ((avgEntry - avgExit) /
            (data[i].entry.maxAdEx - data[i].entry.maxFavEx));
      }
      avgEfficiencyArr.push(efficiency);
      // ---------------------

      // COMPUTE RISK %
      let adjrrr = -avgEntry / (avgEntry - Number(data[i].entry.stopLoss));

      let divisor =
        data[i].entry.selectedPortfolioType === "Forex" ? rmultiple : adjrrr;

      let initPl = profitLossCalculation(
        multiExecution,
        [],
        orderType,
        data[i].entry.selectedPortfolioType,
        data[i].entry.symbol.pointValue,
        true
      );
      // If the asset class if forex we have to calculate risk a slightly different way
      let dividend =
        data[i].entry.selectedPortfolioType === "Forex"
          ? correctedSUM
          : returnNetPLDollar(
              initPl,
              data[i].entry.commissions,
              data[i].entry.fees,
              calctype
            );
      let percent =
        Number(dividend / (thisAccountBalance - Number(correctedSUM))) * 100;
      let riskedPercent = percent / divisor;
      riskedPercentArr.push(riskedPercent);

      // ---------------------
      let pushThis = {
        amount: correctedSUM,
        balance: thisAccountBalance,
        startDate: eachStartDateTime,
        endDate: eachEndDateTime,
        id: entryId,
      };
      fullaccountbalancearrwithDates.push(pushThis);

      // Per iteration calculations, mainly for graphs
      let perzScore = calculateZScore(fullaccountbalancearr).zScore;
      let perstatisticalZScore = calculateZScore(
        fullaccountbalancearr
      ).statisticalZScore;

      zScoreArr.push({
        value: Number(perzScore),
        id: entryId,
      });
      statisticalZScoreArr.push({
        value: Number(perstatisticalZScore),
        id: entryId,
      });
      let perpercentConfidence = calculateZScore(
        fullaccountbalancearr
      ).confidence;
      percentConfidenceArr.push({
        value: Number(perpercentConfidence),
        id: entryId,
      });
      const positionSize = multiExecution.reduce(
        (acc, exec) => acc + exec.lotSize,
        0
      );
      positionSizeArr.push(positionSize);
      avgpositionSizeArr.push({
        value: arrAvg(positionSizeArr),
        id: entryId,
      });
      let peraveragePLPerWindollar = totalProfits / totalWinningTrades;
      let peraveragePLPerLossdollar = totalLosses / totalLosingTrades;
      let peraveragePLPerTradepercent = arrAvg(percentgainArray);
      let peraveragePLPerLosspercent = arrAvg(percentgainLOSSArray);
      let perstdDevperTradepercent = getStandardDeviation(percentgainArray);
      let perexpectation =
        peraveragePLPerTradepercent / Math.abs(peraveragePLPerLosspercent);

      expectationArr.push({ value: perexpectation, id: entryId });
      let perulcerIndex = ulcerIndexFunction(fullaccountbalancearr);
      ulcerIndexArr.push({ value: perulcerIndex, id: entryId });

      let perprobabilityOfRandomChance =
        totalTrades - 1 <= 0
          ? 0
          : (1 -
              studentsTCumulativeValue(
                peraveragePLPerTradepercent /
                  (perstdDevperTradepercent / Math.sqrt(totalTrades - 1)),
                totalTrades - 1
              )) *
            100;
      probChancearr.push({
        value: perprobabilityOfRandomChance,
        id: entryId,
      });
      let perProfitLossRatio =
        peraveragePLPerWindollar / Math.abs(peraveragePLPerLossdollar);
      profitlossRatioArr.push({ value: perProfitLossRatio, id: entryId });
      let perprofitFactor = totalProfits / Math.abs(totalLosses);
      profitFactorArr.push({ value: perprofitFactor, id: entryId });
      let perrecoveryFactor =
        totalPL_dollar / Math.abs(getMaxDD(fullaccountbalancearr));
      recoveryFactorArr.push({ value: perrecoveryFactor, id: entryId });
      let perCPCIndex =
        perprofitFactor *
        perProfitLossRatio *
        (totalWinningTrades / totalTrades);
      cpcArr.push({
        value: perCPCIndex,
        id: entryId,
      });
      let tiqGoldenRatioinner = (perProfitLossRatio / arrAvg(rrrarr))?.toFixed(
        3
      );
      tiqGoldenRatioArr.push({
        value: Number(tiqGoldenRatioinner),
        id: entryId,
      });
      avgPLArr.push({
        value: arrAvg(pLArr),
        id: entryId,
      });

      if (Number(correctedSUM) > 0) {
        avgPLWinArr.push({
          value: arrAvg(pLWinArr),
          id: entryId,
        });
      } else if (Number(correctedSUM) < 0) {
        avgPLLossArr.push({
          value: arrAvg(pLLossArr),
          id: entryId,
        });
      }

      let standardDeviationPer = getStandardDeviation(pLArr);

      let volatilityPer = standardDeviationPer / totalTrades;

      volatilityArr.push({ value: volatilityPer, id: entryId });

      let tfIndexPer =
        (totalWinningTrades / totalTrades) * (1 + perProfitLossRatio);
      tfIndexArr.push({
        value: tfIndexPer,
        id: entryId,
      });
      standardDeviationArr.push({
        value: standardDeviationPer,
        id: entryId,
      });
      let arithmeticSharpeRatioPer = handleNANGOOD(
        calculateSharpeRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        )?.arithmeticSharpeRatio?.toFixed(3),
        "-"
      );
      arithmeticSharpeRatioArr.push({
        value: arithmeticSharpeRatioPer,
        id: entryId,
      });
      let geometricSharpeRatioPer = handleNANGOOD(
        calculateSharpeRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        )?.geometricSharpeRatio?.toFixed(3),
        "-"
      );
      geometricSharpeRatioArr.push({
        value: geometricSharpeRatioPer,
        id: entryId,
      });
      let arithmeticSortinoRatioPer = handleNANGOOD(
        calculateSortinoRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        )?.arithmeticSortinoRatio?.toFixed(3),
        "-"
      );
      arithmeticSortinoRatioArr.push({
        value: arithmeticSortinoRatioPer,
        id: entryId,
      });
      let geometricSortinoRatioPer = handleNANGOOD(
        calculateSortinoRatios(
          fullaccountbalancearr,
          Number(riskFreeRate)
        )?.geometricSortinoRatio?.toFixed(3),
        "-"
      );
      geometricSortinoRatioArr.push({
        value: geometricSortinoRatioPer,
        id: entryId,
      });
      let lineararrPer = [];
      const linearRegressionCalcPer = linearRegression(
        fullaccountbalancearr,
        iterationarray
      );
      const slopePer = linearRegressionCalcPer.slope;
      const interceptPer = linearRegressionCalcPer.intercept;
      for (let iu = 0, ju = fullaccountbalancearr.length; iu < ju; iu++) {
        let lineararrentry = slopePer * iu + interceptPer;
        lineararrPer.push(lineararrentry);
      }
      let LRCorrelationPer = handleNANGOOD(
        pcorr(fullaccountbalancearr, lineararrPer)?.toFixed(3),
        "-"
      );
      LRCorrelationArr.push({
        value: LRCorrelationPer,
        id: entryId,
      });
      let LRStandardErrorPer = handleNANGOOD(
        STEYX(fullaccountbalancearr, lineararrPer)?.toFixed(3),
        "-"
      );
      LRStandardErrorArr.push({
        value: LRStandardErrorPer,
        id: entryId,
      });
      let KRatioPer = handleNANGOOD(
        (
          slopePer /
          ((STEYX(fullaccountbalancearr, lineararrPer) /
            Math.sqrt(DEVSQ(lineararrPer))) *
            totalTrades)
        )?.toFixed(3),
        "-"
      );
      KRatioArr.push({
        value: KRatioPer,
        id: entryId,
      });
      // ---------------------
    }

    // ---------------------
  }

  // Initiate total time between first entry and last exit
  const firstTrade = onlyTrades[0];
  const lastTrade = onlyTrades[onlyTrades.length - 1];
  let multiExecutionlastTrade = lastTrade ? lastTrade.entry.multiExecution : [];
  let exitExecutionlastTrade = lastTrade ? lastTrade.entry.exitExecution : [];
  const entryExitValueslastTrade = getEntryExitLots(
    multiExecutionlastTrade,
    exitExecutionlastTrade
  );

  // Calculate total trading time
  const totalTradingTime =
    lastTrade && firstTrade
      ? ((entryExitValueslastTrade.entryLots !==
        entryExitValueslastTrade.exitLots //handles open trades
          ? Date.now()
          : new Date(lastTrade.entry.endDateTime)) -
          new Date(firstTrade.entry.startDateTime)) /
        1000
      : 0;

  // Metric calculations after end of loop
  totalPL_percent = (totalPL_dollar / totalDW) * 100;
  let averagePLPerTradepercent = arrAvg(percentgainArray);
  let averagePLPerLosspercent = arrAvg(percentgainLOSSArray);

  let stdDevperTradepercent = getStandardDeviation(percentgainArray);
  let averagePLPerWindollar = totalProfits / totalWinningTrades;
  let averagePLPerLossdollar = totalLosses / totalLosingTrades;
  let winRate = handleNANGOOD(
    (totalTrades === 0 ? 0 : (totalWinningTrades / totalTrades) * 100)?.toFixed(
      3
    ),
    "-"
  );
  let maxDrawdownPercent = handleNANGOOD(
    (totalTrades === 0 ? 0 : getMaxDDPercent(fullaccountbalancearr))?.toFixed(
      3
    ),
    "-"
  );
  let avgRisk = handleNANGOOD(arrAvg(riskedPercentArr)?.toFixed(3), "-");
  let avgEfficiency = handleNANGOOD(arrAvg(avgEfficiencyArr)?.toFixed(3), "-");
  let percentTimeinMarket = handleNANGOOD(
    ((timeInMarket / totalTradingTime) * 100)?.toFixed(3),
    "-"
  );
  let probabilityOfRandomChance =
    totalTrades - 1 <= 0
      ? 0.0
      : handleNANGOOD(
          (
            (1 -
              studentsTCumulativeValue(
                averagePLPerTradepercent /
                  (stdDevperTradepercent / Math.sqrt(totalTrades - 1)),
                totalTrades - 1
              )) *
            100
          )?.toFixed(3),
          "-"
        );
  avgRRR = handleNANGOOD(arrAvg(rrrarr)?.toFixed(3), "-") + " : 1";
  avgRRRvalueonly = handleNANGOOD(arrAvg(rrrarr)?.toFixed(3), "-");
  let rmultipleratio = (
    arrAvg(rMultipleWins) / Math.abs(arrAvg(rMultipleLosses))
  )?.toFixed(3);
  let profitLossRatio = handleNANGOOD(
    (averagePLPerWindollar / Math.abs(averagePLPerLossdollar))?.toFixed(3),
    "-"
  );
  let recoveryFactor = handleNANGOOD(
    (totalPL_dollar / Math.abs(getMaxDD(fullaccountbalancearr)))?.toFixed(3),
    "-"
  );
  let expectation = handleNANGOOD(
    (averagePLPerTradepercent / Math.abs(averagePLPerLosspercent))?.toFixed(3),
    3
  );

  let profitFactor = handleNANGOOD(
    (totalProfits / Math.abs(totalLosses))?.toFixed(3),
    "-"
  );
  let CPCIndex = handleNANGOOD(
    (
      profitFactor *
      profitLossRatio *
      (totalWinningTrades / totalTrades)
    )?.toFixed(3),
    "-"
  );
  let ulcerIndex = handleNANGOOD(
    ulcerIndexFunction(fullaccountbalancearr)?.toFixed(3),
    "-"
  );

  let tiqGoldenRatio = handleNANGOOD(
    (
      averagePLPerWindollar /
      Math.abs(averagePLPerLossdollar) /
      arrAvg(rrrarr)
    ).toFixed(3),
    "-"
  );
  let percentConfidence = handleNANGOOD(
    calculateZScore(fullaccountbalancearr).confidence?.toFixed(3),
    "-"
  );
  let statisticalZScore = handleNANGOOD(
    calculateZScore(fullaccountbalancearr).statisticalZScore?.toFixed(3),
    "-"
  );
  let lineararr = [];
  const linearRegressionCalc = linearRegression(
    fullaccountbalancearr,
    iterationarray
  );
  const slope = linearRegressionCalc.slope;
  const intercept = linearRegressionCalc.intercept;
  for (let iu = 0, ju = fullaccountbalancearr.length; iu < ju; iu++) {
    let lineararrentry = slope * iu + intercept;
    lineararr.push(lineararrentry);
  }
  let LRCorrelation = handleNANGOOD(
    pcorr(fullaccountbalancearr, lineararr)?.toFixed(3),
    "-"
  );
  let LRStandardError = handleNANGOOD(
    STEYX(fullaccountbalancearr, lineararr)?.toFixed(3),
    "-"
  );
  let KRatio = handleNANGOOD(
    (
      slope /
      ((STEYX(fullaccountbalancearr, lineararr) / Math.sqrt(DEVSQ(lineararr))) *
        totalTrades)
    )?.toFixed(3),
    "-"
  );
  let avgHoldingTime = getDateDifferencewSeconds(arrAvg(timeInMarketArr));
  let avgPLPerTrade = handleNANGOOD(arrAvg(pLArr)?.toFixed(2), "-");
  let avgPLPerWin = handleNANGOOD(arrAvg(pLWinArr)?.toFixed(2), "-");
  let avgPLPerLoss = handleNANGOOD(arrAvg(pLLossArr)?.toFixed(2), "-");

  let avgPositionSize = handleNANGOOD(arrAvg(positionSizeArr)?.toFixed(2), "-");

  tfIndex = handleNANGOOD(
    (
      (totalWinningTrades / totalTrades) *
      (1 + averagePLPerWindollar / Math.abs(averagePLPerLossdollar))
    )?.toFixed(3),
    "-"
  );
  zScore = handleNANGOOD(
    calculateZScore(fullaccountbalancearr).zScore?.toFixed(3),
    "-"
  );
  let standardDeviation = handleNANGOOD(
    getStandardDeviation(pLArr)?.toFixed(2),
    "-"
  );
  let volatility = handleNANGOOD(
    (standardDeviation / totalTrades)?.toFixed(2),
    "-"
  );
  let arithmeticSharpeRatio = handleNANGOOD(
    calculateSharpeRatios(
      fullaccountbalancearr,
      Number(riskFreeRate)
    )?.arithmeticSharpeRatio?.toFixed(3),
    "-"
  );
  let geometricSharpeRatio = handleNANGOOD(
    calculateSharpeRatios(
      fullaccountbalancearr,
      Number(riskFreeRate)
    )?.geometricSharpeRatio?.toFixed(3),
    "-"
  );
  let arithmeticSortinoRatio = handleNANGOOD(
    calculateSortinoRatios(
      fullaccountbalancearr,
      Number(riskFreeRate)
    )?.arithmeticSortinoRatio?.toFixed(3),
    "-"
  );
  let geometricSortinoRatio = handleNANGOOD(
    calculateSortinoRatios(
      fullaccountbalancearr,
      Number(riskFreeRate)
    )?.geometricSortinoRatio?.toFixed(3),
    "-"
  );

  // Calculate trade frequency
  const tradeCount = totalTrades;
  const totalDays = Object.keys(dailyCount).length;
  const totalWeeks = Object.keys(weeklyCount).length;
  const totalMonths = Object.keys(monthlyCount).length; // Approximation for average number of days in a month
  const totalYears = Object.keys(yearlyCount).length; // Approximation for average number of days in a year

  // 24HR P/L
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const twentyFourHrsAgo = fullaccountbalancearrwithDates.filter(
    (bal) => new Date(bal.endDate) > yesterday
  );
  let twentyFourHrPL = 0;
  twentyFourHrsAgo.forEach((bal) => {
    twentyfourhrArr.push({
      value: bal.amount,
      id: bal.id,
    });
    twentyFourHrPL += bal.amount;
  });
  twentyFourHrPL = handleNANGOOD(twentyFourHrPL?.toFixed(2), "-");

  // DAILY P/L
  let dailyAvgPL = calculateAverage(dailyPL, dailyCount);
  let weeklyAvgPL = calculateAverage(weeklyPL, weeklyCount);
  let monthlyAvgPL = calculateAverage(monthlyPL, monthlyCount);
  // ------------------------ Current Streak ------------------------
  let prevcheck = "";
  let streakType = "";
  let streakTypeArray = [];
  let k = 0;
  for (let i = data.length - 1, j = 0; i >= j; i--) {
    let tempvalue = 0;
    let multiExecution = data[i].entry.multiExecution;
    let exitExecution = data[i].entry.exitExecution;
    const entryExitValues = getEntryExitLots(multiExecution, exitExecution);
    const entryLots = entryExitValues.entryLots;
    const exitLots = entryExitValues.exitLots;
    if (calctype === "Gross") {
      tempvalue = Number(data[i].entry.profitLoss * 100);
    } else if (calctype === "Net") {
      tempvalue =
        Number(data[i].entry.profitLoss * 100) +
        Number(data[i].entry.commissions * 100) +
        Number(data[i].entry.fees * 100);
    }
    if (
      data[i].entry.orderType === "Deposit" ||
      data[i].entry.orderType === "Withdrawal" ||
      data[i].entry.orderType === "Funding Payment" ||
      data[i].entry.orderType === "Commit" ||
      data[i].entry.orderType === "Approval" ||
      data[i].entry.orderType === "Wrap" ||
      data[i].entry.orderType === "Self"
    ) {
      continue;
    } else if (exitLots === 0) {
      continue; // exclude trades with no exit executions
    } else {
      if (tempvalue > 0) {
        streakType = "win";
      } else if (tempvalue < 0) {
        streakType = "loss";
      } else if (tempvalue === 0) {
        streakType = "break-even";
      }
      if (streakTypeArray[0] === undefined) {
        streakTypeArray[0] = streakType;
        prevcheck = streakType;
      } else {
        streakTypeArray[k + 1] = streakType;
        if (streakTypeArray[k + 1] !== prevcheck) {
          streakTypeArray.pop();
          break;
        } else {
          prevcheck = streakType;
          k += 1;
        }
      }
    }
  }
  let miscellaneousCurrentStreak = "";
  if (streakTypeArray.length === 0) {
    miscellaneousCurrentStreak = "0 trades";
  } else if (streakTypeArray.length === 1) {
    miscellaneousCurrentStreak = "1 " + String(streakTypeArray[0]);
  } else {
    if (streakTypeArray[0] === "win" || streakTypeArray[0] === "break-even") {
      miscellaneousCurrentStreak =
        streakTypeArray.length + " " + String(streakTypeArray[0]) + "s";
    } else {
      miscellaneousCurrentStreak =
        streakTypeArray.length + " " + String(streakTypeArray[0]) + "es";
    }
  }

  // Graphs
  let profitLossRatioGraph =
    profitlossRatioArr.length > 15
      ? profitlossRatioArr.slice(-15)
      : profitlossRatioArr;
  let profitFactorGraph =
    profitFactorArr.length > 15 ? profitFactorArr.slice(-15) : profitFactorArr;
  let expectationGraph =
    expectationArr.length > 15 ? expectationArr.slice(-15) : expectationArr;
  let cpcGraph = cpcArr.length > 15 ? cpcArr.slice(-15) : cpcArr;

  let avgPLGraph = avgPLArr.length > 15 ? avgPLArr.slice(-15) : avgPLArr;
  let avgPLWinGraph =
    avgPLWinArr.length > 15 ? avgPLWinArr.slice(-15) : avgPLWinArr;
  let avgPLLossGraph =
    avgPLLossArr.length > 15 ? avgPLLossArr.slice(-15) : avgPLLossArr;

  let probChancearrGraph =
    probChancearr.length > 15 ? probChancearr.slice(-15) : probChancearr;
  let avgHoldingTimeGraph =
    avgHoldingTimeArr.length > 15
      ? avgHoldingTimeArr.slice(-15)
      : avgHoldingTimeArr;
  let tiqGoldenRatioGraph =
    tiqGoldenRatioArr.length > 15
      ? tiqGoldenRatioArr.slice(-15)
      : tiqGoldenRatioArr;
  let tfIndexGraph =
    tfIndexArr.length > 15 ? tfIndexArr.slice(-15) : tfIndexArr;
  let recoveryFactorGraph =
    recoveryFactorArr.length > 15
      ? recoveryFactorArr.slice(-15)
      : recoveryFactorArr;

  let avgRRRGraph = avgRRRArr.length > 15 ? avgRRRArr.slice(-15) : avgRRRArr;
  let RMultipleRatioGraph =
    rMultipleArr.length > 15 ? rMultipleArr.slice(-15) : rMultipleArr;
  let statisticalZScoreGraph =
    statisticalZScoreArr.length > 15
      ? statisticalZScoreArr.slice(-15)
      : statisticalZScoreArr;

  let zScoreGraph = zScoreArr.length > 15 ? zScoreArr.slice(-15) : zScoreArr;
  let avgPositionSizeGraph =
    avgpositionSizeArr.length > 15
      ? avgpositionSizeArr.slice(-15)
      : avgpositionSizeArr;

  let volatilityGraph =
    volatilityArr.length > 15 ? volatilityArr.slice(-15) : volatilityArr;
  let percentConfidenceGraph =
    percentConfidenceArr.length > 15
      ? percentConfidenceArr.slice(-15)
      : percentConfidenceArr;
  let KRatioGraph = KRatioArr.length > 15 ? KRatioArr.slice(-15) : KRatioArr;
  let LRCorrelationGraph =
    LRCorrelationArr.length > 15
      ? LRCorrelationArr.slice(-15)
      : LRCorrelationArr;
  let LRStandardErrorGraph =
    LRStandardErrorArr.length > 15
      ? LRStandardErrorArr.slice(-15)
      : LRStandardErrorArr;
  let arithmeticSharpeRatioGraph =
    arithmeticSharpeRatioArr.length > 15
      ? arithmeticSharpeRatioArr.slice(-15)
      : arithmeticSharpeRatioArr;
  let geometricSharpeRatioGraph =
    geometricSharpeRatioArr.length > 15
      ? geometricSharpeRatioArr.slice(-15)
      : geometricSharpeRatioArr;
  let arithmeticSortinoRatioGraph =
    arithmeticSortinoRatioArr.length > 15
      ? arithmeticSortinoRatioArr.slice(-15)
      : arithmeticSortinoRatioArr;
  let geometricSortinoRatioGraph =
    geometricSortinoRatioArr.length > 15
      ? geometricSortinoRatioArr.slice(-15)
      : geometricSortinoRatioArr;
  let standardDeviationGraph =
    standardDeviationArr.length > 15
      ? standardDeviationArr.slice(-15)
      : standardDeviationArr;

  let ulcerIndexGraph =
    ulcerIndexArr.length > 15 ? ulcerIndexArr.slice(-15) : ulcerIndexArr;

  // Result
  result = {
    winRate,
    totalTrades,
    maxDrawdownPercent,
    avgRisk,
    avgRRR,
    avgRRRvalueonly,
    avgEfficiency,
    expectation,
    percentTimeinMarket,
    tiqGoldenRatio,
    dailyAvgPL,
    tfIndex,
    profitLossRatio,
    CPCIndex,
    ulcerIndex,
    profitFactor,
    percentConfidence,
    recoveryFactor,
    avgHoldingTime,
    rmultipleratio,
    standardDeviation,
    volatility,
    zScore,
    avgPLPerTrade,
    avgPLPerWin,
    avgPLPerLoss,
    twentyFourHrPL,
    totalPL_dollar,
    totalPL_percent,
    KRatio,
    LRCorrelation,
    statisticalZScore,
    avgPositionSize,
    LRStandardError,
    miscellaneousCurrentStreak,
    probabilityOfRandomChance,
    arithmeticSharpeRatio,
    arithmeticSortinoRatio,
    geometricSharpeRatio,
    geometricSortinoRatio,
    // ------- Graphs -------
    profitLossRatioGraph,
    avgHoldingTimeGraph,
    avgPLGraph,
    avgPLWinGraph,
    avgPLLossGraph,
    tiqGoldenRatioGraph,
    twentyfourhrArr,
    tfIndexGraph,
    avgRRRGraph,
    zScoreGraph,
    RMultipleRatioGraph,
    profitFactorGraph,
    probChancearrGraph,
    recoveryFactorGraph,
    expectationGraph,
    cpcGraph,
    ulcerIndexGraph,
    avgPositionSizeGraph,
    volatilityGraph,
    percentConfidenceGraph,
    statisticalZScoreGraph,
    KRatioGraph,
    LRCorrelationGraph,
    standardDeviationGraph,
    LRStandardErrorGraph,
    arithmeticSharpeRatioGraph,
    geometricSharpeRatioGraph,
    arithmeticSortinoRatioGraph,
    geometricSortinoRatioGraph,
    // ----------------------
    start_Balance,
    start_DW,
    tradefreqperday:
      totalDays === 0 || !totalDays || onlyTrades.length === 0
        ? "0 trades"
        : `${(tradeCount / totalDays).toFixed(2)} trades/`,
    tradefreqperweek:
      totalWeeks === 0 || !totalWeeks || onlyTrades.length === 0
        ? "0 trades"
        : `${(tradeCount / totalWeeks).toFixed(2)} trades/`,
    tradefreqpermonth:
      totalMonths === 0 || !totalMonths || onlyTrades.length === 0
        ? "0 trades"
        : `${(tradeCount / totalMonths).toFixed(2)} trades/`,
    tradefreqperyear:
      totalYears === 0 || !totalYears || onlyTrades.length === 0
        ? "0 trades"
        : `${(tradeCount / totalYears).toFixed(2)} trades/`,
    winlossesbe: {
      wins: totalWinningTrades,
      losses: totalLosingTrades,
      bes: totalBEs,
    },
    assetclasses: {
      totalStockTrades: totalStockTrades,
      totalCryptoTrades: totalCryptoTrades,
      totalCFDTrades: totalCFDTrades,
      totalForexTrades: totalForexTrades,
      totalFutureTrades: totalFutureTrades,
      totalOptionTrades: totalOptionTrades,
    },
  };
  return result;
};

export default dashboardCalculations;
