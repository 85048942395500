import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import dropdownStyles from "../../functions/dropdownStyles";
import { theme } from "../../components/selectTheme";
import { v4 as uuidv4 } from "uuid";
import { DataContext } from "../../DataContext";
import SelectComparison from "./SelectComparison";
import ComparisonReport from "./ComparisonReport";
import "./styles.scss";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import $ from "jquery";
import putUserData from "../../utils/AWS/putS3UserObject";
import { useAuth0 } from "@auth0/auth0-react";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import comparerImage from "./../../images/comparertwo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

export const ComparerComponent = (props) => {
  const allGlobalData = useContext(DataContext);
  const history = useHistory();

  const { user /* isAuthenticated */ } = useAuth0();
  const processedData = props.processedData;
  const allData = props.userData;
  const tier = props.tier;
  const active = props.active;
  // Tiering
  let showMaster = false;

  if (tier === "free") {
  } else if (tier === "pro" && active) {
  } else if (tier === "oldpro" && active) {
  } else if (tier === "master" && active) {
    showMaster = true;
  } else if (tier === "ultimate" && active) {
    showMaster = true;
  } else if (tier === "admin" && active) {
    showMaster = true;
  } else {
  }
  const portfolioSettings = processedData.portfolio.settings;

  // hide comparisons for nonmaster users
  const [comparisonObjs, setcomparisonObjs] = useState(
    portfolioSettings?.comparisonObjs
      ? showMaster
        ? portfolioSettings.comparisonObjs
        : []
      : []
  );

  const firstUpdate = useRef(true);
  const [aiError, setaiError] = useState(false);
  const [tipStyle, setTipStyle] = useState({});
  const [bgStyle, setBgStyle] = useState({});

  const [selectComparisons, setselectComparisons] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [showDivMessage, setshowDivMessage] = useState("");
  const [fetchingAImessage, setfetchingAImessage] = useState(false);
  const [fetchingAImessage2, setfetchingAImessage2] = useState(false);

  const [canSendAI, setcanSendAI] = useState(true);
  const [graphType, setgraphType] = useState();

  const zIndexCount = useRef(2147483646);
  const globalCalculations = allGlobalData.miscData;
  const savedMessage = localStorage.getItem("autoiterationMessage");

  const [message, setMessage] = useState(savedMessage ? savedMessage : "");
  useEffect(() => {
    const interval = setInterval(() => {
      const savedMessage = localStorage.getItem("autoiterationMessage");
      const parsedMessage = savedMessage ? savedMessage : null;
      if (parsedMessage && parsedMessage !== message) {
        setMessage(parsedMessage);
      }
    }, 100); // Check every 300 ms
    return () => {
      clearInterval(interval);
    };
  }, [message]);

  useEffect(() => {
    if (firstUpdate.current) {
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    const element = document.querySelector(".fetchingInsightsloader");
    if (fetchingAImessage) {
      element && element.classList.add("fade-in");
    } else {
      element && element.classList.remove("fade-in");
    }
    return () => {};
  }, [fetchingAImessage]);
  const selectComparisonsElement = useMemo(() => {
    return selectComparisons.map((item, id) => (
      <SelectComparison
        globalCalculations={globalCalculations}
        setAiError={(e) => {
          setaiError(e);
          const element = document.querySelector("#fetchingInsightsloader3");

          if (element) {
            element.style.opacity = 0;
            element.style.transform = "translate(100%, 0)";
            element.style.transition = "none";
          }
          setMessage("");
          setTimeout(() => {
            localStorage.removeItem("autoiterationMessage");
          }, 100);
        }}
        setAIFetching={(e) => {
          setfetchingAImessage(e);
          //setfetchingAImessage2(e);
        }}
        graphType={graphType}
        canSendAI={canSendAI}
        setcomparisonObjs={async (e) => {

          setcanSendAI(true);
          setselectComparisons([]);
          let comparisonObjsPre = comparisonObjs.slice();
          const index = comparisonObjsPre.findIndex((obj) => obj.id === e.id);
          if (index !== -1) {
            // Replace the existing object with the new one
            const updatedObj = {
              whatToCompare: e.whatToCompare,
              selectedItems: e.selectedItems,
              metricsToCompare: e.metricsToCompare,
              aiObjs: e.aiObjs,
              aiInsight: e.aiInsight,
              graphType: e.graphType,
              id: e.id,
              zIndex: e.zIndex,
            };
            comparisonObjsPre.splice(index, 1, updatedObj);
          } else {
            // Add the new object to the array
            comparisonObjsPre.unshift(e);
          }
          setcomparisonObjs(comparisonObjsPre);
          setgraphType(e.graphType);
          let propsToChangei = {
            comparisonObjs: comparisonObjsPre,
          };
          let globalpropsToChange = {};
          const finalPush = createDatafromSettingsProps(
            allData.chosenPortfolios,
            allData.createdPortfolios,
            propsToChangei,
            globalpropsToChange,
            portfolioSettings,
            allData.globalSettings,
            allData.version
          );

          // SET S3 DATA
          const S3Data = {
            data: finalPush,
            userId: user.sub,
          };
          const finalAwait = async () => {
            await putUserData(S3Data, history);
            allGlobalData.updateAllData(finalPush);
            $(window).unbind("beforeunload");
            localStorage.setItem("dbSetRunning", "no");
          };
          await finalAwait();
        }}
        deleteItem={(e, controller) => {
          controller.abort();
          let newComparison = selectComparisons.slice();
          newComparison = newComparison.filter(
            (selection) => selection.id !== e
          );
          setselectComparisons(newComparison);
        }}
        selectionId={item.id}
        processedData={processedData}
        zIndexValue={`${item.zIndex}`}
        key={item.id + id}

        /*         setzIndexValue={(e) => {
          zIndexCount.current = e;
        }} */
      />
    ));
  }, [
    selectComparisons,
    canSendAI,
    allData,
    allGlobalData,
    comparisonObjs,
    globalCalculations,
    portfolioSettings,
    graphType,
    processedData,
    user,
  ]);

  const comparisonsElement = useMemo(() => {
    return comparisonObjs.map((comparison) => (
      <ComparisonReport
        globalCalculations={globalCalculations}
        whatToCompare={comparison.whatToCompare}
        selectedItems={comparison.selectedItems}
        metricsToCompare={comparison.metricsToCompare}
        processedData={processedData}
        graphType={comparison.graphType}
        aiObjs={comparison.aiObjs}
        aiInsight={comparison.aiInsight}
        allData={allData}
        zIndexValue={2147483646 - comparison.zIndex}
        comparisonId={comparison.id}
        key={comparison.id}
        /*         setzIndexValue={(e) => {
          zIndexCount.current = e;
        }} */
        togglecomparisonObjs={async (id, type) => {
          let comparisonObjsPre = comparisonObjs.slice();
          const index = comparisonObjsPre.findIndex((obj) => obj.id === id);

          if (index !== -1) {
            // Replace the existing object with the new one
            const updatedObj = {
              ...comparisonObjsPre[index],
              graphType: type,
            };
            comparisonObjsPre.splice(index, 1, updatedObj);
          } else {
            // Add the new object to the array
            comparisonObjsPre.unshift({
              whatToCompare: comparisonObjsPre[index].whatToCompare,
              selectedItems: comparisonObjsPre[index].selectedItems,
              metricsToCompare: comparisonObjsPre[index].metricsToCompare,
              aiObjs: comparisonObjsPre[index].aiObjs,
              aiInsight: comparisonObjsPre[index].aiInsight,
              graphType: type,
              id: comparisonObjsPre[index].id,
              zIndex: comparisonObjsPre[index].zIndex,
            });
          }
          setcomparisonObjs(comparisonObjsPre);

          let propsToChangei = {
            comparisonObjs: comparisonObjsPre,
          };
          let globalpropsToChange = {};
          const finalPush = createDatafromSettingsProps(
            allData.chosenPortfolios,
            allData.createdPortfolios,
            propsToChangei,
            globalpropsToChange,
            portfolioSettings,
            allData.globalSettings,
            allData.version
          );

          // SET S3 DATA
          const S3Data = {
            data: finalPush,
            userId: user.sub,
          };
          const finalAwait = async () => {
            await putUserData(S3Data, history);
            allGlobalData.updateAllData(finalPush);
            $(window).unbind("beforeunload");
            localStorage.setItem("dbSetRunning", "no");
          };
          await finalAwait();
        }}
        deletecomparisonObjs={async (e) => {
          localStorage.setItem("canSendAI", "no");
          const element = document.querySelector("#fetchingInsightsloader3");

          if (element) {
            element.style.opacity = 0;
            element.style.transform = "translate(100%, 0)";
            element.style.transition = "none";
          }
          setMessage("");
          setTimeout(() => {
            localStorage.removeItem("autoiterationMessage");
          }, 100);
          //setfetchingAImessage(false);
          let newcomparisonObjs = comparisonObjs.slice();
          newcomparisonObjs = newcomparisonObjs.filter(
            (comparison) => comparison.id !== e
          );
          setcomparisonObjs(newcomparisonObjs);
          let propsToChangei = {
            comparisonObjs: newcomparisonObjs,
          };
          let globalpropsToChange = {};
          const finalPush = createDatafromSettingsProps(
            allData.chosenPortfolios,
            allData.createdPortfolios,
            propsToChangei,
            globalpropsToChange,
            portfolioSettings,
            allData.globalSettings,
            allData.version
          );

          // SET S3 DATA
          const S3Data = {
            data: finalPush,
            userId: user.sub,
          };
          const finalAwait = async () => {
            await putUserData(S3Data, history);
            allGlobalData.updateAllData(finalPush);
            $(window).unbind("beforeunload");
            localStorage.setItem("dbSetRunning", "no");
          };
          await finalAwait();
        }}
      />
    ));
  }, [
    comparisonObjs,
    allData,
    allGlobalData,
    globalCalculations,
    portfolioSettings,
    processedData,
    user,
  ]);

  function handleMouseOver(event) {
    event.stopPropagation();
    event.preventDefault();
    const heightToAdjust = 900;
    const widthToAdjust = 1680;
    let scaleAmountIn = 1;
    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    var height =
      window.innerHeight > 0 ? window.innerHeight : window.screen.height;

    if (width < widthToAdjust || height < heightToAdjust) {
      scaleAmountIn = 0.8;
    } else {
      scaleAmountIn = 1;
    }
    const imageRect = event.target.getBoundingClientRect();
    const x = event.clientX - imageRect.left;
    const y = event.clientY - imageRect.top;
    let style;
    let bgStyleIn;
    if (x <= imageRect.width * 0.43 && y <= imageRect.height * 0.27) {
      setShowDiv(true);
      setshowDivMessage(
        "Your selected categories. Click them to toggle certain categories on/off."
      );
      style = {
        left: "0%",
        top: "8px",
        width: (imageRect.width * 0.43) / scaleAmountIn,
        height: (imageRect.height * 0.19) / scaleAmountIn,
      };
      bgStyleIn = {
        left: "-1px",
        top: "-39px",
        width: (imageRect.width * 1) / scaleAmountIn,
        height: (imageRect.height * 1) / scaleAmountIn,
      };
    } else if (
      x <= imageRect.width * 0.43 &&
      x >= imageRect.width * 0.22 &&
      y >= imageRect.height * 0.27
    ) {
      setShowDiv(true);
      setshowDivMessage(
        "The Metric Comparison is a visual way of comparing the overall performance of each category all at once - the larger the area for a particular category, the better."
      );
      style = {
        left: (imageRect.width * 0.22) / scaleAmountIn + "px",
        top: (imageRect.height * 0.21) / scaleAmountIn + "px",
        width: (imageRect.width / scaleAmountIn) * 0.21,
        height: (imageRect.height / scaleAmountIn) * 0.73,
      };
      bgStyleIn = {
        left: (-imageRect.width * 0.22) / scaleAmountIn - 1 + "px",
        top: (-imageRect.height * 0.21) / scaleAmountIn - 31 + "px",
        width: (imageRect.width / scaleAmountIn) * 1,
        height: (imageRect.height / scaleAmountIn) * 1,
      };
    } else if (x <= imageRect.width * 0.22 && y >= imageRect.height * 0.27) {
      setShowDiv(true);
      setshowDivMessage(
        "The AI Score is a performance grade from A-F given by an AI based on the metrics selected."
      );
      style = {
        left: 0,
        top: (imageRect.height * 0.21) / scaleAmountIn + "px",
        width: (imageRect.width * 0.22) / scaleAmountIn,
        height: (imageRect.height * 0.73) / scaleAmountIn,
      };
      bgStyleIn = {
        left: "-1px",
        top: (-imageRect.height * 0.21) / scaleAmountIn - 31 + "px",
        width: (imageRect.width / scaleAmountIn) * 1,
        height: (imageRect.height / scaleAmountIn) * 1,
      };
    } else if (x >= imageRect.width * 0.43) {
      setShowDiv(true);
      style = {
        left: (imageRect.width * 0.443) / scaleAmountIn + "px",
        top: -20,
        width: (imageRect.width * 0.55) / scaleAmountIn,
        height: (imageRect.height * 0.98) / scaleAmountIn,
      };
      bgStyleIn = {
        left: (-imageRect.width * 0.443) / scaleAmountIn - 1 + "px",
        top: -11 + "px",
        width: (imageRect.width / scaleAmountIn) * 1,
        height: (imageRect.height / scaleAmountIn) * 1,
      };
      setshowDivMessage(
        "Graphs of all the metrics selected for the comparison. These can be toggled to bar graphs using the 3-dot button in the top right."
      );
    }
    setTipStyle(style);
    setBgStyle(bgStyleIn);
  }
  function handleMouseLeave(event) {
    setShowDiv(false);
  }
  const bgStyleWithImage = {
    backgroundImage: `url(${comparerImage})`,
    backgroundSize: "100%",
    filter: "contrast(200%)", // or 'contrast(150%)'
    opacity: 0.5,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    ...bgStyle, // Apply the background position adjustment
  };
  return (
    <div className="comparerWrapper">
      <div className="comparerButtonWrapper">
        <button
          disabled={
            showMaster === false
              ? true
              : message && message !== "AI scores ready!"
              ? true
              : false
          }
          onClick={() => {
            const newCount = zIndexCount.current - 1;
            zIndexCount.current = newCount;
            setselectComparisons([
              ...selectComparisons,
              { zIndex: newCount, id: uuidv4() },
            ]);
            localStorage.setItem("canSendAI", "yes");
          }}
          className="createnewcomparisonbutton noselect"
        >
          Create New Comparison
        </button>
      </div>
      {!selectComparisons.length && !comparisonObjs.length && (
        <div className="comparerGettingStarted">
          <div className="comparerGettingStartedHeader">
            Example of a Comparison
            <div className="comparerGettingStartedsubHeader">
              **This is demo data for demonstration purposes only
            </div>
          </div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              alt=""
              onMouseOver={handleMouseOver}
              onMouseMove={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              src={comparerImage}
              style={{
                width: "100%",
                zIndex: 10000,
                margin: "-29px 0px 0px 1px",
              }}
            ></img>
            {showDiv && (
              <div
                className="comparerGettingStartedTip1"
                id="comparerGettingStartedTip1"
              >
                {showDivMessage}
              </div>
            )}
            <div
              className="comparerGettingStartedbackground"
              id="comparerGettingStartedbackground"
            ></div>
            <div
              className="comparerGettingStartedLine"
              id="comparerGettingStartedLine"
            ></div>
            {/*             <div
              className="comparerGettingStartedvideoPlayer"
              id="comparerGettingStartedvideoPlayer"
            >
              How to use the Compare & Conquer - quick tutorial
              <div className="comparerGettingStartedplaybutton">
                <FontAwesomeIcon icon={faPlay} />
              </div>
            </div> */}
            {showDiv && (
              <div
                className="comparerGettingStartedTip1h"
                id="comparerGettingStartedTip1h"
                style={{
                  ...tipStyle,
                  position: "absolute",
                  pointerEvents: "none",
                  backgroundColor: "#14181e",
                  opacity: "0.3",
                }}
              >
                {" "}
                <div style={bgStyleWithImage}></div>{" "}
                {/*creates the "highlight" effect*/}
              </div>
            )}
          </div>
          {/*           <div className="comparerGettingStartedTip2">
            AI Insights provides a personalized analysis, powered by AI, that
            incorporates your chosen metrics for comparison. No two comparisons
            are the same - it generates a unique evaluation of your trading
            everytime.
          </div>
          <div className="comparerGettingStartedTip3">
            AI Insights provides a personalized analysis, powered by AI, that
            incorporates your chosen metrics for comparison. No two comparisons
            are the same - it generates a unique evaluation of your trading
            everytime.
          </div>
          <div className="comparerGettingStartedTip4">
            AI Insights provides a personalized analysis, powered by AI, that
            incorporates your chosen metrics for comparison. No two comparisons
            are the same - it generates a unique evaluation of your trading
            everytime.
          </div> */}
        </div>
      )}
      {aiError && (
        <div className="aierrormessage">
          An error occured while fetching your AI insights. Try selecting less
          metrics/categories. This is experimental technology and will improve
          as we learn more.
        </div>
      )}
      {fetchingAImessage2 && (
        <div
          className={`fetchingInsightsloader ${
            fetchingAImessage2 ? "show" : ""
          }`}
        >
          <div
            className="fetchingInsightsloaderx"
            onClick={() => {
              setfetchingAImessage2(false);
              //setfetchingAImessage(false);
            }}
          ></div>
        </div>
      )}
      <div className="comparerComparisonsWrapper">
        <div className={`comparerReportInfo_ComparingWrapperf`}>
          {selectComparisonsElement}
        </div>
        <div
          className={`comparerReportInfo_ComparingWrapperff`}
          id={`comparerReportInfo_ComparingWrapperff`}
        >
          {comparisonsElement}
        </div>
      </div>{" "}
    </div>
  );
};
export default React.memo(ComparerComponent);
