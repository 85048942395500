import { v4 as uuidv4 } from "uuid";
import profitLossCalculation from "./../../../functions/profitLossCalculation";
import getPointValue from "./../getFuturesPointValue";
import { montharr, weekday } from "./../../../arrays/weekandmonth";
import {
  getEndDateTime,
  getStartDateTime,
} from "./../../../functions/getStartorEndDateTime";
import { filterByID } from "./../../../functions/filterByID";
import { csvToArray, csvToArrayFromFills, parseCSV } from "./../csvToArray";

function extractTrades(data) {
  const trades = [];
  let startExtracting = false;
  let skipRows = 2; // Skip 2 rows after "Trades; trade date basis"

  for (let i = 0; i < data.length; i++) {
    const currentItem = data[i];

    // Check for "Trades; trade date basis" in the "History Export" column
    if (currentItem[2] === "Trades; trade date basis") {
      startExtracting = true;
      skipRows = 2; // Reset skipRows in case of multiple matches
      continue;
    }

    // Start extracting trades after skipping 2 rows
    if (startExtracting) {
      if (skipRows > 0) {
        skipRows--;
        continue;
      }
      if (currentItem[0] !== "DATA") {
        // Stop when "History Export" is blank
        break;
      }

      // Only include rows where the 76th element is "EXECUTION"
      if (currentItem[76] === "EXECUTION" && currentItem[69] !== "N/A") {
        trades.push(currentItem); // Add the trade
      }
    }
  }

  return trades;
}

function convertToISO(dateTimeString) {
  // Replace the semicolon with a space to make it a standard date-time format
  const standardizedString = dateTimeString.replace(";", " ");

  // Create a Date object from the string
  const date = new Date(standardizedString);

  // Convert to ISO string in local time
  const isoString = date.toISOString();

  return isoString;
}

export const interactivebrokers = (
  readerEvent,
  userId,
  portfolio,
  settrades,
  setinvalidFile
) => {
  var content = readerEvent.target.result; // =====> this is the content!
  let pretradeArray = parseCSV(content);
  if (!readerEvent) {
    setinvalidFile(true);
    return;
  }

  let trade = "";
  let trades = [];
  let fullTradearray = [];
  let tradeArray = [];
  tradeArray = extractTrades(pretradeArray);

  tradeArray.forEach((trade) => {
    fullTradearray.push(trade); // push trades
  });

  fullTradearray.sort(function (a, b) {
    const isoDatea = convertToISO(a[30]);
    const isoDateb = convertToISO(a[30]);
    var c = new Date(isoDatea);
    var d = new Date(isoDateb);
    return c - d;
  });
  const getFees = (arr, starti) => {
    let fees = 0;
    /*     for (let i = starti + 1, j = arr.length; i < j; i++) {
      let entryType = "";
      if (arr[i]["Status"] === "Canceled" || arr[i]["Status"] === "Rejected") {
      } else {
        if (!arr[i]["Fill Time"]) {
          entryType = "Cash";
        } else {
          entryType = "Order";
        }
        if (entryType === "Order") {
          break;
        }
        if (
          arr[i]["Currency"] === "Exchange Fee" ||
          arr[i]["Currency"] === "Nfa Fee" ||
          arr[i]["Currency"] === "Clearing Fee" ||
          arr[i]["Currency"] === "Commission"
        ) {
          fees += Number(arr[i]["Delta"]);
        } else {
        }
      }
    } */
    return fees;
  };

  const verified = 0; // could be 1 in the future

  for (let i = 0, j = fullTradearray.length; i < j; i++) {
    const delta = 0; // Placeholder
    if (
      fullTradearray[i]["Contract"] === "Deposit" // Placeholder check for deposits/withdrawals, fix later
    ) {
      var startDateTime = new Date(fullTradearray[i][30]);
      trade = {
        entryId: uuidv4(),
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: [],
            pointValue: 0,
          },
          strategy: "",
          selectedConfidence: "",
          selectedEmotion: "",
          selectedPhysical: "",
          selectedMarket: "",
          selectedTimeframe: "",
          selectedMistake: "",
          selectedPortfolio: portfolio.name,
          selectedPortfolioType: "",
          orderType: Number(delta) > 0 ? "Deposit" : "Withdrawal",
          orderNumber: fullTradearray[i][62],
          dayOfWeek: weekday[startDateTime.getDay()],
          monthOfYear: montharr[startDateTime.getMonth()],
          stopLoss: "",
          takeProfit: "",
          profitLoss: Number(delta),
          commissions: 0,
          fees: 0,
          maxAdEx: "",
          maxFavEx: "",
          comments: "",
          multiExecution: [],
          exitExecution: [],
          startDateTime: startDateTime,
          endDateTime: startDateTime,
          tags: "",
          verifiedLevel: verified,
          idLinks: [],
        },
      };
      trades = trades.concat(trade);
    } else {
      const tradeType = fullTradearray[i][7];
      if ((tradeType === "STK")) {
        // look for open trades in "trades" (not the current iteration!)
        // that matches the symbol
        let openTradesPre = [...trades];
        let openTradesPre2 = [...trades];
        let openTradesMatch = openTradesPre.filter((trade) =>
          filterByID(trade, false, fullTradearray[i][9])
        );
        let openTradesNOTMatch = openTradesPre2.filter((trade) =>
          filterByID(trade, true, fullTradearray[i][9])
        );
        // --------------------------

        // if the order was canceled or rejected dont do anything
        // also skip "Trade Paired" Cash entries - not needed
        if (fullTradearray[i][38] === "0" || fullTradearray[i][62] === "") {
          // Don't do anything here
        } else {
          let startDateTime = new Date(fullTradearray[i][30]);
          //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
          let orderType = fullTradearray[i][59] === "BUY" ? "Long" : "Short";
          //let delta = fullTradearray[i]["Delta"];

          let entryexecution = {
            id: uuidv4(),
            lotSize: Number(fullTradearray[i][38]), // Quantity column
            entryPrice: Number(fullTradearray[i][39]), // TradePrice column
            startDateTime: startDateTime,
            expectedEntry: "",
            strikePrice: "",
            expirationDate: "",
            legType: "",
          };
          const openTrade = openTradesMatch[0];
          const opentradeOrderType = openTrade?.entry.orderType;
          if (!openTradesMatch.length) {
            trade = {
              entryId: uuidv4(),
              entry: {
                pictures: ["N/A"],
                symbol: {
                  symbols: [fullTradearray[i][9]],
                  pointValue: getPointValue("rithmic", fullTradearray[i][9]),
                },
                strategy: "",
                selectedConfidence: "",
                selectedEmotion: "",
                selectedPhysical: "",
                selectedMarket: "",
                selectedTimeframe: "",
                selectedMistake: "",
                selectedPortfolio: portfolio.name,
                selectedPortfolioType: "Stocks",
                orderType: orderType,
                orderNumber: fullTradearray[i][62],
                dayOfWeek: weekday[startDateTime.getDay()],
                monthOfYear: montharr[startDateTime.getMonth()],
                stopLoss: "",
                takeProfit: "",
                profitLoss: 0,
                commissions: 0,
                fees: getFees(fullTradearray, i),
                maxAdEx: "",
                maxFavEx: "",
                comments: "",
                multiExecution: [entryexecution],
                exitExecution: [],
                startDateTime: startDateTime,
                endDateTime: "",
                tags: "",
                verifiedLevel: verified,
                idLinks: [],
              },
            };
            trades.push(trade);
            openTradesPre = trades;
            openTradesPre2 = trades;
          } else {
            if (orderType !== opentradeOrderType) {
              // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
              var endDateTime = new Date(fullTradearray[i][30]);
              let exitexecution = {
                id: uuidv4(),
                exitLotSize: Number(fullTradearray[i][38]),
                exitPrice: Number(fullTradearray[i][39]),
                endDateTime: endDateTime,
                expectedExit: "",
                exitstrikePrice: "",
                exitexpirationDate: "",
                exitlegType: "",
                equityComponents: [],
                exercised: "",
              };
              const openTrade = openTradesMatch[0];
              const openTradeEntry = openTrade?.entry;

              // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
              const opentrademultiExecution = openTradeEntry.multiExecution;
              const opentradeOrderType = openTradeEntry.orderType;
              const opentradeSymbol = openTradeEntry.symbol;
              const opentradeFees = openTradeEntry.fees;

              const opentradesType = openTradeEntry.selectedPortfolioType;
              const newExitExecution = [
                ...openTradeEntry.exitExecution,
                exitexecution,
              ];
              // calculate absolute start date time

              let startDateTimez = getStartDateTime(opentrademultiExecution);
              let endDateTimez = getEndDateTime(newExitExecution);
              const entry = Object.assign({}, openTradeEntry, {
                exitExecution: newExitExecution,
                profitLoss: Number(
                  profitLossCalculation(
                    opentrademultiExecution,
                    newExitExecution,
                    opentradeOrderType,
                    opentradesType,
                    opentradeSymbol.pointValue,
                    false
                  )
                ),
                endDateTime: endDateTimez,
                startDateTime: startDateTimez,
                fees: opentradeFees + getFees(fullTradearray, i),
              });
              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              openTradesNOTMatch.push(closedTrade);
              trades = openTradesNOTMatch;
              openTradesPre = openTradesNOTMatch;
              openTradesPre2 = openTradesNOTMatch;
            } else {
              // Add new opening executions
              const openTrade = openTradesMatch[0];
              const openTradeEntry = openTrade?.entry;
              const opentradeFeesin = openTradesMatch[0].entry.fees;
              const entry = Object.assign({}, openTradeEntry, {
                multiExecution: [
                  ...openTradesMatch[0].entry.multiExecution,
                  entryexecution,
                ],
                fees: opentradeFeesin + getFees(fullTradearray, i),
              });
              const closedTrade = Object.assign({}, openTrade, {
                entry: entry,
              });
              openTradesNOTMatch.push(closedTrade);
              trades = openTradesNOTMatch;
              openTradesPre = openTradesNOTMatch;
              openTradesPre2 = openTradesNOTMatch;
            }
          }
        }
      } else if ((tradeType === "FUT")) {
      } else if ((tradeType === "OPT")) {
      } else if ((tradeType === "CASH")) {
      }
    }
  }
  settrades(trades);
};
export default interactivebrokers;
