import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import styled from "styled-components";
import Select from "react-select";
import { currencySymbolCodes2 /* , skynetPortals  */ } from "./settingsLists";
import premiumTierComponent from "../../components/tierComponents/premium";
import "./settingsStyles.scss";
import AutoImport from "../Integrations/autoImport";
import { useHistory } from "react-router-dom";
import { setLoaderState } from "../../actions/actionCreators";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import store from "../../store";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import { DataContext } from "../../DataContext";
import arraysEqual from "../../functions/arrayFunctions/arrayEqual";
import ProTierComponent, {
  MasterTierComponent,
} from "./../../components/tierComponents/pro";
import {
  faRedo,
  faPlus,
  faTrashAlt,
  faPlay,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import { theme } from "./../../components/selectTheme";
import demoData from "../../demoData";
import useForceUpdate from "../../hooks/useForceUpdate";
import $ from "jquery";
import dydxlogo from "./../../images/dydxlogo.svg";
import robinhoodlogo from "./../../images/Logo-Robinhood.png";
import metamasklogo from "./../../images/MetaMask_Fox.svg";
import mt4logo from "./../../images/metatrader-4-logo-og.png";
import mt5logo from "./../../images/mt5-logo.png";
import tradovatelogo from "./../../images/Tradovate-darkbg.png";
import iblogo from "./../../images/Interactive_Brokers-Logo.png";

import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
import createSettings from "../../pages/Settings/createSettings";
import supportedCryptos from "./../../arrays/isCurrencyCrypto";

export const CurrencySymbolItemOther = styled.div`
  padding: 0;
  margin: 0;
  font-size: 1.5em;
  font-weight: 400;
`;
export const Title = styled.div`
  color: #fff7cb;
  font-weight: 400;
  white-space: nowrap;
  width: 0px;
`;
export const GoldTitle = styled.div`
  color: #fbd665;
  font-weight: 400;
`;
var he = require("he");

const SettingsComponent = (props) => {
  const allGlobalData = useContext(DataContext);
  const allData = props.allData;
  const portfolio = allData.portfolio;
  const active = allGlobalData.customer.active;
  const tier = allGlobalData.customer.tier;
  const { user /* isAuthenticated */ } = useAuth0();
  const firstUpdate = useRef(true);
  //const [selectedTimeZone, setselectedTimeZone] = useState();
  const [createPortfolioName, setcreatePortfolioName] = useState(
    portfolio.name
  );
  const [initPortfolioName] = useState(portfolio.name);
  const [initPortfolioAssets] = useState(portfolio.assetClasses);
  const [tabBarWidth, settabBarWidth] = useState(220);
  const [settingswidth, setsettingswidth] = useState(0);
  const [settingsheight, setsettingsheight] = useState(0);
  const [navBarToggle, setnavBarToggle] = useState(220);
  const forceUpdate = useForceUpdate();

  // Tiering logic
  let ispro = false;
  let ismaster = false;

  let tierDiv = "";
  let tierDiv2 = "";
  if (tier === "free") {
    tierDiv = (
      <div style={{ position: "relative", top: -32, left: 116 }}>
        <ProTierComponent />
      </div>
    );
    tierDiv2 = (
      <div style={{ position: "relative", top: -62, left: 266 }}>
        <MasterTierComponent />
      </div>
    );
  } else if (tier === "pro" && active) {
    ispro = true;
    tierDiv2 = (
      <div style={{ position: "relative", top: -62, left: 266 }}>
        <MasterTierComponent />
      </div>
    );
  } else if (tier === "oldpro" && active) {
    ispro = true;
    tierDiv2 = (
      <div style={{ position: "relative", top: -62, left: 266 }}>
        <MasterTierComponent />
      </div>
    );
  } else if (tier === "master" && active) {
    ispro = true;
    ismaster = true;
  } else if (tier === "ultimate" && active) {
    ispro = true;
    ismaster = true;
  } else if (tier === "admin" && active) {
    ispro = true;
    ismaster = true;
  } else {
    tierDiv = (
      <div style={{ position: "relative", top: -32, left: 116 }}>
        <ProTierComponent />
      </div>
    );
    tierDiv2 = (
      <div style={{ position: "relative", top: -62, left: 266 }}>
        <MasterTierComponent />
      </div>
    );
  }
  const handleSaveDemoData = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    let portfolio = {};
    let temparr = allGlobalData.allData.createdPortfolios;
    let tempALL = allGlobalData.allData.createdPortfolios.slice();
    let tempALLOther = allGlobalData.allData.createdPortfolios.slice();
    tempALL = tempALL.filter((x) => x.name !== initPortfolioName);
    tempALLOther = tempALLOther.filter((x) => x.name === initPortfolioName);
    let entries = tempALLOther[0].entries;
    let tempsettings = tempALLOther[0].settings;
    const propsValuesToChange = {
      demoDataLoaded: true,
    };
    const settingsDataPre = createSettings(tempsettings, propsValuesToChange);

    // Change portfolio name in current change to updated name

    portfolio = {
      name: initPortfolioName,
      assetClasses: tempALLOther[0].assetClasses,
      entries: [...entries, ...demoData(initPortfolioName).entries],
      settings: settingsDataPre,
    };
    if (!temparr.length) {
      tempALL[0] = portfolio;
    } else {
      tempALL.push(portfolio);
    }
    // ------------
    const settingsData = {
      chosenPortfolios: allGlobalData.allData.chosenPortfolios,
      createdPortfolios: tempALL,
      globalSettings: allGlobalData.allData.globalSettings,
      version: Number(allGlobalData.allData.version) + 1,
      sharedTrades: allGlobalData.allData.sharedTrades,
    };
    // SET AWS DATA AND CONTEXT STATE
    forceUpdate();
    const S3Data = {
      data: settingsData,
      userId: user.sub,
    };
    const customerId =
      settingsData === null ? "" : settingsData.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      settingsData,
      "trades",
      user.sub,
      history
    );

    allGlobalData.updateAllData(settingsData);
    await putUserData(S3Data, history);
    store.dispatch(setLoaderState({ loading: false }));
    localStorage.setItem("loadDemoDataAction", "yes");
    history.push("/BlankPage");
    history.goBack();
  };
  const handleDefault = (x) => {
    const temparr = {
      label: (
        <CurrencySymbolItemOther>
          {he.decode(String(x))}
        </CurrencySymbolItemOther>
      ),
      value: x,
    };
    return temparr;
  };
  const [INITSettings] = useState(portfolio.settings);
  const selectedSymboltemp = portfolio.settings.currencySymbol;
  const selectedCaltype = portfolio.settings.calculationType;
  const selectedDateFilterBy = portfolio.settings.dateFilterBy;
  const selectedsaveFilters = portfolio.settings.savefilters;
  const selectedBrokerInfo = portfolio.settings.linkedBrokerInfo;
  const colorSetting = portfolio.settings.portfolioColor || "";

  const loadDemoSetting = portfolio.settings.demoDataLoaded;
  const selectedstartingBalance = portfolio.settings.startingBalance;
  const selectedfeesStructure = portfolio.settings.feesStructure;

  const [selectedSymbol, setselectedSymbol] = useState(
    handleDefault(selectedSymboltemp)
  );
  const supportedCryptosCheck = supportedCryptos.includes(selectedSymboltemp);

  const [calcType, setcalcType] = useState(
    selectedCaltype === "Gross" ? false : true
  );
  const [dateFilterBy, setdateFilterBy] = useState(
    selectedDateFilterBy === "Trade Close" ? true : false
  );
  const [saveFilters, setsaveFilters] = useState(selectedsaveFilters);
  const initView = props.initialSettingsView;
  const INITsettingShowState = !initView
    ? {
        general: "block",
        web3: "none",
        filtering: "none",
        importing: "none",
        subscription: "none",
        integrations: "none",
      }
    : !initView.initToggle
    ? {
        general: "block",
        web3: "none",
        filtering: "none",
        importing: "none",
        subscription: "none",
        integrations: "none",
      }
    : initView.initView;

  const [settingShowState, setsettingShowState] =
    useState(INITsettingShowState);
  let initcheckState = {
    Options: initPortfolioAssets.includes("Options") ? true : false,
    Stocks: initPortfolioAssets.includes("Stocks") ? true : false,
    Futures: initPortfolioAssets.includes("Futures") ? true : false,
    Forex: initPortfolioAssets.includes("Forex") ? true : false,
    Crypto: initPortfolioAssets.includes("Crypto") ? true : false,
    CFD: initPortfolioAssets.includes("CFD") ? true : false,
  };
  const [startingBalance, setstartingBalance] = useState(
    selectedstartingBalance
  );
  const [feesStructure, setfeesStructure] = useState(selectedfeesStructure);

  const [checkedItems, setCheckedItems] = useState(initcheckState);
  const { Options, Stocks, Futures, Forex, Crypto, CFD } = checkedItems;
  const [isDisabled, setisDisabled] = useState(true);

  const [colorCheck, setcolorCheck] = useState(
    !colorSetting || colorSetting === "" ? true : false
  );

  const [colorState, setColorState] = useState(ismaster ? colorSetting : "");

  const [rewardsOptin /* setrewardsOptin */] = useState();
  const handleColorChange = (event) => {
    const newColor = event.target.value;
    if (!newColor || newColor === "") {
      setcolorCheck(true);
    } else {
      setcolorCheck(false);
    }
    setColorState(newColor);
  };

  const history = useHistory();
  useEffect(() => {
    const settingscompmain = document.getElementById("settingscompmain");

    const resizeObserver = new ResizeObserver((entries) => {
      setsettingswidth(settingscompmain && settingscompmain.offsetWidth);
      setsettingsheight(settingscompmain && settingscompmain.offsetHeight);
    });

    if (firstUpdate.current) {
      var addedType = localStorage.getItem("savedsettings");
      var addedType2 = localStorage.getItem("loadDemoDataAction");

      if (addedType === "Saved") {
        showConfirmedActionButton("Settings Saved!");
        localStorage.removeItem("savedsettings");
      }
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    settingscompmain && resizeObserver.observe(settingscompmain);
    return () => {
      firstUpdate.current = false;
    };
  }, [allData, props.pageName]);

  useEffect(() => {
    if (initView && initView.initToggle) {
      /*       $("#messageBox").fadeIn(300);
      $("#overlay").fadeIn(300);
      document.getElementById("messageBoxMessage").innerHTML =
        "Link a broker to start your journaling!"; */
    }
    return () => {};
  }, [initView]);
  const undoChanges = () => {
    setcreatePortfolioName(portfolio.name);
    setselectedSymbol(handleDefault(selectedSymboltemp));
    setcalcType(selectedCaltype === "Gross" ? false : true);
    setdateFilterBy(selectedDateFilterBy === "Trade Close" ? true : false);
    setsaveFilters(selectedsaveFilters);
    setfeesStructure(selectedfeesStructure);
    setstartingBalance(selectedstartingBalance);
    setCheckedItems(initcheckState);
    //setcolorCheck()
    setColorState(ismaster ? colorSetting : "");
    setcolorCheck(!colorSetting || colorSetting === "" ? true : false);
  };
  const settingsValidation = useCallback(() => {
    const assetClasses = checkedItems;
    let temparrayu = [];
    for (const property in assetClasses) {
      if (assetClasses[property] === true) {
        temparrayu = temparrayu.concat(property);
      }
    }

    if (
      INITSettings.currencySymbol === selectedSymbol.value &&
      INITSettings.calculationType === (calcType ? "Net" : "Gross") &&
      INITSettings.dateFilterBy ===
        (dateFilterBy ? "Trade Close" : "Trade Open") &&
      INITSettings.savefilters === saveFilters &&
      INITSettings.startingBalance === startingBalance &&
      INITSettings.feesStructure === feesStructure &&
      initPortfolioName === createPortfolioName &&
      arraysEqual(initPortfolioAssets.sort(), temparrayu.sort())
    ) {
      if (ismaster) {
        if (INITSettings.portfolioColor === colorState) {
          setisDisabled(true);
        } else {
          setisDisabled(false);
        }
      } else {
        setisDisabled(true);
      }
    } else {
      setisDisabled(false);
    }
  }, [
    INITSettings,
    selectedSymbol,
    checkedItems,
    calcType,
    dateFilterBy,
    saveFilters,
    colorState,
    feesStructure,
    ismaster,
    startingBalance,
    createPortfolioName,
    initPortfolioName,
    initPortfolioAssets,
  ]);
  useEffect(() => {
    settingsValidation();
  }, [
    checkedItems,
    startingBalance,
    calcType,
    selectedSymbol,
    dateFilterBy,
    saveFilters,
    createPortfolioName,
    settingsValidation,
  ]);

  const customStyles = {
    input: () => ({
      width: 0,
      marginTop: 2,
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        height: "10px",
        width: "10px",
        color: "#1f2831",
        backgroundColor: "#1f2831",
      },
      "::-webkit-scrollbar-track": {
        borderRadius: "10px",
        border: "#a4a9b1",
        color: "#1f2831",
        backgroundColor: "#1f2831",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      },
      "::-webkit-scrollbar-thumb": {
        borderRadius: "5px",
        color: "#14181e",
        backgroundColor: "#14181e",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.5)",
        WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.5)",
      },
      "::-webkit-scrollbar-corner": {
        backgroundColor: "#1f2831",
        borderBottomRightRadius: "5px",
      },
    }),
  };

  /* --------------- Change functions  ---------------*/
  // (props.) functions give inputs to the callback functions in index.js

  const deleteTrades = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    const allportfolios = allGlobalData.allData;
    let tempchosen = allGlobalData.allData.chosenPortfolios;
    const thisport = tempchosen.filter((name) => name !== "Default")[0]; // deleting in the navbar only occurs on one port selected
    let tempALL = allGlobalData.allData.createdPortfolios.slice();
    let tempALLOther = allGlobalData.allData.createdPortfolios.slice();
    tempALL = tempALL.filter((x) => x.name !== thisport);
    tempALLOther = tempALLOther.filter((x) => x.name === thisport);
    let entries = tempALLOther[0].entries.slice();
    entries = entries.filter((obj) => !obj?.entry?.demoData);
    let tempsettings = tempALLOther[0].settings;
    tempsettings.demoDataLoaded = true; // since it's just one line, no need to import the function that creates the settings obj
    const propsValuesToChange = {
      demoDataLoaded: false,
    };
    const settingsDataPre = createSettings(tempsettings, propsValuesToChange);

    // Change portfolio name in current change to updated name

    const portfolio = {
      name: thisport,
      assetClasses: tempALLOther[0].assetClasses,
      entries: entries,
      settings: settingsDataPre,
    };

    tempALL = tempALL.concat(portfolio);

    const finalPush = {
      chosenPortfolios: allportfolios.chosenPortfolios,
      createdPortfolios: tempALL,
      globalSettings: allportfolios.globalSettings,
      version: Number(allportfolios.version) + 1,
      sharedTrades: allportfolios.sharedTrades,
    };

    const customerId =
      finalPush === null ? "" : finalPush.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      finalPush,
      "trades",
      user.sub,
      history
    );

    allGlobalData.updateAllData(finalPush); // this one needs to run on the res
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    const finalAwait = async () => {
      await putUserData(S3Data, history);
      $(window).unbind("beforeunload");
      localStorage.setItem("dbSetRunning", "no");
      localStorage.setItem("loadDemoDataAction", "no");
      store.dispatch(setLoaderState({ loading: false }));
      history.push("/BlankPage");
      history.goBack();
    };
    await finalAwait();
  };
  const handleSelectSubmit = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    // -------------------------------------
    let propsToChange = {
      currencySymbol: selectedSymbol.value,
      calculationType: calcType === false ? "Gross" : "Net",
      dateFilterBy: dateFilterBy === false ? "Trade Open" : "Trade Close",
      savefilters: saveFilters,
      startingBalance: startingBalance,
      filters: store.getState().defaultfilterValue.filtervalue,
      portfolioColor: colorState,
      feesStructure: feesStructure,
    };

    const globalpropsToChange = {};

    // ----- had to add this for changing name and asset class (10/19/22) ----
    let temparr = allGlobalData.allData.createdPortfolios;
    let tempALL = allGlobalData.allData.createdPortfolios.slice();
    let tempALLOther = allGlobalData.allData.createdPortfolios.slice();
    const originalportfolionameCallbackCheck = !initPortfolioName
      ? allData.portfolio.name
      : initPortfolioName;
    const portfolionameCallbackCheck = !createPortfolioName
      ? allData.portfolio.name
      : createPortfolioName;
    const assetClasses = checkedItems;
    let temparrayu = [];
    for (const property in assetClasses) {
      if (assetClasses[property] === true) {
        temparrayu = temparrayu.concat(property);
      }
    }
    const portfolioAssetClassesCallbackCheck = !temparrayu
      ? allData.portfolio.assetClasses
      : temparrayu;
    tempALL = tempALL.filter(
      (x) => x.name !== originalportfolionameCallbackCheck
    );
    tempALLOther = tempALLOther.filter(
      (x) => x.name === originalportfolionameCallbackCheck
    );

    let chosenOnes = "";
    let tempALL_CHOSEN = allGlobalData.allData.chosenPortfolios;
    let tempALL_CHOSENOTHER = allGlobalData.allData.chosenPortfolios;

    // Create the chosen portfolios array for skynet
    tempALL_CHOSENOTHER = tempALL_CHOSENOTHER.filter(
      (x) => x === originalportfolionameCallbackCheck
    );
    // check if the portfolio name (initPortfolioName) (the portfolio the user chose to edit) exists
    // in the original chosen portoflios array
    if (!tempALL_CHOSENOTHER.length) {
      if (tempALL_CHOSEN.length === 1) {
        tempALL_CHOSEN = tempALL_CHOSEN.concat(portfolionameCallbackCheck); // add the portfolio if no portfolios are created
      } else {
        //tempALL_CHOSEN = tempALL_CHOSEN.concat("Default"); // add the portfolio if no portfolios are created
      }
    } else {
      tempALL_CHOSEN = tempALL_CHOSEN.filter(
        (x) => x !== originalportfolionameCallbackCheck
      );
      tempALL_CHOSEN = tempALL_CHOSEN.concat(portfolionameCallbackCheck);
    }
    chosenOnes = tempALL_CHOSEN;

    let entries = tempALLOther[0].entries;
    // Change portfolio name in current change to updated name
    entries.forEach((entry) => {
      if (
        entry.entry.selectedPortfolio === originalportfolionameCallbackCheck
      ) {
        entry.entry.selectedPortfolio = portfolionameCallbackCheck;
      }
    });
    const portfolioNew = {
      name: portfolionameCallbackCheck,
      assetClasses: portfolioAssetClassesCallbackCheck,
      entries: entries,
      settings: allData.portfolio.settings,
    };
    if (!temparr.length) {
      tempALL[0] = portfolioNew;
    } else {
      tempALL.push(portfolioNew);
    }
    // ----------------
    const finalPush = createDatafromSettingsProps(
      chosenOnes,
      tempALL,
      propsToChange,
      globalpropsToChange,
      allData.portfolio.settings,
      allData.allData.globalSettings,
      allData.allData.version
    );
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };

    allGlobalData.updateAllData(finalPush);

    await putUserData(S3Data, history);
    localStorage.setItem("savedsettings", "Saved");

    history.push("/BlankPage");
    history.goBack();
  };

  const innerHeader = "Settings";

  const movecursor = {
    paddingLeft: "10px",
    fontSize: "1.1em",
  };

  const goodSymbol = supportedCryptosCheck
    ? selectedSymboltemp
    : he.decode(selectedSymboltemp);
  const goodSymbolLength = goodSymbol.length;
  const goodSymbolStyling = {
    position: "absolute",
    left: "10px", // Adjust based on padding
    top: "53%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "#fbd665", // Style the symbol
  };
  const chosenPortfolios = allData.allData.chosenPortfolios;
  const toplabel = chosenPortfolios.length === 2 ? portfolio.name : "Default";

  let broker = selectedBrokerInfo.broker;
  let modallogo = "";
  let modallogodiv = "";

  if (broker === "robinhood") {
    modallogo = robinhoodlogo;
    modallogodiv = (
      <img className="linklogowrapperimgrh adjuster3" src={modallogo} alt="" />
    );
  } else if (broker === "metamask") {
    modallogo = metamasklogo;
    modallogodiv = (
      <img
        className="linklogowrapperimgrweb3 makebigger adjuster1"
        src={modallogo}
        alt=""
      />
    );
  } else if (broker === "metatrader4") {
    modallogo = mt4logo;
    modallogodiv = (
      <img
        className="linklogowrapperimgmt4 makebigger adjuster2"
        src={modallogo}
        alt=""
      />
    );
  } else if (broker === "metatrader5") {
    modallogo = mt5logo;
    modallogodiv = (
      <img
        className="linklogowrapperimgmt4 makebigger adjuster2"
        src={modallogo}
        alt=""
      />
    );
  }
  return (
    <div id="settingscompmain" className="noselect">
      {/* <span className="currencysymbolsheading">{innerHeader}</span> */}
      {
        <span className="currencysymbolsheading">
          {/* <div style={{ color: colorSetting }}>{toplabel}</div>&nbsp;Settings */}
          {toplabel} Settings
          {toplabel === "Default" && (
            <PopoverStickOnHover
              placement="bottom"
              delay={300}
              xAdjust={18}
              yAdjust={0}
              setClass="accountNumberSuperWrapper"
              keepOpen={false}
              component={
                <div
                  style={{
                    color: "#fff7cb",
                    padding: "2px",
                    width: 204,
                    textAlign: "left",
                  }}
                >
                  When you have no portfolios or more than one portfolio
                  selected, the settings will be set to a "default"
                  configuration. You can change the default settings, but it
                  isn't recommended.
                </div>
              }
            >
              <span className="tooptip-i-style77">
                <div className="tooptip-i-style77-innerbecausethebackgrounddecidedtoonedayhidethetextWTF">
                  &#x1D48A;
                </div>
              </span>
            </PopoverStickOnHover>
          )}
        </span>
      }
      <div id="settings-navigationbuttons-wrapper">
        {
          <button
            onClick={() => {
              setsettingShowState({
                ...settingShowState,
                general: "block",
                web3: "none",
                filtering: "none",
                importing: "none",
                subscription: "none",
                integrations: "none",
              });
            }}
            id="settings-navigationbuttons-button"
            className={
              settingShowState.general === "block"
                ? "settings-navigationbuttons-selected"
                : ""
            }
          >
            General
          </button>
        }
        {
          <button
            onClick={() => {
              setsettingShowState({
                ...settingShowState,
                general: "none",
                web3: "none",
                filtering: "block",
                importing: "none",
                subscription: "none",
                integrations: "none",
              });
            }}
            id="settings-navigationbuttons-button"
            className={
              settingShowState.filtering === "block"
                ? "settings-navigationbuttons-selected"
                : ""
            }
          >
            Global Filters
          </button>
        }
        {/*         <button
          onClick={() => {
            setsettingShowState({
              ...settingShowState,
              general: "none",
              web3: "none",
              filtering: "none",
              importing: "block",
              subscription: "none",
              integrations: "none",
            });
          }}
          id="settings-navigationbuttons-button"
          style={{
            color:
              settingShowState.importing === "block" ? "#fbd665" : "#deebf7",
          }}
        >
          Importing
        </button> */}
        {
          <button
            onClick={() => {
              setsettingShowState({
                ...settingShowState,
                general: "none",
                web3: "none",
                filtering: "none",
                importing: "none",
                subscription: "none",
                integrations: "block",
              });
            }}
            id="settings-navigationbuttons-button"
            className={
              settingShowState.integrations === "block"
                ? "settings-navigationbuttons-selected"
                : ""
            }
          >
            Integrations
          </button>
        }
        {/* <div
          style={{
            color: "#fff7cb",
            fontWeight: 400,
            marginTop: 30,
            marginBottom: 10,
          }}
        >
          {"Global Settings"}
        </div> */}
        {/*         <button
          onClick={() => {
            setsettingShowState({
              ...settingShowState,
              general: "none",
              web3: "block",
              filtering: "none",
              importing: "none",
              subscription: "none",
              integrations: "none",
            });
          }}
          id="settings-navigationbuttons-button"
          style={{
            color: settingShowState.web3 === "block" ? "#fbd665" : "#deebf7",
          }}
        >
          Web3
        </button>
        <button
          onClick={() => {
            setsettingShowState({
              ...settingShowState,
              general: "none",
              web3: "none",
              filtering: "none",
              importing: "none",
              subscription: "block",
              integrations: "none",
            });
          }}
          id="settings-navigationbuttons-button"
          style={{
            color:
              settingShowState.subscription === "block" ? "#fbd665" : "#deebf7",
          }}
        >
          Subscription
        </button> */}
      </div>
      {<div id="settings-navigationbuttons-line"></div>}

      <div className="select-settings">
        {settingShowState.general === "block" && (
          <span className="generalsettings">
            <div className="portfolioname-setting">
              <Title>Portfolio Name</Title>
              <label className="portfolionamesettinginner">
                {initPortfolioName === "Default" ? (
                  <div className="defaultSettingName noselect"> Default</div>
                ) : (
                  <input
                    style={movecursor}
                    value={createPortfolioName}
                    onChange={(e) => {
                      setcreatePortfolioName(e.target.value);
                    }}
                    className="text-input-boxes"
                    autoComplete="off"
                  />
                )}
              </label>
            </div>
            <div className="portfoliotypecontain-settings">
              <Title>Portfolio Asset Class(es)</Title>
              <div className="portfoliotypecontain-settings-inner">
                <label className="containerSA checksssg1">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="Stocks"
                    checked={Stocks || false}
                    onChange={(e) =>
                      setCheckedItems({
                        ...checkedItems,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">Stocks</div>
                </label>
                <label className="containerSA checksssg2">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="Forex"
                    checked={Forex || false}
                    onChange={(e) =>
                      setCheckedItems({
                        ...checkedItems,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">Forex</div>
                </label>
                <label className="containerSA checksssg5">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="Crypto"
                    checked={Crypto || false}
                    onChange={(e) =>
                      setCheckedItems({
                        ...checkedItems,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">Crypto</div>
                </label>
                <label className="containerSA checksssg3">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="Futures"
                    checked={Futures || false}
                    onChange={(e) =>
                      setCheckedItems({
                        ...checkedItems,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">Futures</div>
                </label>
                <label className="containerSA checksssg4">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="Options"
                    checked={Options || false}
                    onChange={(e) =>
                      setCheckedItems({
                        ...checkedItems,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">Options</div>
                </label>
                <label className="containerSA checksssg6">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="CFD"
                    checked={CFD || false}
                    onChange={(e) =>
                      setCheckedItems({
                        ...checkedItems,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext">CFD</div>
                </label>
              </div>
            </div>
            <div className="settingsColorWrapper">
              <Title>Portfolio Color </Title>
              <div
                id="headColorSuperWrapper2"
                onClick={() => {
                  !ismaster &&
                    props.setUpgradeModal({
                      show: true,
                      featureSelect: "Colored portfolios",
                      blur: false,
                      tierText: "master",
                    });
                }}
              >
                <input
                  type="color"
                  disabled={!ismaster}
                  id="headColorWrapper2"
                  name="head"
                  value={colorState}
                  onChange={handleColorChange}
                />
                <label className="containerSA nocolormovesettings">
                  <input
                    type="checkbox"
                    id="boxChangePortfolios"
                    name="colorcheckbox"
                    disabled={!ismaster}
                    checked={colorCheck || false}
                    onChange={(e) => {
                      if (!colorCheck) {
                        setcolorCheck(true);
                        setColorState("");
                      } else {
                        setColorState(colorSetting || "");
                        setcolorCheck(false);
                      }
                    }}
                  />
                  <span className="checkmarkSA checkmark-col1-2"></span>
                  <div className="porttext4488">No color</div>
                </label>
                {tierDiv2}
              </div>
            </div>
            <div className="startingbalsetting">
              <Title>
                Starting Balance{" "}
                <PopoverStickOnHover
                  placement="bottom"
                  delay={300}
                  xAdjust={18}
                  yAdjust={0}
                  setClass="accountNumberSuperWrapper"
                  keepOpen={false}
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        width: 198,
                        textAlign: "left",
                      }}
                    >
                      Initiates your portfolio with this balance without adding
                      a deposit entry. Recommended only if you aren't tracking
                      deposits/withdrawals, or you are starting a portfolio
                      without importing trades.
                    </div>
                  }
                >
                  <span className="tooptip-i-style7">&#x1D48A;</span>
                </PopoverStickOnHover>
              </Title>
              <label className="startingbalsettinginner">
                <span style={goodSymbolStyling}>{goodSymbol}</span>
                <input
                  style={{
                    ...movecursor,
                    paddingLeft: `${goodSymbolLength * 9 + 16}px`, // Add padding to account for the symbol
                  }}
                  value={startingBalance}
                  onChange={(e) => {
                    setstartingBalance(e.target.value);
                  }}
                  className="text-input-boxes"
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="select-currency-symbol">
              <Title>Currency Symbol</Title>
              <div className="select-currency-symbol-inner">
                <div className="currencySymbolSelectbuff">
                  <Select
                    styles={customStyles}
                    theme={theme}
                    defaultValue={handleDefault("")}
                  />
                </div>
                {handleDefault(selectedSymboltemp).value === "" ? (
                  <div></div>
                ) : (
                  <Select
                    styles={customStyles}
                    options={currencySymbolCodes2}
                    value={selectedSymbol}
                    onChange={(e) => setselectedSymbol(e)}
                    isSearchable={false}
                    theme={theme}
                    defaultValue={handleDefault(selectedSymboltemp)}
                  />
                )}
              </div>
            </div>
            <div className="select-calculation-type">
              <Title>
                {"Commissions & Fees"}{" "}
                <PopoverStickOnHover
                  placement="bottom"
                  delay={300}
                  xAdjust={18}
                  yAdjust={0}
                  setClass="accountNumberSuperWrapper"
                  keepOpen={false}
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        width: 202,
                        textAlign: "left",
                      }}
                    >
                      Includes commissions & fees in calculations for a more
                      accurate respresentation of your trading account.
                    </div>
                  }
                >
                  <span className="tooptip-i-style7">&#x1D48A;</span>
                </PopoverStickOnHover>
              </Title>
              <div className="select-calculation-type-inner">
                {"Off"}{" "}
                {/* tooltip says "referred to as "Gross" in the logbook */}
                <label
                  onClick={() => {
                    !ispro &&
                      props.setUpgradeModal({
                        show: true,
                        featureSelect: "Commissions/Fees",
                        blur: false,
                      });
                  }}
                  className="switch"
                >
                  <input
                    id="togglebutton"
                    type="checkbox"
                    disabled={ispro ? false : true}
                    checked={calcType}
                    onChange={(e) => {
                      setcalcType(e.target.checked);
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                {"On"} {/* tooltip says "referred to as "Net" in the logbook */}
                {tierDiv}
              </div>
            </div>{" "}
            <div className="select-calculation-type4">
              <Title>
                {"Fees Structure"}{" "}
                <PopoverStickOnHover
                  placement="bottom"
                  delay={300}
                  xAdjust={18}
                  yAdjust={0}
                  setClass="accountNumberSuperWrapper"
                  keepOpen={false}
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        width: 194,
                        textAlign: "left",
                      }}
                    >
                      Set the trading fees per contract for future trade
                      imports. Trades currently in the journal will not be
                      affected by changing your fee structure. Leave at 0 if you
                      enter fees manually or we provide fees during importing.
                    </div>
                  }
                >
                  <span className="tooptip-i-style7">&#x1D48A;</span>
                </PopoverStickOnHover>
              </Title>
              <label
                className="startingbalsettinginner"
                style={{ position: "relative" }}
              >
                <span style={goodSymbolStyling}>{goodSymbol}</span>
                <input
                  style={{
                    ...movecursor,
                    paddingLeft: `${goodSymbolLength * 9 + 16}px`, // Add padding to account for the symbol
                  }}
                  value={feesStructure}
                  onChange={(e) => {
                    setfeesStructure(e.target.value); // Do not include the symbol in the value
                  }}
                  className="text-input-boxes"
                  autoComplete="off"
                />
              </label>
            </div>
            <span className="manageaccountinnerheader32"></span>
            <div id="settingsactionbuttonwrapper">
              <div id="portmanageintbutton2label">Demo Data</div>
              <div className="leftadjustmanageintbutton2wrap">
                {toplabel === "Default" ? (
                  <span id="porttext445b">
                    <span>{"Not allowed "}</span>
                    <PopoverStickOnHover
                      placement="bottom"
                      delay={300}
                      xAdjust={18}
                      yAdjust={0}
                      keepOpen={false}
                      setClass="accountNumberSuperWrapper"
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            width: 236,
                            textAlign: "left",
                          }}
                        >
                          Demo data is not allowed for default settings. To add
                          demo data into a specific portfolio, use the portfolio
                          dropdown.
                        </div>
                      }
                    >
                      <span className="tooptip-i-style7">&#x1D48A;</span>
                    </PopoverStickOnHover>
                  </span>
                ) : loadDemoSetting ? (
                  loadDemoSetting === "bad" ? (
                    <div id="porttext445a">
                      Upgrade to allow 50+ entries & load demo data
                    </div>
                  ) : (
                    <div id="porttext445">
                      <button
                        className="deletedemodatabutton2 adjustdemdatabutton2"
                        onClick={() => {
                          deleteTrades();
                        }}
                      >
                        <div style={{ marginTop: "-2px" }}>
                          {"Delete Demo Data"}
                        </div>
                      </button>
                    </div>
                  )
                ) : (
                  <button
                    className="leftadjustmanageintbutton2"
                    id="portmanageintbutton2settings"
                    onClick={async () => await handleSaveDemoData()}
                  >
                    Load Demo Data
                  </button>
                )}
              </div>
            </div>
            {/*           <div className="select-currency-symbol">
            <Title>Broker/Exchange Time Zone</Title>
            <div className="select-currency-symbol-inner">
              <div className="currencySymbolSelectbuff">
                <Select
                  styles={customStyles}
                  theme={theme}
                  defaultValue={handleDefault("")}
                />
              </div>
              {handleDefault(selectedSymboltemp).value === "" ? (
                <div></div>
              ) : (
                <Select
                  styles={customStyles}
                  options={currencySymbolCodes}
                  value={selectedSymbol}
                  onChange={(selectedSymbol) =>
                    handleSymbolChange(selectedSymbol)
                  }
                  isSearchable={false}
                  theme={theme}
                  defaultValue={handleDefault(selectedSymboltemp)}
                />
              )}
            </div>
          </div> */}
          </span>
        )}

        {settingShowState.filtering === "block" && (
          <div className="filtersettings">
            <div className="select-datefilter-type">
              <Title>
                Autosave Filters{" "}
                <PopoverStickOnHover
                  placement="bottom"
                  delay={300}
                  xAdjust={18}
                  yAdjust={0}
                  keepOpen={false}
                  setClass="accountNumberSuperWrapper"
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        width: 184,
                        textAlign: "left",
                      }}
                    >
                      Auto-saves global filters per portfolio as you add/remove
                      them.
                    </div>
                  }
                >
                  <span className="tooptip-i-style7">&#x1D48A;</span>
                </PopoverStickOnHover>
              </Title>
              <div className="select-datefilter-type-inner">
                {"Off"}
                <label
                  className="switch"
                  onClick={() => {
                    !ispro &&
                      props.setUpgradeModal({
                        show: true,
                        featureSelect: "Save global filters",
                        blur: false,
                      });
                  }}
                >
                  <input
                    key="2"
                    id="togglebuttonDF"
                    type="checkbox"
                    disabled={ispro ? false : true}
                    checked={saveFilters}
                    onChange={(e) => setsaveFilters(e.target.checked)}
                  />
                  <span className="slider round"></span>
                </label>
                {"On"}
                {tierDiv}
              </div>
            </div>
            <div className="select-datefilter-type2">
              <Title>
                {"Filter Dates & Times By"}{" "}
                <PopoverStickOnHover
                  placement="bottom"
                  delay={300}
                  xAdjust={18}
                  yAdjust={0}
                  keepOpen={false}
                  setClass="accountNumberSuperWrapper"
                  component={
                    <div
                      style={{
                        color: "#fff7cb",
                        padding: "2px",
                        width: 218,
                        textAlign: "left",
                      }}
                    >
                      When applying the "Date Range" global filter, trades shown
                      will be based on the dates you select, and this setting.
                    </div>
                  }
                >
                  <span className="tooptip-i-style7">&#x1D48A;</span>
                </PopoverStickOnHover>
              </Title>
              <div className="select-datefilter-type-inner3">
                {"First Entry"}
                <label className="switch">
                  <input
                    key="4"
                    id="togglebuttonDF"
                    type="checkbox"
                    checked={dateFilterBy}
                    onChange={(e) => setdateFilterBy(e.target.checked)}
                  />
                  <span className="slider round"></span>
                </label>
                {"Last Exit"}
              </div>
            </div>
          </div>
        )}
        {settingShowState.integrations === "block" && (
          <div className="integrationsettings">
            <div id="settingsactionbuttonwrapper2">
              {/*               {<div id="portmanageintbuttonlabel">Integration</div>}{" "}
               */}
              {selectedBrokerInfo.broker === "" ? (
                <div style={{ marginLeft: "-160px", fontSize: "1.2em" }}>No broker connection.</div>
              ) : (
                <div>
                  <div
                    style={{
                      fontSize: "1.1em",
                      display: "flex",
                      "flex-direction": "row",
                      margin: "0px 0px 26px 0px",
                    }}
                  >
                    Connection: &nbsp; {modallogodiv}
                  </div>
                  <span
                    style={{
                      fontSize: "1.1em",
                      display: "flex",
                      "flex-direction": "row",
                      margin: "0px 0px 26px 0px",
                    }}
                  >
                    Connected on &nbsp;
                    <span
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      {new Date(
                        selectedBrokerInfo.integrationDate
                      ).toLocaleString()}
                    </span>
                  </span>
                  <button
                    className="leftadjustmanageintbutton"
                    id="portmanageintbuttonsettings"
                    onClick={async () => {
                      history.push("/Integrations");
                    }}
                  >
                    Manage Integration
                  </button>
                </div>
              )}
            </div>{" "}
          </div>
        )}
      </div>
      {/*       {
        <div
          className="importsettings"
          style={{ display: settingShowState.importing }}
        >
          <span className="currencysymbolsinnerheader2">Importing</span>
          <div className="select-autogroup-trades">
            <Title>Auto-Group Trades</Title>
            <div className="select-datefilter-type-inner3auto">
              {"Off"}
              <label className="switch">
                <input
                  key="5"
                  id="togglebuttonDF"
                  type="checkbox"
                  checked={false}
                  onChange={(e) => alert("coming soon")}
                />
                <span className="slider round"></span>
              </label>
              {"On (Coming soon)"}
            </div>
          </div>
        </div>
      } */}
      {/*       <div
        className="billingsettings"
        style={{ display: settingShowState.subscription }}
      >
        <span className="currencysymbolsinnerheaderbilling">Subscription</span>
        <div className="subscription-setting-text">
          You are currently a Beta user!
        </div>
      </div> */}
      {!isDisabled && (
        <button
          className="undosettingschangesbutton"
          onClick={() => undoChanges()}
        >
          Undo Changes
        </button>
      )}
      {settingShowState.integrations !== "block" && (
        <button
          disabled={isDisabled}
          className="applysettingsbutton"
          onClick={() => handleSelectSubmit()}
        >
          Save Settings
        </button>
      )}
    </div>
  );
};

export default SettingsComponent;
