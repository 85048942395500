const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
export const isTradeClosed = (trade) => {
  const entryExecs = trade.entry.multiExecution;
  let entryLots = 0;
  entryExecs.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  let exitLots = 0;
  const exitExecs = trade.entry.exitExecution;
  exitExecs.forEach((exec) => {
    exitLots += Number(exec.exitLotSize);
  });
  entryLots = fixRound(entryLots); // fix rounding issue
  exitLots = fixRound(exitLots);
  if (exitLots !== entryLots) {
    return false;
  } else {
    return true;
  }
};
export const isTradeClosed2 = (multiExecution, exitExecution) => {
  const entryExecs = multiExecution;
  let entryLots = 0;
  entryExecs.forEach((exec) => {
    entryLots += Number(exec.lotSize);
  });
  let exitLots = 0;
  const exitExecs = exitExecution;
  exitExecs.forEach((exec) => {
    exitLots += Number(exec.exitLotSize);
  });
  entryLots = fixRound(entryLots); // fix rounding issue
  exitLots = fixRound(exitLots);
  if (exitLots !== entryLots) {
    return false;
  } else {
    return true;
  }
};
export default isTradeClosed;
