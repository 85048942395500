import getUniqueItemsByProps from "../getUniqueItemsByProps";
import $ from "jquery";
import { getPortfoliowSpecificBrokerIntegration } from "../getSpecificPortfolio";
import parsedYdXOrders from "../autoimport/parsers/dydx";
import parseRobinhoodOrders from "./../autoimport/parsers/robinhood";
import addNewEntriesandIds from "./../autoimport/addNewEntriesandIds";
import {
  getRobinhoodCryptoInstrument,
  getNewRobinhoodTransactions,
} from "../../functions/robinhood/utils";
import putUserData from "../../utils/AWS/putS3UserObject";
import { ethers } from "ethers";
import parseEtherOrders from "./../../functions/autoimport/parsers/etherwallet";
import parseMetaTraderOrders from "./../../functions/autoimport/parsers/metatrader";
import MetaApi, { HistoryStorage } from "metaapi.cloud-sdk";
import isArray from "../arrayFunctions/isArray";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";

const NodeRSA = require("node-rsa");

const { DydxClient } = require("@dydxprotocol/v3-client");
const key = new NodeRSA();
const metaapi = new MetaApi(process.env.REACT_APP_METAAPIKEY);

const ethereum = window.ethereum;
const etherscanProvider =
  ethereum &&
  new ethers.providers.EtherscanProvider(
    "homestead",
    process.env.REACT_APP_ETHERSCAN_KEY
  );
const privatePem = `-----BEGIN RSA PRIVATE KEY-----${process.env.REACT_APP_PRIVATE_KEY}-----END RSA PRIVATE KEY-----`;
key.importKey(privatePem, "pkcs1-pem");

export async function initialLoadMerge(
  brokerInfoArray,
  allDataIn,
  userIdIN,
  history
) {
  const userId = userIdIN;
  const verified = 2;
  // have to call getSpecificPortfolio b/c we haven't called processDataandSettings yet
  let allDataRef = allDataIn;
  const runAllBrokerFetch = async (allDataRefIn) => {
    let allDataRefInner = allDataRefIn;
    for (var ii = 0, jj = brokerInfoArray.length; ii < jj; ii++) {
      if (brokerInfoArray[ii].broker === "dYdX") {
        let portfolio = {};
        if (Object.keys(allDataRefInner).length === 0) {
        } else {
          portfolio = getPortfoliowSpecificBrokerIntegration(
            allDataRefInner,
            brokerInfoArray[ii].integrationId
          );
        }
        const entries = portfolio.entries;
        let trades = [...entries];
        let ids = [...brokerInfoArray[ii].ids];
        const apiCredsPre = brokerInfoArray[ii].cred;

        //decrypt credentials
        const decryptedString = key.decrypt(apiCredsPre, "utf8");
        const apiCreds = JSON.parse(decryptedString);

        // --------
        const timestamp = new Date().toISOString();

        const HTTP_HOST = "https://api.dydx.exchange";

        const client = new DydxClient(HTTP_HOST, {
          apiKeyCredentials: apiCreds,
        });
        client.private.sign({
          requestPath: "/v3/",
          method: "GET",
          isoTimestamp: timestamp,
        });

        let tradedata = [];
        // get fills, transfers and funding transactions
        // because they are limited to 100 we need to loop through
        const pushtrades = (trades, topush) => {
          trades.forEach((item) => {
            topush.push(item);
          });
          // use this function to prevent the error
          // Function declared in a loop contains unsafe references
          // to variable(s) 'fills' **or fundingfees or depositswithdrawals
        };
        let createdBeforeAt = timestamp;
        let nomorepages = false;
        let fills = [];
        let iteration = 0;
        while (!nomorepages) {
          const fillsper = await client.private.getFills({
            limit: 100,
            createdBeforeOrAt: createdBeforeAt,
          });
          let trades = fillsper.fills;
          if (trades.length < 100 && iteration > 0) {
            nomorepages = true;
          } else {
          }
          const [lastItem] = trades.slice(-1);
          let lasttradeDatePre = lastItem && new Date(lastItem.createdAt);
          let lasttradeDate =
            lasttradeDatePre &&
            new Date(
              lasttradeDatePre.setSeconds(lasttradeDatePre.getSeconds() + 1)
            ).toISOString(); // add 1 sec
          createdBeforeAt = lasttradeDate;
          pushtrades(trades, fills);
          iteration++;
        }

        let depositswithdrawals = [];
        nomorepages = false; // reset this var
        createdBeforeAt = timestamp; //and this one
        iteration = 0; //and this one
        while (!nomorepages) {
          const dwsper = await client.private.getTransfers({
            limit: 100,
            createdBeforeOrAt: createdBeforeAt,
          });
          let trades = dwsper.transfers;
          if (trades.length < 100 && iteration > 0) {
            nomorepages = true;
          } else {
          }
          const [lastItem] = trades.slice(-1);
          let lasttradeDatePre = lastItem && new Date(lastItem.createdAt);
          let lasttradeDate =
            lasttradeDatePre &&
            new Date(
              lasttradeDatePre.setSeconds(lasttradeDatePre.getSeconds() + 1)
            ).toISOString(); // add 1 sec
          createdBeforeAt = lasttradeDate;
          pushtrades(trades, depositswithdrawals);
          iteration++;
        }

        let fundingfees = [];
        nomorepages = false; // reset this var
        createdBeforeAt = timestamp; //and this one
        iteration = 0; //and this one
        while (!nomorepages) {
          const fundingsper = await client.private.getFundingPayments({
            limit: 100,
            effectiveBeforeOrAt: createdBeforeAt,
          });
          let trades = fundingsper.fundingPayments;
          if (trades.length < 100 && iteration > 0) {
            nomorepages = true;
          } else {
          }
          const [lastItem] = trades.slice(-1);
          let lasttradeDatePre = lastItem && new Date(lastItem.effectiveAt);
          let lasttradeDate =
            lasttradeDatePre &&
            new Date(
              lasttradeDatePre.setSeconds(lasttradeDatePre.getSeconds() - 0.01)
            ).toISOString(); // add 1 sec
          createdBeforeAt = lasttradeDate;
          pushtrades(trades, fundingfees);
          iteration++;
        }

        nomorepages = false; // reset this var
        createdBeforeAt = timestamp; //and this one

        // REMOVE DUPLICATES
        fills = getUniqueItemsByProps(fills, ["id"]);
        depositswithdrawals = getUniqueItemsByProps(depositswithdrawals, [
          "id",
        ]);
        fundingfees = getUniqueItemsByProps(fundingfees, [
          "market",
          "effectiveAt",
          "price",
          "positionSize",
          "rate",
          "payment",
        ]);
        fills.forEach((item) => {
          tradedata.push(item);
        });
        depositswithdrawals.forEach((item) => {
          tradedata.push(item);
        });
        fundingfees.forEach((item) => {
          tradedata.push(item);
        });

        // SORT BEFORE DOING STUFF TO tradedata
        tradedata &&
          tradedata.sort(function (a, b) {
            var c = new Date(
              !a["createdAt"] ? a["effectiveAt"] : a["createdAt"]
            );
            var d = new Date(
              !b["createdAt"] ? b["effectiveAt"] : b["createdAt"]
            );
            return d - c;
          });
        tradedata && tradedata.reverse();

        // INITIALLY CREATE ARRAY OF TRADES FROM DYDX
        // SHAVE OFF THE TRADES ALREADY CREATED
        // must look for open trades

        // PARSE INCOMING DYDX ORDERS
        // CREATE NEW IDS ARRAY
        const parsedOrdersandIds = parsedYdXOrders(
          trades,
          ids,
          portfolio,
          tradedata,
          userId,
          verified
        );

        trades = parsedOrdersandIds.trades;
        ids = parsedOrdersandIds.ids;

        // -------- NEED TO ADD THE NEW ENTRIES TO CHOSENPORTFOLIOS AND CREATEDPORTFOLIOS --------
        // ADD THE NEW ENTRIES TO THE CORRESPONDING EXISTING DATA
        const preSetEntriesandIds = addNewEntriesandIds(
          allDataRefInner,
          portfolio,
          ids,
          trades
        );
        allDataRefInner["createdPortfolios"] = preSetEntriesandIds;
        // -----------------------------------------------
      } else if (brokerInfoArray[ii].broker === "robinhood") {
        let portfolio = {};
        if (Object.keys(allDataRefInner).length === 0) {
        } else {
          portfolio = getPortfoliowSpecificBrokerIntegration(
            allDataRefInner,
            brokerInfoArray[ii].integrationId
          );
        }
        const entries = portfolio.entries;
        let trades = [...entries];
        const idsToCheck = [...brokerInfoArray[ii].ids];

        const accessToken = localStorage.getItem(
          `robinhoodToken${brokerInfoArray[ii].portfolio}`
        );
        /*         let accessToken = accessTokenPre && key.decrypt(accessTokenPre, "utf8");
        accessToken = accessToken?.replace(/"/g, ""); */
        if (!accessToken) {
          // if the access token doesn't exist, don't do anything
        } else {
          const robinhoodCryptoInstrumentsResponse =
            await getRobinhoodCryptoInstrument(null);
          const robinhoodCryptoInstruments =
            robinhoodCryptoInstrumentsResponse &&
            robinhoodCryptoInstrumentsResponse.gotRobinhoodCryptoInstruments
              .instrument.results;
          const robinhoodData = await getNewRobinhoodTransactions(
            portfolio,
            idsToCheck,
            accessToken
          );
          const robinhoodOrders =
            robinhoodData && robinhoodData.robinhoodOrders;
          // Filter crypto instrument by Id
          const filtercryptoByID = (id) => {
            const filteredInstrument = robinhoodCryptoInstruments?.filter(
              (instrument) => instrument.id === id
            );
            return filteredInstrument[0]?.symbol;
          };

          let tradedata = robinhoodOrders;
          let ids = [...brokerInfoArray[ii].ids];

          // SORT BEFORE DOING STUFF TO tradedata
          tradedata &&
            tradedata.sort(function (a, b) {
              var c = new Date(
                !a["created_at"]
                  ? !a["updated_at"]
                    ? a["initiated_at"]
                    : a["updated_at"]
                  : a["created_at"]
              );
              var d = new Date(
                !b["created_at"]
                  ? !b["updated_at"]
                    ? b["initiated_at"]
                    : b["updated_at"]
                  : b["created_at"]
              );
              return d - c;
            });
          tradedata && tradedata.reverse();

          // PARSE INCOMING ROBINHOOD ORDERS
          // CREATE NEW IDS ARRAY
          const parsedOrdersandIds = await parseRobinhoodOrders(
            trades,
            ids,
            portfolio,
            tradedata,
            userId,
            filtercryptoByID,
            verified,
            accessToken
          );

          trades = parsedOrdersandIds.trades;
          ids = parsedOrdersandIds.ids;

          // ADD THE NEW ENTRIES TO THE CORRESPONDING EXISTING DATA

          const preSetEntriesandIds = addNewEntriesandIds(
            allDataRefInner,
            portfolio,
            ids,
            trades
          );

          allDataRefInner["createdPortfolios"] = preSetEntriesandIds;
        }
      } else if (
        brokerInfoArray[ii].broker === "metatrader4" ||
        brokerInfoArray[ii].broker === "metatrader5"
      ) {
        /*         const mtsocket =
          isArray(sockets.current) &&
          sockets.current.filter((socket) => socket.type === "metatrader");
        console.log(mtsocket); */
        let portfolio = {};
        if (Object.keys(allDataRefInner).length === 0) {
        } else {
          portfolio = getPortfoliowSpecificBrokerIntegration(
            allDataRefInner,
            brokerInfoArray[ii].integrationId
          );
        }
        const entries = portfolio.entries;
        let trades = [...entries];
        let ids = [...brokerInfoArray[ii].ids];
        const apiCredsPre = brokerInfoArray[ii].cred;
        const marginMode = brokerInfoArray[ii].marginMode;
        //decrypt credentials
        const decryptedString = key.decrypt(apiCredsPre, "utf8");
        const apiCreds = JSON.parse(decryptedString);
        // Get trades
        try {
          const account = await metaapi.metatraderAccountApi.getAccount(
            apiCreds
          );

          if (!account) {
          } else {
            try {
              if (account.state !== "DEPLOYED") {
                //store.dispatch(setLoaderState({ loading: false }));
                localStorage.setItem("autoiterationMessage", `reconnecting`);
                await account.deploy();
              } else {
                //console.log("Account already deployed");
              }
              const connection = account.getStreamingConnection();

              if (account.connectionStatus !== "CONNECTED") {
                localStorage.setItem("autoiterationMessage", `reconnecting`);
                await account.waitConnected();
              } else {
              }
              await connection.connect();

              // wait until synchronization completed
              await connection.waitSynchronized();
              // access history storage
              const historyStorage = connection.historyStorage;
              // both orderSynchronizationFinished and dealSynchronizationFinished
              // should be true once history synchronization have finished
              let tradedata = historyStorage.deals;
              // PARSE INCOMING DYDX ORDERS
              // CREATE NEW IDS ARRAY
              const parsedOrdersandIds = parseMetaTraderOrders(
                trades,
                ids,
                portfolio,
                tradedata,
                userId,
                verified,
                marginMode
              );
              trades = parsedOrdersandIds.trades;
              ids = parsedOrdersandIds.ids;

              // ADD THE NEW ENTRIES TO THE CORRESPONDING EXISTING DATA

              const preSetEntriesandIds = addNewEntriesandIds(
                allDataRefInner,
                portfolio,
                ids,
                trades
              );

              allDataRefInner["createdPortfolios"] = preSetEntriesandIds;
              localStorage.removeItem("autoiterationMessage");
            } catch (err) {
              console.log(err);
            }
          }
        } catch (err) {
          localStorage.removeItem("autoiterationMessage");
          // process errors
          if (err.details) {
            // returned if the server file for the specified server name has not been found
            // recommended to check the server name or create the account using a provisioning profile
            if (err.details === "E_SRV_NOT_FOUND") {
              console.error(err);
              // returned if the server has failed to connect to the broker using your credentials
              // recommended to check your login and password
            } else if (err.details === "E_AUTH") {
              console.log(err);
              // returned if the server has failed to detect the broker settings
              // recommended to try again later or create the account using a provisioning profile
            } else if (err.details === "E_SERVER_TIMEZONE") {
              console.log(err);
            }
          }
        }
      } else if (brokerInfoArray[ii].broker === "metamask") {
        let portfolio = {};
        if (Object.keys(allDataRefInner).length === 0) {
        } else {
          portfolio = getPortfoliowSpecificBrokerIntegration(
            allDataRefInner,
            brokerInfoArray[ii].integrationId
          );
        }
        const entries = portfolio.entries;
        let trades = [...entries];

        // ----------- METAMASK -----------
        if (ethereum) {
          // From now on, this should always be true:
          // provider === window.ethereum
          let ids = [...brokerInfoArray[ii].ids];
          const account = await ethereum.request({
            method: "eth_requestAccounts",
          });
          const address = account[0].toLowerCase();

          // Step 1: Fetch External Transactions
          const externalHistory = await etherscanProvider.getHistory(address);

          // Step 2: Fetch Internal Transactions using Etherscan API
          const internalResponse = await fetch(
            `https://api.etherscan.io/api?module=account&action=txlistinternal&address=${address}&apikey=${process.env.REACT_APP_ETHERSCAN_KEY}`
          );

          if (!internalResponse.ok) {
            throw new Error(
              `Failed to fetch internal transactions: ${internalResponse.statusText}`
            );
          }

          const internalData = await internalResponse.json();
          if (internalData.status !== "1") {
            console.warn(
              "No internal transactions found or error from Etherscan:",
              internalData.message
            );
            return externalHistory; // Return external history only if no internal transactions
          }

          const internalHistory = internalData.result;

          // Combine External and Internal Transactions
          const externalHashes = new Set(externalHistory.map((tx) => tx.hash));

          const fullHistory = [
            ...externalHistory,
            ...internalHistory
              .filter((transaction) => !externalHashes.has(transaction.hash))
              .map((transaction) => {
                return { ...transaction, isInternal: true };
              }),
          ];
          
          // Step 3: Sort Transactions by Timestamp (Earliest to Latest)
          const history = fullHistory.sort((a, b) => {
            const timestampA = Number(a.timeStamp)
              ? Number(a.timeStamp)
              : Number(a.timestamp); // Ensure timestamps are numbers
            const timestampB = Number(b.timeStamp)
              ? Number(b.timeStamp)
              : Number(b.timestamp);
            return timestampA - timestampB;
          });

          const historyFiltered = history.filter(
            (tx) => !ids.includes(tx.hash)
          );
          const parsedOrdersandIds = await parseEtherOrders(
            trades,
            ids,
            portfolio,
            historyFiltered,
            userId,
            verified,
            address
          );
          trades = parsedOrdersandIds.trades;
          ids = parsedOrdersandIds.ids;

          // ADD THE NEW ENTRIES TO THE CORRESPONDING EXISTING DATA

          const preSetEntriesandIds = addNewEntriesandIds(
            allDataRefInner,
            portfolio,
            ids,
            trades
          );

          allDataRefInner["createdPortfolios"] = preSetEntriesandIds;
        } else {
          // Some warning to install metamask
        }
      }
    }
    return allDataRefInner;
  };
  const allDataRefReturn = await runAllBrokerFetch(allDataRef);

  // LOGBOOK STATE
  // SET SKYNET DATA

  const finalPush = {
    chosenPortfolios: allDataRefReturn.chosenPortfolios,
    createdPortfolios: allDataRefReturn.createdPortfolios,
    globalSettings: allDataRefReturn.globalSettings,
    version: Number(allDataRefReturn.version) + 1,
    sharedTrades: allDataRefReturn.sharedTrades,
  };
  $(window).bind("beforeunload", function () {
    return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
  });
  localStorage.setItem("dbSetRunning", "yes");

  // Set S3 Data
  const S3Data = {
    data: finalPush,
    userId: userId,
  };
  const customerId =
    finalPush === null ? "" : finalPush.globalSettings.stripeId;
  await updateStripeCustomerWrapper(
    customerId,
    finalPush,
    "trades",
    userId,
    history
  );

  const finalAwait = async () => {
    await putUserData(S3Data, history);
    $(window).unbind("beforeunload");
    localStorage.setItem("dbSetRunning", "no");
    localStorage.removeItem("initialLoad");
  };
  await finalAwait();
  return finalPush;
}
export default initialLoadMerge;
