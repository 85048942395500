import React from "react";
import AdvancedChart from "./advanced.js";
import TradingViewWidget from "./index.js";
import BlockchainDataFeed from "./blockhainDataFeed.js";

const TradingViewChart = ({
  symbol,
  currentTrade,
  dataExists,
  address,
  startDateTime,
  endDateTime,
}) => {
  const datafeed = new BlockchainDataFeed(
    symbol,
    address,
    startDateTime,
    endDateTime
  );

  // 12/06/24 - will eventually be master -> widget, ultimate -> advanced charts once I get all the data
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {dataExists ? (
        <AdvancedChart
          symbol={symbol}
          datafeed={datafeed}
          address={address}
          currentTrade={currentTrade}
          containerId="tv_chart_container"
        />
      ) : (
        <TradingViewWidget symbol={symbol} />
      )}
    </div>
  );
};

export default TradingViewChart;
