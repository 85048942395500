import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import "./styles.scss";
import PricingTier from "./pricingTier";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import { useHistory } from "react-router-dom";
import putUserData from "../../utils/AWS/putS3UserObject";
import showConfirmedActionButton from "../../functions/showConfirmedActionButton";
import stripePriceIds from "./../../utils/stripe/stripetiers";
//import StripePricingTable from "./../../components/StripePricingTable";
import { DataContext } from "../../DataContext";
import { v4 as uuidv4 } from "uuid";
import Modal from "react-modal";
import deleteUserData from "../../utils/AWS/deleteS3UserObject";
import { setLoaderState } from "../../actions/actionCreators";
import store from "../../store";
import { Auth0Context } from "@auth0/auth0-react";
import cancelSubscriptions from "./../../utils/stripe/cancelAllSubscriptions";
import proDiamond from "./../../images/186300.png";
import usePrevious from "../../hooks/usePrevious";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faPowerOff,
  faCheckCircle,
  faCaretLeft,
  faCaretRight,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import PromoCodeElement from "./promoCodeElement";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import createStripeSession from "./../../utils/stripe/createStripeSession";
import createStripePortalSession from "./../../utils/stripe/createStripePortalSession";

function formatUnixTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getUTCDate();
  let daySuffix = "th";

  if (day === 1 || day === 21 || day === 31) {
    daySuffix = "st";
  } else if (day === 2 || day === 22) {
    daySuffix = "nd";
  } else if (day === 3 || day === 23) {
    daySuffix = "rd";
  }

  const month = months[date.getUTCMonth()];

  return `${month} ${day}${daySuffix}`;
}

var axios = require("axios").default;
const proxyURL = "https://api.tradefuse.app/updateStripeCustomer";

export const Title = styled.div`
  color: #deebf7;
  font-weight: 400;
  white-space: nowrap;
`;
export const RedTitle = styled.div`
  color: #d41c35;
  font-weight: 500;                       
  white-space: nowrap;
`;
function getKeyByValue(object, value) {
  return Object.keys(object).filter((key) => object[key] === value);
}
function getQueryParam(url, param) {
  const queryParams = new URLSearchParams(url.search);
  return queryParams.get(param);
}
const integrationMaxCountValue = (priceId) => {
  let integrationMaxCount = 0;
  if (
    priceId === "price_1NrZ3SJEnF6qjMZifdKYlDFo" ||
    priceId === "price_1NrZ3BJEnF6qjMZiC0mbaEzN" ||
    priceId === "price_1NrZ2cJEnF6qjMZiREJtN0bg" ||
    priceId === "price_1Mla3qJEnF6qjMZijRgbEgN3" // Free Master
  ) {
    integrationMaxCount = 5;
  } else if (
    priceId === "price_1NrcgQJEnF6qjMZiyuU53gwV" ||
    priceId === "price_1NrcgBJEnF6qjMZiHHyqL7zj" ||
    priceId === "price_1NrcfhJEnF6qjMZiefJh6cra" ||
    priceId === "price_1NNs2kJEnF6qjMZiRWeutOMh" // Free Ultimate
  ) {
    integrationMaxCount = 10;
  }
  return integrationMaxCount;
};
const payCardStateInitValue = (priceId) => {
  let payCardStateInit = "Monthly";
  if (
    priceId === "price_1NrZ9gJEnF6qjMZiGZBT2yOK" ||
    priceId === "price_1NrZ3SJEnF6qjMZifdKYlDFo" ||
    priceId === "price_1NrZ4jJEnF6qjMZiJjvHqJT0" ||
    priceId === "price_1NrZ7AJEnF6qjMZiUgjO2W1q" ||
    priceId === "price_1Nr5PrJEnF6qjMZihHXdaxIo" ||
    priceId === "price_1NrcgQJEnF6qjMZiyuU53gwV" ||
    priceId === "price_1NrchLJEnF6qjMZiBxInopoT" ||
    priceId === "price_1NrciMJEnF6qjMZiGXzuyOfu" ||
    priceId === "price_1NrcjTJEnF6qjMZilagP5YoA" ||
    priceId === "price_1NrckLJEnF6qjMZi7Z3y3Yi7"
  ) {
    payCardStateInit = "Monthly";
  } else if (
    priceId === "price_1NrZ9XJEnF6qjMZilJdClA9a" ||
    priceId === "price_1NrZ3BJEnF6qjMZiC0mbaEzN" ||
    priceId === "price_1NrZ4UJEnF6qjMZi1v4TgU02" ||
    priceId === "price_1NrZ6KJEnF6qjMZiAaU4KZKz" ||
    priceId === "price_1Nr5PjJEnF6qjMZiVIBveXUn" ||
    priceId === "price_1NrcgBJEnF6qjMZiHHyqL7zj" ||
    priceId === "price_1Nrch2JEnF6qjMZiEGeV65e8" ||
    priceId === "price_1Nrci7JEnF6qjMZiU55b7OP5" ||
    priceId === "price_1Nrcj3JEnF6qjMZijnp3WNFm" ||
    priceId === "price_1Nrck6JEnF6qjMZizx8aB3PQ"
  ) {
    payCardStateInit = "3-Month";
  } else if (
    priceId === "price_1NrZ9HJEnF6qjMZicUdwt4Az" ||
    priceId === "price_1NrZ2cJEnF6qjMZiREJtN0bg" ||
    priceId === "price_1NrZ3xJEnF6qjMZiLyNIWVyZ" ||
    priceId === "price_1NrZ5FJEnF6qjMZiHoyWDR4o" ||
    priceId === "price_1Nr5PTJEnF6qjMZiqqEDvCCM" ||
    priceId === "price_1NrcfhJEnF6qjMZiefJh6cra" ||
    priceId === "price_1NrcgjJEnF6qjMZiaLXJ0wuf" ||
    priceId === "price_1NrchkJEnF6qjMZi8UVeSqNY" ||
    priceId === "price_1NrcihJEnF6qjMZibyfQKCST" ||
    priceId === "price_1NrcjpJEnF6qjMZiTlAJ2R1W"
  ) {
    payCardStateInit = "Yearly";
  }
  return payCardStateInit;
};
export const ManageAccountComponent = (props) => {
  const allGlobalData = useContext(DataContext);
  const auth0Instance = useContext(Auth0Context);
  const userEmail = auth0Instance.user.email;
  const userSubscriptionsPre = allGlobalData.customer.subscription;
  const userSubscriptions = userSubscriptionsPre.map((sub) => {
    return sub.id;
  });
  const userId = auth0Instance.user.sub;
  const signInType =
    userId.indexOf("google") !== -1
      ? "Google"
      : userId.indexOf("discord") !== -1
      ? "Discord"
      : userId.indexOf("auth0") !== -1
      ? "Auth0"
      : "";

  const auth0options = {
    method: "POST",
    url: "https://tradefuse.us.auth0.com/dbconnections/change_password",
    headers: { "content-type": "application/json" },
    data: {
      client_id: "FRUYoAzA3soAkQ73grtjVPezQfo2wEfS",
      email: userEmail,
      connection: "LogBookDB",
    },
  };
  const customer = allGlobalData.customer;
  const active = customer.active;
  const tier = customer.tier;
  const priceId = customer.priceId;

  let integrationMaxCount = integrationMaxCountValue(priceId);
  const trialEnd = allGlobalData?.customer?.subscription[0]?.trial_end;
  const billingEmail = customer.email;
  const initState = props.state;
  const { user /* isAuthenticated */ } = useAuth0();
  const history = useHistory();
  //const globalSettings = dataInstance.allData.globalSettings;
  const allData = props.allData;
  const globalSettings = allData.allData.globalSettings;
  const userNameINIT = globalSettings.username || "";
  const stripeId = globalSettings.stripeId;

  const currentUrl = new URL(window.location.href);

  // Extract the utm_source parameter
  const utmpageIndex = getQueryParam(currentUrl, "utm_pageIndex");

  let prePriceID = "";
  if (
    priceId === stripePriceIds.monthly ||
    priceId === stripePriceIds.monthlymaster ||
    priceId === stripePriceIds.monthlyultimate
  ) {
    prePriceID = "Monthly";
  } else if (
    priceId === stripePriceIds.quarterly ||
    priceId === stripePriceIds.quarterlymaster ||
    priceId === stripePriceIds.quarterlyultimate
  ) {
    prePriceID = "Quarterly";
  } else if (
    priceId === stripePriceIds.annual ||
    priceId === stripePriceIds.annualmaster ||
    priceId === stripePriceIds.annualultimate
  ) {
    prePriceID = "Annual";
  } else {
    prePriceID = "Monthly";
  }
  let payCardStateInit = payCardStateInitValue(priceId);

  if (
    priceId === "price_1NrZ9gJEnF6qjMZiGZBT2yOK" ||
    priceId === "price_1NrZ3SJEnF6qjMZifdKYlDFo" ||
    priceId === "price_1NrZ4jJEnF6qjMZiJjvHqJT0" ||
    priceId === "price_1NrZ7AJEnF6qjMZiUgjO2W1q" ||
    priceId === "price_1Nr5PrJEnF6qjMZihHXdaxIo" ||
    priceId === "price_1NrcgQJEnF6qjMZiyuU53gwV" ||
    priceId === "price_1NrchLJEnF6qjMZiBxInopoT" ||
    priceId === "price_1NrciMJEnF6qjMZiGXzuyOfu" ||
    priceId === "price_1NrcjTJEnF6qjMZilagP5YoA" ||
    priceId === "price_1NrckLJEnF6qjMZi7Z3y3Yi7"
  ) {
    payCardStateInit = "Monthly";
  } else if (
    priceId === "price_1NrZ9XJEnF6qjMZilJdClA9a" ||
    priceId === "price_1NrZ3BJEnF6qjMZiC0mbaEzN" ||
    priceId === "price_1NrZ4UJEnF6qjMZi1v4TgU02" ||
    priceId === "price_1NrZ6KJEnF6qjMZiAaU4KZKz" ||
    priceId === "price_1Nr5PjJEnF6qjMZiVIBveXUn" ||
    priceId === "price_1NrcgBJEnF6qjMZiHHyqL7zj" ||
    priceId === "price_1Nrch2JEnF6qjMZiEGeV65e8" ||
    priceId === "price_1Nrci7JEnF6qjMZiU55b7OP5" ||
    priceId === "price_1Nrcj3JEnF6qjMZijnp3WNFm" ||
    priceId === "price_1Nrck6JEnF6qjMZizx8aB3PQ"
  ) {
    payCardStateInit = "3-Month";
  } else if (
    priceId === "price_1NrZ9HJEnF6qjMZicUdwt4Az" ||
    priceId === "price_1NrZ2cJEnF6qjMZiREJtN0bg" ||
    priceId === "price_1NrZ3xJEnF6qjMZiLyNIWVyZ" ||
    priceId === "price_1NrZ5FJEnF6qjMZiHoyWDR4o" ||
    priceId === "price_1Nr5PTJEnF6qjMZiqqEDvCCM" ||
    priceId === "price_1NrcfhJEnF6qjMZiefJh6cra" ||
    priceId === "price_1NrcgjJEnF6qjMZiaLXJ0wuf" ||
    priceId === "price_1NrchkJEnF6qjMZi8UVeSqNY" ||
    priceId === "price_1NrcihJEnF6qjMZibyfQKCST" ||
    priceId === "price_1NrcjpJEnF6qjMZiTlAJ2R1W"
  ) {
    payCardStateInit = "Yearly";
  }
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteAccountStep, setdeleteAccountStep] = useState(0);
  const [billingEmailState, setbillingEmailState] = useState(
    billingEmail || ""
  );
  const emailpreCheck = billingEmail === userEmail ? true : false;
  const [hasbillingEmail, sethasbillingEmail] = useState(emailpreCheck);

  const [billingEmailStateINIT] = useState(billingEmail || "");
  const [userNameINITINIT] = useState(userNameINIT || "");
  const [deleteAccountValue, setdeleteAccountValue] = useState("");
  const [deleteAccountDisabled, setdeleteAccountDisabled] = useState("");
  const [changePasswordButtonText, setchangePasswordButtonText] = useState("");
  const [changePasswordButtonDisabled, setchangePasswordButtonDisabled] =
    useState(false);

  const [masterAutoImportCount, setmasterAutoImportCount] = useState(
    integrationMaxCount <= 5 ? integrationMaxCount : 0
  );
  const [ultimateAutoImportCount, setultimateAutoImportCount] = useState(
    integrationMaxCount > 5 ? integrationMaxCount : 0
  );

  const [isDisabled, setisDisabled] = useState(true);
  const [billingState, setbillingState] = useState(prePriceID);
  const [userName, setuserName] = useState(userNameINIT);
  const [paycardState, setpaycardState] = useState(payCardStateInit);
  const [settingShowState, setsettingShowState] = useState(
    Number(utmpageIndex) === 2
      ? {
          account: "none",
          subscription: "block",
          actions: "none",
        }
      : initState
      ? initState
      : {
          account: "block",
          subscription: "none",
          actions: "none",
        }
  );
  const prevBillingEmail = usePrevious(billingEmailState);

  const firstUpdate = useRef(true);

  // For the confirmed action button
  useEffect(() => {
    if (firstUpdate.current) {
      var addedType = localStorage.getItem("savedprofile");
      if (addedType === "Saved") {
        showConfirmedActionButton("Profile Saved!");
        localStorage.removeItem("savedprofile");
      }
      var addedType2 = localStorage.getItem("loadDemoDataAction");
      if (addedType2 === "yes") {
        showConfirmedActionButton("Demo Data Added!");
        localStorage.removeItem("loadDemoDataAction");
      } else if (addedType2 === "no") {
        showConfirmedActionButton("Demo Data Deleted!");
        localStorage.removeItem("loadDemoDataAction");
      }
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [allData, props.pageName]);

  // Validation
  const profileValidation = useCallback(() => {
    if (
      userNameINITINIT === userName &&
      billingEmailState === billingEmailStateINIT
    ) {
      setisDisabled(true);
    } else {
      setisDisabled(false);
    }
  }, [userNameINITINIT, billingEmailState, userName, billingEmailStateINIT]);

  // Validation
  const deleteAccountValidation = useCallback(() => {
    if (deleteAccountValue === "permanently delete") {
      setdeleteAccountDisabled(false);
    } else {
      setdeleteAccountDisabled(true);
    }
  }, [deleteAccountValue]);

  useEffect(() => {
    deleteAccountValidation();
  }, [deleteAccountValidation, deleteAccountValue]);

  useEffect(() => {
    profileValidation();
  }, [profileValidation]);
  const handlebillingEmailCheck = (e) => {
    sethasbillingEmail(e.target.checked);
    if (!e.target.checked) {
      setbillingEmailState(prevBillingEmail);
    } else {
      setbillingEmailState(userEmail);
    }
  };

  const changePassword = () => {
    setchangePasswordButtonText(
      "We've just sent you an email to reset your password."
    );
    setTimeout(() => {
      setchangePasswordButtonText("");
    }, 8000);
    setchangePasswordButtonDisabled(true);
    if (signInType === "Auth0") {
      axios
        .request(auth0options)
        .then(function (response) {})
        .catch(function (error) {
          console.error(error);
        });
    } else {
    }
  };

  const closeDeleteAccountModal = () => {
    setIsOpen(false);
    setdeleteAccountDisabled(true);
    setdeleteAccountStep(0);
    setdeleteAccountValue("");
  };
  const openDeleteAccountModal = () => {
    setIsOpen(true);
  };
  // Define pricing tiers
  const pricingTiers = [
    /*     {
      currentPlanId: priceId,
      tier: "Free",
      monthlystripeid: "",
      annualstripeid: "",
      quarterlystripeid: "",
      headerid: "pricing-tier-header-free",
      subheaderid: "pricing-tier-subheader-free",
      featureItemId: "pricing-tier-feature-item-free",
      featureWrapper: "pricing-tier-features-wrapper-free",
      buttonid: "pricing-tier-button-free",
      priceClass: "pricing-tier-free-price",
      header: "Free Plan",
      subheader: "For testing use",
      monthlyprice: "Free",
      annualprice: "Free",
      quarterlyprice: "Free",
      highlighted: false,
      hightlightedText: "",
      features: [
        "1 portfolio",
        "1 file import per month",
        "30 entry maximum",
        "Limited metrics",
        "1 picture per trade",
      ],
    }, */
    /*     {
      currentPlanId: priceId,
      tier: "Starter",
      free: stripePriceIds.admin,
      monthlystripeid: stripePriceIds.monthlyultimate,
      annualstripeid: stripePriceIds.annualultimate,
      quarterlystripeid: stripePriceIds.quarterlyultimate,
      headerid: "pricing-tier-header-master",
      subheaderid: "pricing-tier-subheader-master",
      featureItemId: "pricing-tier-feature-item-pro",
      featureWrapper: "pricing-tier-features-wrapper-master",
      buttonid: "pricing-tier-button-master",
      priceClass: "pricing-tier-master-price",
      header: "Starter Plan",
      subheader: "Kickstart your trading journey",
      quarterlyprice: "$6.79/month",
      monthlyprice: "$7.99/month",
      annualprice: "$5.19/month",
      highlighted: false,
      hightlightedText: "Featured",
      features: [
        "2 portfolios",
        "500 entry maximum",
        "3 pictures per trade",
        "24/7 priority support",
      ],
    }, */
    /*     {
      currentPlanId: priceId,
      tier: "Starter",
      free: stripePriceIds.freepro,
      monthlystripeid: stripePriceIds.monthly,
      annualstripeid: stripePriceIds.annual,
      quarterlystripeid: stripePriceIds.quarterly,
      headerid: "pricing-tier-header-starter",
      subheaderid: "pricing-tier-subheader-starter",
      featureItemId: "pricing-tier-feature-item-starter",
      featureWrapper: "pricing-tier-features-wrapper",
      buttonid: "pricing-tier-button-starter",
      priceClass: "pricing-tier-monthly-price",
      header: "Starter Plan",
      subheader: "For beginner traders",
      quarterlyprice: "$7/month",
      monthlyprice: "$8/month",
      annualprice: "$6/month",
      highlighted: false,
      hightlightedText: "Featured",
      features: [
        "2 portfolios",
        "25 file imports per month",
        "500 entry maximum",
        "Comprehensive report",
        "3 pictures per trade",
      ],
    }, */
    {
      currentPlanId: priceId,
      tier: "Pro",
      free: stripePriceIds.freepro,
      monthlystripeid: stripePriceIds.monthly,
      annualstripeid: stripePriceIds.annual,
      quarterlystripeid: stripePriceIds.quarterly,
      headerid: "pricing-tier-header-pro",
      subheaderid: "pricing-tier-subheader-pro",
      featureItemId: "pricing-tier-feature-item-pro",
      featureWrapper: "pricing-tier-features-wrapper",
      buttonid: "pricing-tier-button-pro",
      priceClass: "pricing-tier-monthly-price",
      header: "Pro Plan",
      subheader: "Essential tools for traders",
      quarterlyprice: "$18/month",
      monthlyprice: "$20/month",
      annualprice: "$15/month",
      highlighted: false,
      hightlightedText: "Featured",
      features: [
        "5 portfolios",
        "Unlimited file imports",
        "Unlimited entries",
        "Advanced reports",
        "10 pictures per trade",
        "Calendar",
        "Customizable dashboard",
        "Track open positions",
        "Save global filters",
        "Commissions/Fees",
      ],
    },
    {
      currentPlanId: priceId,
      tier: "Master",
      free: stripePriceIds.freemaster,
      monthlystripeid: stripePriceIds.monthlymaster,
      annualstripeid: stripePriceIds.annualmaster,
      quarterlystripeid: stripePriceIds.quarterlymaster,
      headerid: "pricing-tier-header-master",
      subheaderid: "pricing-tier-subheader-master",
      featureItemId: "pricing-tier-feature-item-pro",
      featureWrapper: "pricing-tier-features-wrapper-master",
      buttonid: "pricing-tier-button-master",
      priceClass: "pricing-tier-master-price",
      header: "Master Plan",
      subheader: "Unlock your full trading potential",
      quarterlyprice: "$43/month",
      monthlyprice: "$48/month",
      annualprice: "$36/month",
      featuredIconShow: "annual",
      highlighted: false,
      hightlightedText: "Featured",
      features: [
        "10 portfolios",
        "Automated importing for up to 5 portfolios",
        "Advanced Tools",
        "AI-powered insights",
        "25 pictures per trade",
        "Portfolio & Trade verification",
        "Colored portfolios",
        "Slippage",
        "Enhanced support",
      ],
    },
    {
      currentPlanId: priceId,
      tier: "Ultimate",
      free: stripePriceIds.freeultimate,
      monthlystripeid: stripePriceIds.monthlyultimate,
      annualstripeid: stripePriceIds.annualultimate,
      quarterlystripeid: stripePriceIds.quarterlyultimate,
      headerid: "pricing-tier-header-ultimate",
      subheaderid: "pricing-tier-subheader-master",
      featureItemId: "pricing-tier-feature-item-pro",
      featureWrapper: "pricing-tier-features-wrapper-master",
      buttonid: "pricing-tier-button-ultimate",
      priceClass: "pricing-tier-ultimate-price",
      header: "Ultimate Plan",
      subheader: "Achieve trading supremacy",
      quarterlyprice: "$151/month",
      monthlyprice: "$168/month",
      annualprice: "$126/month",
      buttontextwrapper: "ultimatebuttonwrapper",
      highlighted: false,
      hightlightedText: "Featured",
      features: [
        "50 portfolios",
        "Automated importing for up to 20 portfolios",
        "100 pictures per trade",
        "Integration request priority",
        "24/7 priority support",
      ],
    },
  ];
  const undoChanges = useCallback(() => {
    setuserName(userNameINITINIT);
    setbillingEmailState(billingEmailStateINIT);
  }, [userNameINITINIT, billingEmailStateINIT]);

  // Delete Account Function
  const deleteAccount = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    closeDeleteAccountModal();
    const S3Data = {
      userId: user.sub,
    };
    await cancelSubscriptions(userSubscriptions, history);
    await deleteUserData(S3Data, history);
    localStorage.clear();
    auth0Instance.logout({
      returnTo: window.location.origin,
    });
  };

  // Submit to S3 function
  const handleSelectSubmit = async () => {
    const globalpropsToChange = {
      username: userName,
    };
    const propsToChange = {};

    const finalPush = createDatafromSettingsProps(
      allData.allData.chosenPortfolios,
      allData.allData.createdPortfolios,
      propsToChange,
      globalpropsToChange,
      allData.portfolio.settings,
      allData.allData.globalSettings,
      allData.allData.version
    );
    const S3Data = {
      data: finalPush,
      userId: user.sub,
    };
    allGlobalData.updateAllData(finalPush);
    const updateObj = {
      ...customer,
      email: billingEmailState,
    };
    allGlobalData.setCustomer(updateObj);
    await putUserData(S3Data, history);
    localStorage.setItem("savedprofile", "Saved");

    const runUpdateStripeCustomer = async () => {
      const dataIn = {
        data: {
          customerId: stripeId,
          email: billingEmailState,
        },
      };
      const response = await fetch(proxyURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Host: proxyURL,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(dataIn),
      })
        .then((res) => {})
        .catch((err) => {
          throw err;
        });
      return response?.json(); // parses JSON response into native JavaScript objects
    };

    stripeId !== "" && (await runUpdateStripeCustomer());
    history.push("/BlankPage");
    history.goBack();
  };
  const innerHeader = "Manage Account";
  const pricingTiersComponent = pricingTiers.map((tier) => (
    <PricingTier
      stripeId={stripeId}
      tierComponent={tier}
      billingState={billingState}
      key={uuidv4()}
    />
  ));
  const handleDeleteAccountClick = () => {
    openDeleteAccountModal();
  };

  const mastercountdisplay =
    paycardState === "Monthly"
      ? "$39"
      : paycardState === "3-Month"
      ? "$35"
      : paycardState === "Yearly"
      ? "$29"
      : "$0";

  const proPriceId =
    paycardState === "Monthly"
      ? "price_1NrZ9gJEnF6qjMZiGZBT2yOK"
      : paycardState === "3-Month"
      ? "price_1NrZ9XJEnF6qjMZilJdClA9a"
      : "price_1NrZ9HJEnF6qjMZicUdwt4Az";

  const masterPriceId =
    paycardState === "Monthly"
      ? "price_1NrZ3SJEnF6qjMZifdKYlDFo"
      : paycardState === "3-Month"
      ? "price_1NrZ3BJEnF6qjMZiC0mbaEzN"
      : paycardState === "Yearly"
      ? "price_1NrZ2cJEnF6qjMZiREJtN0bg"
      : "";

  const ultimatePriceId =
    paycardState === "Monthly"
      ? "price_1NrcgQJEnF6qjMZiyuU53gwV"
      : paycardState === "3-Month"
      ? "price_1NrcgBJEnF6qjMZiHHyqL7zj"
      : paycardState === "Yearly"
      ? "price_1NrcfhJEnF6qjMZiefJh6cra"
      : "";

  const masterisHighlighted =
    paycardState === "Monthly"
      ? priceId === "price_1NrZ3SJEnF6qjMZifdKYlDFo" ||
        priceId === "price_1Mla3qJEnF6qjMZijRgbEgN3"
        ? true
        : false
      : paycardState === "3-Month"
      ? priceId === "price_1NrZ3BJEnF6qjMZiC0mbaEzN" ||
        priceId === "price_1Mla3qJEnF6qjMZijRgbEgN3"
        ? true
        : false
      : paycardState === "Yearly"
      ? priceId === "price_1NrZ2cJEnF6qjMZiREJtN0bg" ||
        priceId === "price_1Mla3qJEnF6qjMZijRgbEgN3"
        ? true
        : false
      : false;

  const ultimatepricedisplay =
    paycardState === "Monthly"
      ? "$69"
      : paycardState === "3-Month"
      ? "$62"
      : paycardState === "Yearly"
      ? "$51"
      : "$0";

  const proisHighlighted =
    paycardState === "Monthly"
      ? priceId === "price_1NrZ9gJEnF6qjMZiGZBT2yOK" ||
        priceId === "price_1NQzmvJEnF6qjMZiHK5k8GX6"
        ? true
        : false
      : paycardState === "3-Month"
      ? priceId === "price_1NrZ9XJEnF6qjMZilJdClA9a" ||
        priceId === "price_1NQzmYJEnF6qjMZiqykNch1F"
        ? true
        : false
      : priceId === "price_1NrZ9HJEnF6qjMZicUdwt4Az"
      ? true
      : false;

  const ultimateisHighlighted =
    paycardState === "Monthly"
      ? priceId === "price_1NrcgQJEnF6qjMZiyuU53gwV" ||
        priceId === "price_1NNs2kJEnF6qjMZiRWeutOMh"
        ? true
        : false
      : paycardState === "3-Month"
      ? priceId === "price_1NrcgBJEnF6qjMZiHHyqL7zj" ||
        priceId === "price_1NNs2kJEnF6qjMZiRWeutOMh"
        ? true
        : false
      : paycardState === "Yearly"
      ? priceId === "price_1NrcfhJEnF6qjMZiefJh6cra" ||
        priceId === "price_1NNs2kJEnF6qjMZiRWeutOMh"
        ? true
        : false
      : false;
  const movecursor = {
    paddingLeft: "10px",
    fontSize: "1.1em",
  };
  return (
    <div id="manage-acc-comp" className="noselect">
      {<span className="currencysymbolsheading">{"Manage Account"}</span>}
      <Modal
        ariaHideApp={false}
        id={"deleteAccountModal"}
        className={"deleteAccountModal"}
        isOpen={modalIsOpen}
        closeTimeoutMS={200}
        contentLabel="Trade"
        overlayClassName="OverlayModal"
        onRequestClose={closeDeleteAccountModal}
      >
        <div>
          <h3 className="modalSymbolHeader8">
            {deleteAccountStep === 0 ? "CAUTION" : "DELETE ACCOUNT"}
          </h3>
          {deleteAccountStep === 0 ? (
            <div>
              {" "}
              <div className="delacountinnermessagewrapper">
                Caution! You are about to delete your TradeFuse account. All of
                your trades and portfolios will be <b>permanently deleted</b>{" "}
                and your current subscription will be <b>canceled</b>. After
                deletion, you will be logged out and taken to the sign in
                screen.
              </div>
              <div className="delacountinnermessagewrapper">
                <b>This action CANNOT be undone.</b>
              </div>
              <button
                className="deleteaccountcancelbutton"
                onClick={() => {
                  closeDeleteAccountModal();
                }}
              >
                Cancel
              </button>
              <button
                className="deleteaccountproceedbutton"
                onClick={() => {
                  setdeleteAccountStep(1);
                }}
              >
                Proceed
              </button>
            </div>
          ) : (
            <div>
              {" "}
              <div className="delacountinnermessagewrapper">
                To confirm your TradeFuse account deletion, type{" "}
                <b>
                  <i>permanently delete</i>
                </b>{" "}
                in the box below
              </div>
              <input
                name="exitPrice"
                style={movecursor}
                value={deleteAccountValue}
                onChange={(e) => {
                  setdeleteAccountValue(e.target.value);
                }}
                className="text-input-boxes-accdel"
                autoComplete="off"
              />
              <button
                className="deleteaccountcancelbutton delacccancelright"
                onClick={() => {
                  closeDeleteAccountModal();
                }}
              >
                Cancel
              </button>
              <button
                disabled={deleteAccountDisabled}
                className="deleteaccountproceedbutton delaccwidth"
                onClick={() => {
                  deleteAccount();
                }}
              >
                Delete Account
              </button>
            </div>
          )}
        </div>
      </Modal>
      {/*       <span className="currencysymbolsheading">{innerHeader}</span>
       */}{" "}
      <div id="settings-navigationbuttons-wrapper">
        <button
          onClick={() => {
            setsettingShowState({
              ...settingShowState,
              account: "block",
              subscription: "none",
              actions: "none",
            });
          }}
          id="settings-navigationbuttons-button"
          className={
            settingShowState.account === "block"
              ? "settings-navigationbuttons-selected"
              : ""
          }
        >
          Account
        </button>
        <button
          onClick={() => {
            setsettingShowState({
              ...settingShowState,
              account: "none",
              subscription: "block",
              actions: "none",
            });
          }}
          id="settings-navigationbuttons-button"
          className={
            settingShowState.subscription === "block"
              ? "settings-navigationbuttons-selected"
              : ""
          }
        >
          Subscription
        </button>
        {/*         <button
          onClick={() => {
            setsettingShowState({
              ...settingShowState,
              account: "none",
              subscription: "none",
              actions: "block",
            });
          }}
          id="settings-navigationbuttons-button"
          className={
            settingShowState.actions === "block"
              ? "settings-navigationbuttons-selected"
              : ""
          }
        >
          Account Actions
        </button> */}
      </div>
      <div id="manage-navigationbuttons-line"></div>
      <div className="select-manage">
        {settingShowState.account === "block" && (
          <div className="subscriptionsettings">
            <span className="manageaccountinnerheader3">Profile</span>
            <div className="select-profile-type-inner">
              <div className="usernameelementwrapper">
                <Title>Username</Title>
                <label className="usernameelementinner">
                  <input
                    style={movecursor}
                    value={userName}
                    onChange={(e) => {
                      setuserName(e.target.value);
                    }}
                    className="text-input-boxes"
                    autoComplete="off"
                  />
                </label>
              </div>
            </div>
            <div className="select-profile2-type-inner">
              <div className="usernameelementwrapper">
                <Title>Billing Email</Title>
                <label className="usernameelementinner">
                  <input
                    style={movecursor}
                    value={billingEmailState}
                    onChange={(e) => {
                      setbillingEmailState(e.target.value);
                    }}
                    className="text-input-boxes"
                    autoComplete="off"
                  />
                  <label className="container checkssspv2">
                    <input
                      type="checkbox"
                      id="boxNoEntrySlip"
                      name="hasEntrySlippage"
                      checked={hasbillingEmail}
                      onChange={(e) => handlebillingEmailCheck(e)}
                    />
                    <span className="checkmark checkmark-col1-2"></span>
                    <span style={{ marginTop: "-24px", marginRight: 4 }}>
                      &emsp;&ensp;Same as TradeFuse account email
                    </span>
                  </label>
                </label>
              </div>
            </div>
            <div className="select-profile3-type-inner">
              <div className="usernameelementwrapper">
                <Title>Change Password</Title>
                {signInType === "Auth0" ? (
                  <button
                    disabled={changePasswordButtonDisabled}
                    className="changePasswordbutton"
                    onClick={() => changePassword()}
                  >
                    {"Change Password"}
                  </button>
                ) : (
                  <div className="changePasswordNoneTExt">
                    {`Your password is currently managed by ${signInType}.`}{" "}
                  </div>
                )}
                <div className="changepasswordconfirmtext">
                  {changePasswordButtonText}
                </div>
              </div>
            </div>
            <div className="dangerzonewrapper">
              <span className="criticalactionsheader">DANGER ZONE</span>
              <div className="select-critical-type-inner">
                <div className="usernameelementwrapper">
                  <RedTitle>Delete Your Account</RedTitle>
                  <button
                    onClick={() => {
                      handleDeleteAccountClick();
                    }}
                    className="deleteaccountbutton"
                  >
                    Delete Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {settingShowState.subscription === "block" && (
          <div
            className="billingsettings"
            style={{ display: settingShowState.subscription }}
          >
            {/*             <span className="currencysymbolsinnerheaderbilling">
              Subscription
            </span> */}
            {trialEnd && (
              <span id="trialendsnumber">
                <div
                  style={{
                    height: 0,
                    width: 0,
                    fontSize: "1em",
                    position: "relative",
                    top: 0,
                    margin: "0px 6px 0px -6px",
                  }}
                >
                  <FontAwesomeIcon icon={faClock} />
                </div>{" "}
                {<span>Trial ends </span>} {formatUnixTimestamp(trialEnd)}
              </span>
            )}
            {/*             <PromoCodeElement />
             */}{" "}
            {/*         <div className="stripetablewrapper">
          {
            <StripePricingTable
              pricingtableid="prctbl_1LwEESJEnF6qjMZirVRCsEVP"
              publishablekey="pk_live_C0S8x0s61Ul7GNxHVd1KIrET00vKEP9Ubt"
              clientreferenceid={stripeID}
            />
          }
        </div> */}
            {/* <div className="stripetablewrapper">{pricingTiersComponent}</div> */}
            <div className="newpricingtableToggleComponentMA">
              <div className="newpricingtableToggleinner">
                <div
                  className={
                    paycardState === "Monthly"
                      ? "newpricingtableToggleinnersubhighlighted"
                      : "newpricingtableToggleinnersub"
                  }
                  onClick={() => setpaycardState("Monthly")}
                >
                  Monthly
                </div>
                <div
                  className={
                    paycardState === "3-Month"
                      ? "newpricingtableToggleinnersubhighlighted"
                      : "newpricingtableToggleinnersub"
                  }
                  onClick={() => setpaycardState("3-Month")}
                >
                  3-Month
                  <div className="newpricingtableToggleinnersub2">
                    <div className="newpricingtableToggleinnersubsparkle">
                      ✨
                    </div>
                    <div className="newpricingtableToggleinnersub22">
                      Save 10%
                    </div>
                  </div>
                </div>
                <div
                  className={
                    paycardState === "Yearly"
                      ? "newpricingtableToggleinnersubhighlighted"
                      : "newpricingtableToggleinnersub"
                  }
                  onClick={() => setpaycardState("Yearly")}
                >
                  Yearly
                  <div className="newpricingtableToggleinnersub2">
                    <div className="newpricingtableToggleinnersubsparkle">
                      ✨
                    </div>
                    <div className="newpricingtableToggleinnersub22">
                      Save 25%
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="newpricingtablecardWrapperMainMA">
              <div
                className={
                  proisHighlighted
                    ? "newpricingtablecardCard newpricingtablecardCardhighlighted"
                    : "newpricingtablecardCard"
                }
              >
                <div className="procardheader">Pro Plan</div>{" "}
                {proisHighlighted && (
                  <div className="mastercardheaderCurrent">Current Plan</div>
                )}
                <div>
                  <div className="newpricingPricetext">
                    {paycardState === "Monthly"
                      ? priceId === "price_1NQzmvJEnF6qjMZiHK5k8GX6"
                        ? "$20"
                        : "$19"
                      : paycardState === "3-Month"
                      ? priceId === "price_1NQzmYJEnF6qjMZiqykNch1F"
                        ? "$18"
                        : "$17"
                      : "$14"}
                  </div>
                  <div className="newpricingPricepermonthtext">/MONTH</div>
                </div>
                <div className="newpricingPricepermonthsubtext">
                  Essential tools for traders
                </div>
                <div className="newpricingPricecardfeaturepro">
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp;{" "}
                    {priceId === "price_1NQzmvJEnF6qjMZiHK5k8GX6" ||
                    priceId === "price_1NQzmYJEnF6qjMZiqykNch1F"
                      ? "5 portfolios"
                      : "2 portfolios"}
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Unlimited file imports
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Unlimited entries
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Advanced reports
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Calendar
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp;{" "}
                    {priceId === "price_1NQzmvJEnF6qjMZiHK5k8GX6" ||
                    priceId === "price_1NQzmYJEnF6qjMZiqykNch1F"
                      ? "10 pictures per trade"
                      : "5 pictures per trade"}
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Customizable dashboard
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Track open positions
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Save global filters
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Commissions/fees
                  </div>
                </div>
                <div
                  id="pricing-tier-button-pro2"
                  onClick={async () => {
                    const currentURL = window.location;
                    const success_url = currentURL.origin + "/Dashboard";
                    const cancel_url = currentURL.href;
                    const returnUrl = currentURL.href;
                    if (proisHighlighted) {
                      store.dispatch(setLoaderState({ loading: true }));
                      const session = await createStripePortalSession(
                        stripeId,
                        returnUrl
                      );
                      store.dispatch(setLoaderState({ loading: false }));
                      window.location.replace(session.url);
                    } else {
                      store.dispatch(setLoaderState({ loading: true }));
                      const session = await createStripeSession(
                        stripeId,
                        proPriceId,
                        success_url,
                        cancel_url,
                        history
                      );
                      store.dispatch(setLoaderState({ loading: false }));
                      window.location.replace(session.url);
                    }
                  }}
                  /*                   onClick={async () => {
                    payWallState === "expired"
                      ? runExpiredStripeFunction(proPriceId)
                      : runInitialStripeFunction(proPriceId);
                  }} */
                >
                  {proisHighlighted ? "Manage Plan" : "Choose Plan"}
                </div>
              </div>
              <div
                className={
                  masterisHighlighted
                    ? "newpricingtablecardCardM newpricingtablecardCardhighlighted"
                    : "newpricingtablecardCardM"
                }
              >
                <div className="mastercardheader">Master Plan</div>
                {masterisHighlighted && (
                  <div className="mastercardheaderCurrent">Current Plan</div>
                )}
                <div>
                  <div className="newpricingPricetext">
                    {mastercountdisplay}
                  </div>
                  <div className="newpricingPricepermonthtext">/MONTH</div>
                </div>
                <div className="newpricingPricepermonthsubtext">
                  Unlock your full trading potential
                </div>{" "}
                <div className="newpricingPricepermonthsubtext2">
                  Everything in Pro, plus:
                </div>
                <div className="newpricingPricecardfeaturemaster">
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; 5 portfolios
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span className="newpricingPricecardfeaturecool">
                      &nbsp;&nbsp; Automated importing{" "}
                    </span>
                    {/*                   <div className="newpricingPricecardfeature2">
                    {masterAutoImportCount} portfolios
                  </div> */}{" "}
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            width: 260,
                            textAlign: "left",
                          }}
                        >
                          Connect your broker accounts with just a few easy
                          steps. Past trades are imported for you once
                          connected, and new trades are imported in{" "}
                          <b>real-time</b>. No waiting. No manual sync.
                        </div>
                      }
                      placement="bottom"
                      onMouseEnter={() => {}}
                      delay={300}
                      setClass="accountNumberSuperWrapper"
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style80">&#x1D48A;</span>
                    </PopoverStickOnHover>
                  </div>{" "}
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span className="newpricingPricecardfeaturecool">
                      &nbsp;&nbsp; QuantumQuery
                    </span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            width: 220,
                            textAlign: "left",
                          }}
                        >
                          A comprehensive trading journal analysis AI designed
                          to answer any question related to your trading
                          activities.{" "}
                        </div>
                      }
                      placement="bottom"
                      onMouseEnter={() => {}}
                      delay={300}
                      setClass="accountNumberSuperWrapper"
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style80">&#x1D48A;</span>
                    </PopoverStickOnHover>
                  </div>
                  {/*                   <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span className="newpricingPricecardfeaturecool">
                      &nbsp;&nbsp; AI-powered reports{" "}
                    </span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            width: 260,
                            textAlign: "left",
                          }}
                        >
                          Fully customizable reports powered by AI. Select
                          metrics & categories to compare, then an AI will give
                          feedback and score your performance.
                        </div>
                      }
                      placement="bottom"
                      onMouseEnter={() => {}}
                      delay={300}
                      setClass="accountNumberSuperWrapper"
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style80">&#x1D48A;</span>
                    </PopoverStickOnHover>
                  </div> */}{" "}
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; AI-powered reports
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Advanced tools
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Portfolio & trade verification
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; 10 pictures per trade
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Colored portfolios
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Slippage metrics
                  </div>
                  <div className="newpricingPricecardfeature">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Enhanced customer support
                  </div>
                </div>
                <div
                  id="pricing-tier-button-master2"
                  onClick={async () => {
                    const currentURL = window.location;
                    const success_url = currentURL.origin + "/Dashboard";
                    const cancel_url = currentURL.href;
                    const returnUrl = currentURL.href;
                    if (masterisHighlighted) {
                      store.dispatch(setLoaderState({ loading: true }));
                      const session = await createStripePortalSession(
                        stripeId,
                        returnUrl
                      );
                      store.dispatch(setLoaderState({ loading: false }));
                      window.location.replace(session.url);
                    } else {
                      store.dispatch(setLoaderState({ loading: true }));
                      const session = await createStripeSession(
                        stripeId,
                        masterPriceId,
                        success_url,
                        cancel_url,
                        history
                      );
                      store.dispatch(setLoaderState({ loading: false }));
                      window.location.replace(session.url);
                    }
                  }}
                  /*                   onClick={async () => {
                    payWallState === "expired"
                      ? runExpiredStripeFunction(masterPriceId)
                      : runInitialStripeFunction(masterPriceId);
                  }} */
                >
                  {masterisHighlighted ? "Manage Plan" : "Choose Plan"}
                </div>
              </div>
              <div
                className={
                  ultimateisHighlighted
                    ? "newpricingtablecardCardE newpricingtablecardCardhighlighted"
                    : "newpricingtablecardCardE"
                }
              >
                <div className="ultimatecardheader">Ultimate Plan</div>{" "}
                {ultimateisHighlighted && (
                  <div className="mastercardheaderCurrent">Current Plan</div>
                )}
                <div>
                  <div className="newpricingPricetexte">
                    {ultimatepricedisplay}
                  </div>
                  <div className="newpricingPricepermonthtexte">/MONTH</div>
                </div>
                <div className="newpricingPricepermonthsubtexte">
                  Achieve trading supremacy
                </div>{" "}
                <div className="newpricingPricepermonthsubtext22">
                  Everything in Master, plus:
                </div>
                <div className="newpricingPricecardfeatureultimate">
                  <div className="newpricingPricecardfeaturee">
                    {" "}
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; 10 portfolios
                  </div>
                  <div className="newpricingPricecardfeaturee">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span className="newpricingPricecardfeaturecool">
                      &nbsp;&nbsp; QuantumQuery Advanced
                    </span>
                    <PopoverStickOnHover
                      component={
                        <div
                          style={{
                            color: "#fff7cb",
                            padding: "2px",
                            width: 214,
                            textAlign: "left",
                          }}
                        >
                          Our most advanced model for QuantumQuery. Results are
                          more consistent and intelligent.{" "}
                        </div>
                      }
                      placement="bottom"
                      onMouseEnter={() => {}}
                      delay={300}
                      setClass="accountNumberSuperWrapper"
                      xAdjust={27}
                      yAdjust={0}
                      keepOpen={false}
                    >
                      <span className="tooptip-i-style80">&#x1D48A;</span>
                    </PopoverStickOnHover>
                  </div>
                  <div className="newpricingPricecardfeaturee">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Additional portfolios with &nbsp;&nbsp;
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; automated importing
                    {/*                   <div className="newpricingPricecardfeature2">
                    {masterAutoImportCount} portfolios
                  </div> */}
                  </div>{" "}
                  <div className="newpricingPricecardfeaturee">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; 25 pictures per trade
                  </div>
                  <div className="newpricingPricecardfeaturee">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; Integration request priority
                  </div>
                  <div className="newpricingPricecardfeaturee">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;&nbsp; 24/7 priority customer support
                  </div>
                </div>
                <div
                  id="pricing-tier-button-master2"
                  onClick={async () => {
                    const currentURL = window.location;
                    const success_url = currentURL.origin + "/Dashboard";
                    const cancel_url = currentURL.href;
                    const returnUrl = currentURL.href;
                    if (ultimateisHighlighted) {
                      store.dispatch(setLoaderState({ loading: true }));
                      const session = await createStripePortalSession(
                        stripeId,
                        returnUrl
                      );
                      store.dispatch(setLoaderState({ loading: false }));
                      window.location.replace(session.url);
                    } else {
                      store.dispatch(setLoaderState({ loading: true }));
                      const session = await createStripeSession(
                        stripeId,
                        ultimatePriceId,
                        success_url,
                        cancel_url,
                        history
                      );
                      store.dispatch(setLoaderState({ loading: false }));
                      window.location.replace(session.url);
                    }
                  }}
                  /*                   onClick={async () => {
                    payWallState === "expired"
                      ? runExpiredStripeFunction(ultimatePriceId)
                      : runInitialStripeFunction(ultimatePriceId);
                  }} */
                >
                  {ultimateisHighlighted ? "Manage Plan" : "Choose Plan"}
                </div>
              </div>
            </div>{" "}
          </div>
        )}
      </div>
      {/*       <div
        className="subscriptionsettings"
        style={{ display: settingShowState.actions }}
      >
        <span className="manageaccountinnerheader3">Account Actions</span>
        <span className="criticalactionsheader">DANGER ZONE</span>
        <div className="select-critical-type-inner">
          <div className="usernameelementwrapper">
            <RedTitle>Delete Your Account</RedTitle>
            <button
              onClick={() => {
                handleDeleteAccountClick();
              }}
              className="deleteaccountbutton"
            >
              Delete Account
            </button>
          </div>
        </div>
      </div> */}
      {!isDisabled && (
        <button
          className="undoprofilechangesbutton"
          onClick={() => undoChanges()}
        >
          Undo Changes
        </button>
      )}
      {settingShowState.subscription === "none" && (
        <button
          disabled={isDisabled}
          className="applyaccountbutton"
          onClick={() => handleSelectSubmit()}
        >
          Save
        </button>
      )}
    </div>
  );
};
export default ManageAccountComponent;
