import { v4 as uuidv4 } from "uuid";
import profitLossCalculation from "./../../../functions/profitLossCalculation";
import getPointValue from "./../getFuturesPointValue";
import { montharr, weekday } from "./../../../arrays/weekandmonth";
import {
  getEndDateTime,
  getStartDateTime,
} from "./../../../functions/getStartorEndDateTime";
import { filterByID } from "./../../../functions/filterByID";
import { csvToArray, csvToArrayFromFills } from "./../csvToArray";
import * as XLSX from "xlsx";

function convertToISOString(dateStr) {
  // Regular expression to validate and capture date and time components
  const regex = /^(\d{2})\.(\d{2})\.(\d{4})\s+(\d{2}):(\d{2}):(\d{2})$/;
  const match = dateStr.match(regex);

  if (!match) {
    throw new Error('Invalid date format. Expected "DD.MM.YYYY HH:MM:SS".');
  }

  // Destructure the matched groups
  const [, day, month, year, hours, minutes, seconds] = match.map(Number);

  // Create a new Date object (Note: Months are zero-based in JavaScript)
  const date = new Date(year, month - 1, day, hours, minutes, seconds);

  // Check for invalid dates (e.g., 31.02.2024)
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date components.");
  }

  // Convert to ISO string
  return date.toISOString();
}

export const atas = (
  readerEvent,
  userId,
  portfolio,
  settrades,
  setinvalidFile
) => {
  const feesStructure = portfolio.settings.feesStructure;
  const arrayBuffer = readerEvent.target.result;
  if (!arrayBuffer) {
    setinvalidFile(true);
    return;
  }

  // Create a Uint8Array from the ArrayBuffer
  const data = new Uint8Array(arrayBuffer);

  // Parse the workbook with the 'array' type
  const workbook = XLSX.read(data, { type: "array" });

  // Check if there are at least 3 sheets
  if (workbook.SheetNames.length < 3) {
    setinvalidFile(true);
    return;
  }

  const thirdSheetName = workbook.SheetNames[2]; // Zero-based index
  const worksheet = workbook.Sheets[thirdSheetName];

  if (!worksheet) {
    setinvalidFile(true);
    return;
  }

  // Convert sheet to CSV
  const csv = XLSX.utils.sheet_to_csv(worksheet);

  if (!csv) {
    setinvalidFile(true);
    return;
  }

  // Parse CSV into array of rows
  let tradeArray = csvToArray(csv);

  if (!readerEvent) {
    setinvalidFile(true);
    return;
  }

  let trade = "";
  let trades = [];
  let fullTradearray = [];

  tradeArray.forEach((trade) => {
    fullTradearray.push(trade); // push Cash History
  });

  fullTradearray.sort(function (a, b) {
    const datestringa = convertToISOString(a["Time"]);
    const datestringb = convertToISOString(b["Time"]);

    var c = new Date(datestringa);
    var d = new Date(datestringb);
    return c - d;
  });

  const verified = 0; // could be 1 in the future

  for (let i = 0, j = fullTradearray.length; i < j; i++) {
    if (
      fullTradearray[i]["Contract"] === "Fund Transaction" ||
      fullTradearray[i]["Currency"] === "Fund Transaction"
    ) {
      /*       var startDateTime = new Date(fullTradearray[i]["Timestamp"]);
      let delta = fullTradearray[i]["Delta"];
      delta = delta.replace(/["]+/g, "");
      // if the contract is a fund transaction, we have to create the amount
      // from "Amount" and "Delta" props
      if (fullTradearray[i]["Contract"] === "Fund Transaction") {
        let amount = fullTradearray[i]["Amount"];
        amount = amount.replace(/["]+/g, "");
        let totalDelta = Number(delta + amount);
        delta = totalDelta;
      } else {
      }
      trade = {
        entryId: uuidv4(),
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: [],
            pointValue: 0,
          },
          strategy: "",
          selectedConfidence: "",
          selectedEmotion: "",
          selectedPhysical: "",
          selectedMarket: "",
          selectedTimeframe: "",
          selectedMistake: "",
          selectedPortfolio: portfolio.name,
          selectedPortfolioType: "",
          orderType: Number(delta) > 0 ? "Deposit" : "Withdrawal",
          orderNumber: fullTradearray[i]["Transaction ID"],
          dayOfWeek: weekday[startDateTime.getDay()],
          monthOfYear: montharr[startDateTime.getMonth()],
          stopLoss: "",
          takeProfit: "",
          profitLoss: Number(delta),
          commissions: 0,
          fees: 0,
          maxAdEx: "",
          maxFavEx: "",
          comments: "",
          multiExecution: [],
          exitExecution: [],
          startDateTime: startDateTime,
          endDateTime: startDateTime,
          tags: "",
          verifiedLevel: verified,
          idLinks: [],
        },
      };
      trades = trades.concat(trade); */
    } else {
      // look for open trades in "trades" (not the current iteration!)
      // that matches the symbol
      let openTradesPre = [...trades];
      let openTradesPre2 = [...trades];
      let openTradesMatch = openTradesPre.filter((trade) =>
        filterByID(trade, false, fullTradearray[i]["Instrument"])
      );
      let openTradesNOTMatch = openTradesPre2.filter((trade) =>
        filterByID(trade, true, fullTradearray[i]["Instrument"])
      );
      // --------------------------

      // if the order was canceled or rejected dont do anything
      // also skip "Trade Paired" Cash entries - not needed
      if (
        fullTradearray[i]["Volume"] === "0" ||
        fullTradearray[i]["Exchange ID"] === ""
      ) {
        // Don't do anything here
      } else {
        let startDateTime = new Date(
          convertToISOString(fullTradearray[i]["Time"])
        );
        //let timestamp = new Date(fullTradearray[i]["Timestamp"]);
        let orderType =
          fullTradearray[i]["Direction"] === "Buy" ? "Long" : "Short";
        //let delta = fullTradearray[i]["Delta"];

        let entryexecution = {
          id: uuidv4(),
          lotSize: Number(fullTradearray[i]["Volume"]),
          entryPrice: Number(fullTradearray[i]["Price"]),
          startDateTime: startDateTime,
          expectedEntry: "",
          strikePrice: "",
          expirationDate: "",
          legType: "",
        };
        const openTrade = openTradesMatch[0];
        const opentradeOrderType = openTrade?.entry.orderType;
        if (!openTradesMatch.length) {
          trade = {
            entryId: uuidv4(),
            entry: {
              pictures: ["N/A"],
              symbol: {
                symbols: [fullTradearray[i]["Instrument"]],
                pointValue: getPointValue(
                  "atas",
                  fullTradearray[i]["Instrument"]
                ),
              },
              strategy: "",
              selectedConfidence: "",
              selectedEmotion: "",
              selectedPhysical: "",
              selectedMarket: "",
              selectedTimeframe: "",
              selectedMistake: "",
              selectedPortfolio: portfolio.name,
              selectedPortfolioType: "Futures",
              orderType: orderType,
              orderNumber: fullTradearray[i]["Exchange ID"],
              dayOfWeek: weekday[startDateTime.getDay()],
              monthOfYear: montharr[startDateTime.getMonth()],
              stopLoss: "",
              takeProfit: "",
              profitLoss: 0,
              commissions: Number(fullTradearray[i]["Commission"]),
              fees: 0,
              maxAdEx: "",
              maxFavEx: "",
              comments: "",
              multiExecution: [entryexecution],
              exitExecution: [],
              startDateTime: startDateTime,
              endDateTime: "",
              tags: "",
              verifiedLevel: verified,
              idLinks: [],
            },
          };
          trades.push(trade);
          openTradesPre = trades;
          openTradesPre2 = trades;
        } else {
          if (orderType !== opentradeOrderType) {
            // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
            var endDateTime = new Date(convertToISOString(fullTradearray[i]["Time"]));
            let exitexecution = {
              id: uuidv4(),
              exitLotSize: Number(fullTradearray[i]["Volume"]),
              exitPrice: Number(fullTradearray[i]["Price"]),
              endDateTime: endDateTime,
              expectedExit: "",
              exitstrikePrice: "",
              exitexpirationDate: "",
              exitlegType: "",
              equityComponents: [],
              exercised: "",
            };
            const openTrade = openTradesMatch[0];
            const openTradeEntry = openTrade?.entry;

            // -------- Add the exit execution, and calculate profit/loss, fees, and start & end date time stuff -------
            const opentrademultiExecution = openTradeEntry.multiExecution;
            const opentradeOrderType = openTradeEntry.orderType;
            const opentradeSymbol = openTradeEntry.symbol;
            const opentradeFees = openTradeEntry.commissions;

            const opentradesType = openTradeEntry.selectedPortfolioType;
            const newExitExecution = [
              ...openTradeEntry.exitExecution,
              exitexecution,
            ];
            // calculate absolute start date time

            let startDateTimez = getStartDateTime(opentrademultiExecution);
            let endDateTimez = getEndDateTime(newExitExecution);
            const entry = Object.assign({}, openTradeEntry, {
              exitExecution: newExitExecution,
              profitLoss: Number(
                profitLossCalculation(
                  opentrademultiExecution,
                  newExitExecution,
                  opentradeOrderType,
                  opentradesType,
                  opentradeSymbol.pointValue,
                  false
                )
              ),
              endDateTime: endDateTimez,
              startDateTime: startDateTimez,
              commissions:
                opentradeFees +
                Number(fullTradearray[i]["Commission"]),
            });
            const closedTrade = Object.assign({}, openTrade, {
              entry: entry,
            });
            openTradesNOTMatch.push(closedTrade);
            trades = openTradesNOTMatch;
            openTradesPre = openTradesNOTMatch;
            openTradesPre2 = openTradesNOTMatch;
          } else {
            // Add new opening executions
            const openTrade = openTradesMatch[0];
            const openTradeEntry = openTrade?.entry;
            const opentradeFeesin = openTradesMatch[0].entry.commissions;
            const entry = Object.assign({}, openTradeEntry, {
              multiExecution: [
                ...openTradesMatch[0].entry.multiExecution,
                entryexecution,
              ],
              commissions:
                opentradeFeesin +
                Number(fullTradearray[i]["Commission"]),
            });
            const closedTrade = Object.assign({}, openTrade, {
              entry: entry,
            });
            openTradesNOTMatch.push(closedTrade);
            trades = openTradesNOTMatch;
            openTradesPre = openTradesNOTMatch;
            openTradesPre2 = openTradesNOTMatch;
          }
        }
      }
    }
  }
  settrades(trades);
};
export default atas;
