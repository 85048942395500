import React, {
  useMemo,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import dollarCostAverage from "../../../functions/dollarCostAvg";
import { returnNetPLDollar } from "../../../functions/profitLossCalculation";
import { numberWithCommas } from "../../Logbook/functions";
import getDateDifference from "../../../functions/getDateDifference";
import bookarrow from "../../../images/bookarrow.svg";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import supportedCryptos from "../../../arrays/isCurrencyCrypto";
import { toppinnedMetrics } from "./../arrays";
import getOptionsStrategy from "../../../functions/getOptionsStrategy";
import longShortDelineation from "../../../functions/longShortDelineation";
import ProTierComponent from "../../../components/tierComponents/pro";
import OpenPositionDonutGraph from "../DonutChart";
import PopoverStickOnHover from "../../../components/PopoverStickOnHover";
import EditTopPinnedMetrics from "./TopPinnedMetricsforEDIT";
import EditTickerTape from "./editTickerTape";
import $ from "jquery";
import SearchBar from "./searchBar"; // Adjust the path based on your file structure
import useForceUpdate from "../../../hooks/useForceUpdate";

let he = require("he");

//import { Bar } from "react-chartjs-2";
const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};
export const EditDashboardComponent = (props) => {
  const userData = props.userData;
  const tier = props.tier;
  const forceUpdate = useForceUpdate();

  const active = props.active;
  const portfolio = userData && userData.portfolio;
  const calculations = userData && userData.calculations;
  const entries = portfolio && portfolio.entries;
  let tierDiv = "";
  if (tier === "free") {
    tierDiv = (
      <div style={{ position: "absolute", top: 12, left: 200 }}>
        <ProTierComponent />
      </div>
    );
  } else if (tier === "pro" && active) {
  } else if (tier === "oldpro" && active) {
  } else if (tier === "master" && active) {
  } else if (tier === "ultimate" && active) {
  } else if (tier === "admin" && active) {
  } else {
    tierDiv = (
      <div style={{ position: "absolute", top: 12, left: 200 }}>
        <ProTierComponent />
      </div>
    );
  }
  let recentTrades = entries.slice(-15).reverse();

  const settings = portfolio && portfolio.settings;
  const thedefaultSymbol = settings.currencySymbol;
  const supportedCryptosCheck = supportedCryptos.includes(thedefaultSymbol);
  const dashboardSettings = settings?.dashboardElements;
  const topPinnedMetrics = dashboardSettings.toppinnedmetrics;
  const chosenPortType = portfolio.assetClasses;
  const chosenPortTypeLength = chosenPortType.length;
  const datenow = new Date();

  const [dateNow, setdateNow] = useState(datenow);
  const [recentTradesShow, setrecentTradesShow] = useState();
  const [editDashMode, seteditDashMode] = useState("Ticker Tape");

  const firstUpdate = useRef(true);
  const history = useHistory();
  const [searchResults, setSearchResults] = useState([]);
  let TOPgraphsMainINIT = [
    "pl24hr",
    "avgholdingtime",
    "avgPLperloss",
    "avgPLpertrade",
    "avgPLperwin",
    "avgpositionsize",
    "avgRRR",
    "cpcIndex",
    "expectation",
    "tiqgoldenratio",
    "kratio",
    "lrcorrelation",
    "lrstandarderror",
    "percentconfidence",
    "probabilityofrandomchance",
    "profitlossratio",
    "profitfactor",
    "recoveryfactor",
    "rMultipleRatio",
    "arithmeticSharpeRatio",
    "geometricSharpeRatio",
    "arithmeticSortinoRatio",
    "geometricSortinoRatio",
    "standarddeviation",
    "statisticalZScore",
    "tfindex",
    "ulcerindex",
    "volatility",
    "zScore",
  ];
  const [TOPgraphsMain, setTOPgraphsMain] = useState(TOPgraphsMainINIT);
  function searchByTitle(searchText) {
    const matchedProps = [];

    // Convert the search text to lower case for case-insensitive comparison
    const lowerCaseSearchText = searchText.toLowerCase();

    // Iterate over each object in the array
    toppinnedMetrics.forEach((obj) => {
      // Iterate over each property in the object
      for (const prop in obj) {
        // Check if the title matches the search text
        if (obj[prop].title.toLowerCase().includes(lowerCaseSearchText)) {
          // Add the top-level property name to the matchedProps array
          matchedProps.push(prop);
          break; // Stop checking other properties in the same object
        }
      }
    });

    return matchedProps;
  }
  const handleSearch = (searchTerm) => {
    const searchResult = searchByTitle(searchTerm);
    setTOPgraphsMain(searchResult);
    // For example, filter a list of items based on the searchTerm
  };
  useEffect(() => {
    // checks every 300ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    let interval = setInterval(async () => {
      const datenowinner = new Date();
      setdateNow(datenowinner);
    }, 1000);

    if (firstUpdate.current) {
      props.setOpenPositionsState();
    }
    return () => {
      firstUpdate.current = false;
      clearInterval(interval);
    };
  }, [firstUpdate, props]);
  const toComponentB = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row.original,
    });
  };
  const editElements = useCallback(() => {
    let mainelement;
    if (editDashMode === "Top Metrics") {
      mainelement = (
        <EditTopPinnedMetrics
          userCalculations={calculations}
          userData={entries}
          defaultSymbol={String(thedefaultSymbol)}
          userSettings={settings}
          TOPgraphsMain={TOPgraphsMain}
          allData={userData}
          forceUpdate={() => forceUpdate()}
        />
      );
    } else if (editDashMode === "Ticker Tape") {
      mainelement = (
        <EditTickerTape
          userCalculations={calculations}
          userData={entries}
          defaultSymbol={String(thedefaultSymbol)}
          userSettings={settings}
          allData={userData}
          forceUpdate={() => forceUpdate()}
        />
      );
    } else if (editDashMode === "Donut Charts") {
      mainelement = (
        <div
          style={{
            color: "#808080",
            fontStyle: "italic",
            width: "100%",
            textAlign: "center",
          }}
        >
          Coming Soon
        </div>
      );
    } else if (editDashMode === "Main Graphs") {
      mainelement = (
        <div
          style={{
            color: "#808080",
            fontStyle: "italic",
            width: "100%",
            textAlign: "center",
          }}
        >
          Coming Soon
        </div>
      );
    }
    return mainelement;
  }, [editDashMode, TOPgraphsMain]);

  return (
    <div className="noselect editdashboardcomponenntwrapper">
      <div className="noselect editdashboardcomponenntheader">
        Edit Dashboard
      </div>{" "}
      <div
        className="closeeditdashboardbutton"
        onClick={() => {
          //props.setEditState(false);
          props.setPsuedoEditState(false);
        }}
      ></div>
      <div className="selectEditdahsboardmodewrapper">
        <div
          className={
            editDashMode === "Ticker Tape"
              ? "selectEditdahsboardmodeselected"
              : "selectEditdahsboardmode"
          }
          onClick={() => seteditDashMode("Ticker Tape")}
        >
          Ticker Tape
        </div>
        <div
          className={
            editDashMode === "Top Metrics"
              ? "selectEditdahsboardmodeselected"
              : "selectEditdahsboardmode"
          }
          onClick={() => seteditDashMode("Top Metrics")}
        >
          Top Metrics
        </div>{" "}
        <div
          className={
            editDashMode === "Donut Charts"
              ? "selectEditdahsboardmodeselected"
              : "selectEditdahsboardmode"
          }
          onClick={() => seteditDashMode("Donut Charts")}
        >
          Donut Charts
        </div>{" "}
        <div
          className={
            editDashMode === "Main Graphs"
              ? "selectEditdahsboardmodeselected"
              : "selectEditdahsboardmode"
          }
          onClick={() => seteditDashMode("Main Graphs")}
        >
          Main Graphs
        </div>
      </div>
      {editDashMode !== "Ticker Tape" && (
        <SearchBar
          onSearch={handleSearch}
          placeHolder={`Search ${editDashMode}`}
        />
      )}
      {/*       <div className="noselect editdashboardcomponenntheader">
        Edit % Metrics
      </div>{" "}
      <div className="noselect editdashboardcomponenntheader">Edit Graphs</div> */}
      <div
        className={
          editDashMode !== "Ticker Tape"
            ? "editdashboardpanelscrollwrapper"
            : "editdashboardpanelscrollwrapper2"
        }
      >
        {editElements()}
      </div>
    </div>
  );
};

export default EditDashboardComponent;
