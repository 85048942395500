import React, { useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import isArray from "../../functions/arrayFunctions/isArray";
import { useHistory } from "react-router-dom";

export const PercentMetricGraph = (props) => {
  const { userData: entries, userCalculations: calculations } = props;
  const history = useHistory();
  const chartRef = useRef(null); // Reference to the chart instance
  const gradientRef = useRef(null); // Reference to store the gradient

  // Function to retrieve an entry by its index
  const getEntryByIndex = (i) => {
    return entries.filter((_, index) => index === i)[0];
  };

  // Function to navigate to Trade-Details page
  const navigateToTradeDetails = (row) => {
    // Remove any existing edit trade information
    localStorage.removeItem("editTrade");
    localStorage.removeItem("editTradeId");

    // Navigate to the Trade-Details page with the selected row's state
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };

  // Function to generate X-axis labels based on the data array
  const generateXAxisLabels = (arr) => {
    return arr.map((_, index) => index + 1);
  };

  // Chart data configuration
  const chartData = (canvas) => {
    const ctx = canvas.getContext("2d");

    // Create horizontal gradient only if it hasn't been created yet
    if (!gradientRef.current) {
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      gradient.addColorStop(0, "#fff7cb"); // Start color (left)
      gradient.addColorStop(1, "#fbc665"); // End color (right)
      gradientRef.current = gradient;
    }

    return {
      labels: generateXAxisLabels(
        isArray(calculations.goldenRatioArr) ? calculations.goldenRatioArr : []
      ),
      datasets: [
        {
          label: "Your Equity",
          fill: false, // No fill under the line
          lineTension: 0.4, // Smooth bezier curves
          backgroundColor: gradientRef.current, // Not visible since fill is false
          borderColor: gradientRef.current, // Apply gradient to the line
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "round",
          pointBorderColor: gradientRef.current, // Optional: Apply gradient to points
          pointBackgroundColor: gradientRef.current, // Optional
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: gradientRef.current, // Optional
          pointHoverBorderColor: gradientRef.current, // Optional
          pointHoverBorderWidth: 2,
          pointRadius: 3, // Visible points
          pointHitRadius: 10,
          data: calculations.goldenRatioArr,
        },
      ],
    };
  };

  // Chart options configuration
  const chartOptions = {
    tooltips: {
      mode: "index",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbc665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: true,
      callbacks: {
        title: () => "Golden Ratio:",
        label: (tooltipItem) => parseFloat(tooltipItem.yLabel.toFixed(3)),
      },
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
            callback: function (value) {
              return "";
            },
          },
          scaleLabel: {
            display: false,
            labelString: "Equity",
            fontColor: "#fbc665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
            callback: function (value) {
              return "";
            },
          },
          scaleLabel: {
            display: false,
            labelString: "Entry Number",
            fontColor: "#fbc665",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
    onHover: function (event, chartElement) {
      if (chartElement && chartElement.length) {
        event.target.style.cursor = "pointer";
      } else {
        event.target.style.cursor = "default";
      }
    },
    onClick: function (event, chartElement) {
      if (chartElement.length) {
        const index = chartElement[0]._index;
        const entry = getEntryByIndex(index);
        if (entry) navigateToTradeDetails(entry);
      }
    },
  };

  // Update gradient when the chart is first rendered or resized
  useEffect(() => {
    const chart = chartRef.current.chartInstance;
    if (chart) {
      const canvas = chart.canvas;
      const ctx = canvas.getContext("2d");
      // Recreate the gradient based on the new canvas width
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      gradient.addColorStop(0, "#c5b674"); // Start color (left)
      gradient.addColorStop(1, "#fbd665"); // End color (right)
      gradientRef.current = gradient;
      chart.data.datasets[0].borderColor = gradient;
      chart.data.datasets[0].pointBorderColor = gradient;
      chart.data.datasets[0].pointBackgroundColor = gradient;
      chart.data.datasets[0].pointHoverBackgroundColor = gradient;
      chart.data.datasets[0].pointHoverBorderColor = gradient;
      chart.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculations.goldenRatioArr]);

  return (
    <Line
      ref={chartRef}
      data={chartData}
      options={chartOptions}
      className="ovallreportgraphactual"
      // Removed onElementsClick as it's deprecated in favor of the options' onClick
    />
  );
};

export default PercentMetricGraph;
