import React, { useRef, useState } from "react";
import GaugeChart from "react-gauge-chart";
import useDimension from "./../../hooks/useDimension";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { handleNANGOOD } from "./../../functions/handleUndefined";
import {
  calculateSharpeRatios,
  calculateSortinoRatios,
} from "./../../functions/sharpeRatio";
//var he = require("he");
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import { Link } from "react-router-dom";
import useForceUpdate from "../../hooks/useForceUpdate";
import getEntryExitLots from "./../../functions/getEntryExitLots";
import RadarGraph from "./../Compare-&-Conquer/RadarGraph";
import { v4 as uuidv4 } from "uuid";

const PerformanceMeter = (props) => {
  const userData = props.userData;
  const riskFreeRate = props.riskFreeRate;
  const allData = props.allData;
  const createdPortfolios = allData?.allData.createdPortfolios;
  const chosenPortfolios = allData?.allData.chosenPortfolios;

  const divRef = useRef(null);

  const { height, width } = useDimension(divRef);

  const chart = document.getElementById("gauge-chart2");
  const [, setdoughHeight] = useState();
  const [timeframeToggle, settimeframeToggle] = useState("Last 7 Days");
  const [timeframeToggleedit, settimeframeToggleedit] = useState(false);
  const forceUpdate = useForceUpdate();
  const [selectedItemsMapped, setselectedItemsMapped] = useState(
    Object.fromEntries(chosenPortfolios.map((item) => [item, false]))
  );
  const portfolio = allData?.portfolio;
  const allEntries = createdPortfolios.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.entries);
  }, []);
  const portfolioColors = chosenPortfolios.map((portfolio) => {
    const matchingPort = createdPortfolios.find(
      (port) => port.name === portfolio
    );
    return matchingPort ? "#fff7cb" : null;
  });
  const ro = new ResizeObserver((entries) => {
    setdoughHeight(entries[0].contentRect.height);
  });
  // Only observe the second box
  chart && ro.observe(chart);

  const equityvaluefunction = () => {
    let numWins = 0;
    let numLosses = 0;
    let numTrades = 0;
    let totalProfits = 0;
    let fullBalanceArray = [];
    let totalLosses = 0;
    let TradersIQIndex = 0;
    let profitFactor = 0;
    let sharpeRatioArith = 0;
    let alpha = 0;
    let absoluteStartDate = "";
    let absoluteEndDate = "";
    for (var i = 0, j = userData.length; i < j; i++) {
      let correctedSUM = 0;
      let x100profitLoss = Number(props.userData[i].entry.profitLoss * 100);
      let x100commissions = Number(props.userData[i].entry.commissions * 100);
      let x100fees = Number(props.userData[i].entry.fees * 100);
      if (props.calculationType === "Gross") {
        correctedSUM = x100profitLoss / 100;
      } else if (props.calculationType === "Net") {
        correctedSUM = (x100profitLoss + x100commissions + x100fees) / 100;
      }
      const thisAccountBalance = Number(props.userData[i].balance);
      let endDateTimez = "";
      let startDateTimez = "";
      let multiExecution = props.userData[i].entry.multiExecution;
      let exitExecution = props.userData[i].entry.exitExecution;

      for (let im = 0, jm = multiExecution.length; im < jm; im++) {
        if (im === 0) {
          startDateTimez = multiExecution[im].startDateTime;
        } else {
          startDateTimez =
            multiExecution[im].startDateTime < startDateTimez
              ? multiExecution[im].startDateTime
              : startDateTimez;
        }
      }
      for (let ie = 0, je = exitExecution.length; ie < je; ie++) {
        if (ie === 0) {
          endDateTimez = exitExecution[ie].endDateTime;
        } else {
          endDateTimez =
            exitExecution[ie].endDateTime > endDateTimez
              ? exitExecution[ie].endDateTime
              : endDateTimez;
        }
      }
      absoluteStartDate = new Date("1/1/6000");
      if (startDateTimez < absoluteStartDate) {
        absoluteStartDate = startDateTimez;
      }
      absoluteEndDate = new Date("1/1/1971");
      if (endDateTimez > absoluteEndDate) {
        absoluteEndDate = endDateTimez;
      }
      const diffTime = Math.abs(
        new Date(absoluteEndDate) - new Date(absoluteStartDate)
      );

      //newaccountbal += correctedSUM;
      fullBalanceArray.push(thisAccountBalance);

      const entryExitValues = getEntryExitLots(multiExecution, exitExecution);

      const exitLots = entryExitValues.exitLots;
      if (
        props.userData[i].entry.orderType === "Deposit" ||
        props.userData[i].entry.orderType === "Withdrawal" ||
        props.userData[i].entry.orderType === "Funding Payment" ||
        props.userData[i].entry.orderType === "Commit" ||
        props.userData[i].entry.orderType === "Approval" ||
        props.userData[i].entry.orderType === "Wrap" ||
        props.userData[i].entry.orderType === "Self"
      ) {
        continue;
      } else if (exitLots === 0) {
        continue; // exclude trades with no exit executions
      } else {
        if (correctedSUM < 0) {
          totalLosses += correctedSUM;
          numLosses += 1;
        } else if (correctedSUM > 0) {
          totalProfits += correctedSUM;
          numWins += 1;
        }
        numTrades += 1;
      }
    }
    let averagePLPerWindollar = totalProfits / numWins;
    let averagePLPerLossdollar = totalLosses / numLosses;
    profitFactor = totalProfits / Math.abs(totalLosses);

    sharpeRatioArith = calculateSharpeRatios(
      fullBalanceArray,
      Number(riskFreeRate)
    ).arithmeticSharpeRatio;

    let arithmeticSortinoRatio = calculateSortinoRatios(
      fullBalanceArray,
      Number(riskFreeRate)
    ).arithmeticSortinoRatio;

    TradersIQIndex =
      (numWins / numTrades) *
      (1 + averagePLPerWindollar / Math.abs(averagePLPerLossdollar));
    return {
      tfIndex: handleNANGOOD(TradersIQIndex?.toFixed(3), "-"),
      profitFactor: handleNANGOOD(profitFactor?.toFixed(3), "-"),
      sharpeRatioArith: handleNANGOOD(sharpeRatioArith?.toFixed(3), "-"),
      sortinoRatioArith: handleNANGOOD(arithmeticSortinoRatio?.toFixed(3), "-"),
      alpha: handleNANGOOD(alpha?.toFixed(3), "-"),
    };
  };
  const TradersIQIndex = equityvaluefunction().tfIndex;
  const profitFactor = equityvaluefunction().profitFactor;
  const sharpeRatioArith = equityvaluefunction().sharpeRatioArith;
  const sortinoRatioArith = equityvaluefunction().sortinoRatioArith;

  const alpha = equityvaluefunction().alpha;
  const theWidth =
    width > 506 ? `calc(${width / 2}px + 6px)` : `calc(${width / 2}px - 20px)`;
  const chartStyle = {
    height: "auto",
    width: theWidth,
    maxHeight: 136,
    position: "relative",
    marginTop: width / 2 / 9,
  };
  //const firstchild = chart.children()[0];
  const lineColor = "#98b5eb"; //purple
  const baseColor = "#3f4b5e";
  const movecursor = {
    paddingLeft: "10px",
  };

  const metricsToCompare = [
    {
      value: "totalPl",
      label: "Total P/L",
    },
    {
      value: "avgHoldingTime",
      label: "Avg. Holding Time",
    },
    {
      value: "tfIndex",
      label: "TradeFuse Index",
    },
    {
      value: "sharpeRatio",
      label: "Sharpe Ratio",
    },
    {
      value: "maxDD",
      label: "Max. Drawdown",
    },
  ];
  const valuesArray = Object.entries(selectedItemsMapped).map(
    ([key, value]) => !value && { label: key, value: key }
  );
  return (
    <div className="perfmeterwrappermain" ref={divRef}>
      <div id="perfmeterwrapper">
        <div id="perfmetertext">{"Performance Radar"}</div>
        {/*         <div>
          {!timeframeToggleedit ? (
            <div
              className="flushtext2"
              onClick={() => {
                settimeframeToggleedit(true);
                forceUpdate();
              }}
            >
              {!timeframeToggle ? (
                <span>
                  <span className="edittradepencil">{"✎ "}</span>-
                </span>
              ) : (
                <span>
                  <span className="edittradepencil">{"✎ "}</span>
                  {timeframeToggle}
                </span>
              )}
            </div>
          ) : (
            <div className="logbook-text-input-boxes-wrapper2">
              <input
                id="logbook-text-input-boxes-stopLoss2"
                name="stopLoss"
                style={movecursor}
                value={timeframeToggle}
                onChange={(e) => {
                  settimeframeToggle(e.target.value);
                }}
                onBlur={() => {
                  settimeframeToggleedit(false);

                  document
                    .getElementById("logbook-text-input-boxes-stopLoss2")
                    .removeEventListener("keyup", function (event) {
                      // Number 13 is the "Enter" key on the keyboard
                      if (event.keyCode === 13) {
                        // Cancel the default action, if needed
                        event.preventDefault();
                        // Trigger the button element with a click
                        settimeframeToggleedit(false);
                      } else if (event.keyCode === 9) {
                        // Cancel the default action, if needed
                        event.preventDefault();
                        // Trigger the button element with a click
                        settimeframeToggleedit(false);
                      }
                    });
                  document
                    .getElementById("logbook-text-input-boxes-stopLoss2")
                    .removeEventListener("keydown", function (event) {
                      // Number 9 is the "Enter" key on the keyboard
                      if (event.keyCode === 9) {
                        // Cancel the default action, if needed
                        event.preventDefault();
                        // Trigger the button element with a click
                      }
                    });
                }}
                onFocus={() => {
                  document
                    .getElementById("logbook-text-input-boxes-stopLoss2")
                    .addEventListener("keyup", function (event) {
                      // Number 13 is the "Enter" key on the keyboard
                      if (event.keyCode === 13) {
                        // Cancel the default action, if needed
                        event.preventDefault();
                        // Trigger the button element with a click
                        settimeframeToggleedit(false);
                      } else if (event.keyCode === 9) {
                        // Cancel the default action, if needed
                        event.preventDefault();
                        // Trigger the button element with a click
                        settimeframeToggleedit(false);
                      }
                    });
                  document
                    .getElementById("logbook-text-input-boxes-stopLoss2")
                    .addEventListener("keydown", function (event) {
                      // Number 9 is the "Enter" key on the keyboard
                      if (event.keyCode === 9) {
                        // Cancel the default action, if needed
                        event.preventDefault();
                        // Trigger the button element with a click
                      }
                    });
                }}
                autoFocus
                className="logbook-text-input-boxes2"
                autoComplete="off"
                pattern="[+-]?([0-9]*[.])?[0-9]+"
              />
              <div></div>
            </div>
          )}
        </div> */}
        {/*         <span id="perfmeterwrappertooltip">
          <div id="perfmetertext">{"Performance Meter"}</div>

          <OverlayTrigger
            placement="top"
            delay={{ show: 400, hide: 250 }}
            overlay={
              <Tooltip>
                <div id="perfmetertexttooltipinner">{`The Performance Meter is based on your TradeFuse Index`}</div>
              </Tooltip>
            }
          >
            <div id="perfmeterwrappertooltipquestionmark">
              <div id="perfmeterwrappertooltipquestionmarkinner">?</div>
            </div>
          </OverlayTrigger>
        </span> */}
        {/*         <div id="perfmeterlabelBE">Break-Even</div>{" "}
        <div
          style={{ top: `calc(3.1415926535*${Math.pow(width, 0.636)}px)` }}
          id="perfmeterlabelPoor"
        >
          Poor
        </div>
        <div
          style={{ top: `calc(3.1415926535*${Math.pow(width, 0.636)}px)` }}
          id="perfmeterlabelGood"
        >
          Good
        </div> */}
        {
          <div className="gaugechartwrapper">
            <div className="gaugechartwrapperinner">
              <div className="gaugechartinner">
                <div className="gaugechartinnerheaderwrapper">
                  <div className="gaugechartinnerheader">TF Index</div>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          textAlign: "left",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        The TradeFuse index is one of the single best measures
                        of the profitability of your trading account. It
                        combines two of the most important metrics in trading:
                        win rate and profit/loss ratio. <br></br>
                        <Link to={"/KPIs"} className="linktooltiphover">
                          View KPIs &#8594;
                        </Link>
                      </div>
                    }
                    placement="bottom"
                    onMouseEnter={() => {}}
                    delay={300}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={true}
                  >
                    <div className="tooptip-i-style2">&#x1D48A;</div>
                  </PopoverStickOnHover>
                </div>
                <GaugeChart
                  id="gauge-chart2"
                  nrOfLevels={50}
                  percent={TradersIQIndex / 2 > 1 ? 1.01 : TradersIQIndex / 2}
                  arcPadding={0.01}
                  formatTextValue={(value) => {
                    return TradersIQIndex;
                  }}
                  colors={["#fa798b", "#55f8a6"]}
                  style={chartStyle}
                  textColor={"none"}
                  needleColor={lineColor}
                  needleBaseColor={baseColor}
                ></GaugeChart>
                <div id="perfmeterlabelIndex">{TradersIQIndex}</div>
              </div>
              <div className="gaugechartinner">
                <div className="gaugechartinnerheaderwrapper">
                  <div className="gaugechartinnerheader">Profit Factor</div>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          textAlign: "left",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        The profit factor is used to determine the profitability
                        of your trading account. <br></br>
                      </div>
                    }
                    placement="bottom"
                    onMouseEnter={() => {}}
                    delay={300}
                    xAdjust={42}
                    yAdjust={0}
                    keepOpen={true}
                  >
                    <div className="tooptip-i-style2">&#x1D48A;</div>
                  </PopoverStickOnHover>
                </div>{" "}
                <GaugeChart
                  id="gauge-chart2"
                  nrOfLevels={50}
                  percent={profitFactor / 2 > 1 ? 1.01 : profitFactor / 2}
                  arcPadding={0.01}
                  formatTextValue={(value) => {
                    return profitFactor;
                  }}
                  colors={["#fa798b", "#55f8a6"]}
                  style={chartStyle}
                  textColor={"none"}
                  needleColor={lineColor}
                  needleBaseColor={baseColor}
                ></GaugeChart>{" "}
                <div id="perfmeterlabelIndex">{profitFactor}</div>
              </div>
              <div className="gaugechartinner">
                <div className="gaugechartinnerheaderwrapper2">
                  <div className="gaugechartinnerheader">Sharpe Ratio</div>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          textAlign: "left",
                          width: 200,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        The Sharpe Ratio measures your strategy’s returns
                        relative to the amount of risk taken. It’s calculated by
                        comparing your excess returns (above the risk-free rate
                        of {handleNANGOOD(riskFreeRate?.toFixed(3), "-")}%) to
                        the volatility of those returns. A higher Sharpe Ratio
                        indicates that you’re earning more reward per unit of
                        risk. <br></br>
                        <Link to={"/KPIs"} className="linktooltiphover">
                          View KPIs &#8594;
                        </Link>
                      </div>
                    }
                    setClass="accountNumberSuperWrapper"
                    placement="bottom"
                    onMouseEnter={() => {}}
                    delay={300}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={true}
                  >
                    <div className="tooptip-i-style2">&#x1D48A;</div>
                  </PopoverStickOnHover>
                </div>
                <GaugeChart
                  id="gauge-chart2"
                  nrOfLevels={50}
                  percent={
                    sharpeRatioArith / 2 > 1 ? 1.01 : sharpeRatioArith / 2
                  }
                  arcPadding={0.01}
                  formatTextValue={(value) => {
                    return sharpeRatioArith;
                  }}
                  colors={["#fa798b", "#55f8a6"]}
                  style={chartStyle}
                  textColor={"none"}
                  needleColor={lineColor}
                  needleBaseColor={baseColor}
                ></GaugeChart>
                <div id="perfmeterlabelIndex2">{sharpeRatioArith}</div>
              </div>
              <div className="gaugechartinner">
                <div className="gaugechartinnerheaderwrapper2">
                  <div className="gaugechartinnerheader">Sortino Ratio</div>
                  <PopoverStickOnHover
                    component={
                      <div
                        style={{
                          color: "#fff7cb",
                          padding: "2px",
                          textAlign: "left",
                          width: 200,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        The Sortino Ratio refines the Sharpe Ratio by focusing
                        only on “bad” volatility—downside risk. It measures how
                        effectively your strategy generates returns while
                        minimizing losses. A higher Sortino Ratio suggests more
                        consistent returns with fewer harmful drawdowns.{" "}
                        <br></br>
                        <Link to={"/KPIs"} className="linktooltiphover">
                          View KPIs &#8594;
                        </Link>
                      </div>
                    }
                    setClass="accountNumberSuperWrapper"
                    placement="bottom"
                    onMouseEnter={() => {}}
                    delay={300}
                    xAdjust={27}
                    yAdjust={0}
                    keepOpen={true}
                  >
                    <div className="tooptip-i-style2">&#x1D48A;</div>
                  </PopoverStickOnHover>
                </div>
                <GaugeChart
                  id="gauge-chart2"
                  nrOfLevels={50}
                  percent={
                    sortinoRatioArith / 2 > 1 ? 1.01 : sortinoRatioArith / 2
                  }
                  arcPadding={0.01}
                  formatTextValue={(value) => {
                    return sortinoRatioArith;
                  }}
                  colors={["#fa798b", "#55f8a6"]}
                  style={chartStyle}
                  textColor={"none"}
                  needleColor={lineColor}
                  needleBaseColor={baseColor}
                ></GaugeChart>{" "}
                <div id="perfmeterlabelIndex2">{sortinoRatioArith}</div>
              </div>
            </div>
          </div>
        }
        {/*         <RadarGraph
          key={uuidv4()}
          selectComparisons={{
            label: "Portfolios",
            value: "portfolios",
            prop: "selectedPortfolio",
            single: "Portfolio",
          }}
          selectedItems={valuesArray}
          userData={portfolio.entries}
          defaultSymbol={String(portfolio.settings.currencySymbol)}
          type={"line"}
          userSettings={portfolio.settings}
          lineColors={portfolioColors}
          metricsToCompare={metricsToCompare}
          riskFreeRate={riskFreeRate}
          allEntries={allEntries}
          processedData={allData}
          className={"AIComparerGraph2"}
          showTitle={false}
        /> */}
      </div>
    </div>
  );
};

export default PerformanceMeter;
