import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import dropdownStyles from "../../functions/dropdownStyles";
import { theme } from "../../components/selectTheme";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DayofWeekOptions,
  MonthOptions,
} from "../../components/Right-Panel/Filter-Menu/FilterMenuDropdownOptions";
import { assetClasses } from "../Manual-Trade-Entry/DropDownOptions";
import getOpenAIMessage from "../../utils/handleOpenAIRequest";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";
import filterTheTrades from "./filterTheTrades";
import { profitLossCalculation } from "../../functions/profitLossCalculation";
import { returnNetPLDollar } from "../../functions/profitLossCalculation";
import dollarCostAverage from "../../functions/dollarCostAvg";
import longShortDelineation from "../../functions/longShortDelineation";
import getStandardDeviation from "../../functions/calculations_for_metrics/standardDeviation";
import getEntryExitLots from "../../functions/getEntryExitLots";
import {
  getMaxDD,
  getMaxDDPercent,
} from "../../functions/calculations_for_metrics/getmaxDD";
import {
  calculateSharpeRatios,
  calculateSortinoRatios,
} from "../../functions/sharpeRatio";
import PopoverStickOnHover from "../../components/PopoverStickOnHover";
import isArray from "../../functions/arrayFunctions/isArray";
import getSession from "../../functions/getSession";
import ulcerIndex from "../../functions/calculations_for_metrics/ulcerIndex";
import calculateZScore from "../../functions/zScoreCalculation";
import linearRegression from "../../functions/calculations_for_metrics/linearRegression";
import pcorr from "../../functions/calculations_for_metrics/pearsonCorrelation";
import STEYX from "../../functions/calculations_for_metrics/steyx";
import DEVSQ from "../../functions/calculations_for_metrics/DEVSQ";
import $ from "jquery";

var { jStat } = require("jstat");

function studentsTCumulativeValue(tValue, degreesOfFreedom) {
  // Calculate the cumulative t-distribution
  let cumulativeValue = jStat.studentt.cdf(tValue, degreesOfFreedom);

  // Return the result
  return cumulativeValue;
}

const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
function generateReport(objects, metricNames, comparerType) {
  /*   let report = `[no prose][Output only JSON]Provide a JSON response with 'probarr' and 'insights' as properties. 'probarr' should be an array of objects with 'score' and 'category' properties, with the 'category' properties EXACTLY matching the ${comparerType.single.toLowerCase()} titles below, and for the 'score' property, assign a score (0.00 to 100.00, where under 60.00 is failing) to each of the following ${
    comparerType.value
  } that represents the performance of the ${comparerType.single.toLowerCase()} based on the metric values provided for the ${comparerType.single.toLowerCase()}. If 0 trades were taken, assign a score of 0.00. The 'insights' property should be a detailed, in-depth analysis, in text format only, based on all the metric values for each ${comparerType.single.toLowerCase()} below. The analysis should transcend surface-level inferences from the metrics; it should provide a deeper interconnection between the metrics and their corresponding values to really pinpoint specific things the trader is doing well and what they need to potentially adjust in their strategy or work on as a trader to improve their performance. Also offer tips, based on the metrics and analysis, to improve each ${comparerType.single.toLowerCase()} and the overall trading process. The analysis should be in paragraph format, and each tip should be rendered in a bulleted format, each on a new line. DO NOT just reiterate the metrics. Be professional and friendly in tone, and incorporate a touch of emojis where appropriate. Note, TradeFuse Index = (win rate)*(1 + (profit-loss ratio)) and  TradeFuse Golden Ratio = (profit-loss ratio)/(avg RRR). {\n\n`;
 */
  //let report = `Provide a JSON response with 'probarr' and 'insights' as properties. For 'probarr', assign a performance score (0.00-100.00; below 60.00 is failing) to each ${comparerType.single.toLowerCase()} based on the given metric values for each ${comparerType.single.toLowerCase()}. Use an array of objects with 'score' and 'category' properties; the latter must match the ${comparerType.single.toLowerCase()} titles exactly. If no trades were taken, the score should be 0.00. Under 'insights', provide a unique, valuable analysis that explains the interrelation of metrics for each ${comparerType.single.toLowerCase()} and tips for improving them and overall trading. Avoid being vague, while remaining professional and friendly. Use at most one emoji. Note: TradeFuse Index = (win rate)*(1 + (profit-loss ratio)) and TradeFuse Golden Ratio = (profit-loss ratio)/(avg RRR).\n\n`;
  let report = `[no prose][Output only JSON]Provide a JSON response with 'probarr' as properties. 'probarr' should be an array of objects with 'score' and 'category' properties, with the 'category' properties EXACTLY matching the ${comparerType.single.toLowerCase()} titles below, and for the 'score' property, assign a score from 0.00 to 100.00, where under 60.00 is failing, to each of the following ${
    comparerType.value
  } that represents the relative performance of the ${comparerType.single.toLowerCase()} based on the metric values provided for the ${comparerType.single.toLowerCase()}. If 0 trades were taken, assign a score of 0.00. Note: TradeFuse Index = (win rate)*(1 + (profit-loss ratio)) and TradeFuse Golden Ratio = (profit-loss ratio)/(avg RRR).{\n\n`;

  for (let i = 0; i < objects.length; i++) {
    const obj = objects[i];
    report += obj.label + "\n";
    report += "- Number of trades: " + obj.trades + " \n";

    if (obj.trades === 0) {
    } else {
      for (let j = 0; j < metricNames.length; j++) {
        report +=
          "- " +
          metricNames[j] +
          ": " +
          (!obj?.data[j] ? "-" : obj?.data[j]?.toFixed(3)) +
          "\n";
      }
    }

    report += "\n";
  }
  if (!objects.length) report += `No ${comparerType.value} were selected\n`;
  return report;
}

const SelectComparison = (props) => {
  $(window).bind("beforeunload", function () {
    return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
  });
  const processedData = props.processedData;
  const allData = processedData.allData;
  const portfolios = allData.createdPortfolios;
  const allEntries = portfolios.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.entries);
  }, []);
  const portfoliosDropdownItem = portfolios.reduce((dropdownItems, port) => {
    if (port.name !== "Default") {
      dropdownItems.push({ label: port.name, value: port.name });
    }
    return dropdownItems;
  }, []);
  // Helper function to calculate week of the year
  function getWeek(date) {
    let firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    let pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return (
      date.getFullYear() +
      "-" +
      Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
    );
  }

  // Helper function to calculate average
  function calculateAverage(total, count) {
    let perAvg = 0;
    let totalCount = 0;
    for (let key in total) {
      perAvg += total[key];
      totalCount += 1;
    }
    return perAvg / totalCount;
  }
  const dropDownItems = processedData.filterLists;
  const portfolio = processedData.portfolio;
  const allEntriesButFiltered = portfolio.entries; // used in every other comparison
  const globalCalculations = props.globalCalculations;
  const dateToggle = portfolio?.settings.dateFilterBy;
  const riskFreeRate = globalCalculations.riskFreeRate;
  const [comparerType, setComparerType] = useState("");
  const [method, setMethod] = useState("");
  const [metric, setMetric] = useState("");
  const [selectOptions, setselectOptions] = useState([]);
  const [selectCheck, setselectCheck] = useState("");
  const controller = new AbortController();
  const signal = controller.signal;
  const defaultKPIbundle = [
    {
      label: "Simple KPI Bundle",
      value: "Simple KPI Bundle",
      metrics: [
        { value: "totalPl", label: "Total P/L" },
        { value: "avgHoldingTime", label: "Avg. Holding Time" },
        { value: "tfIndex", label: "TradeFuse Index" },
        { value: "sharpeRatio", label: "Sharpe Ratio" },
        { value: "maxDD", label: "Max. Drawdown" },
      ],
    },
    {
      label: "Advanced KPI Bundle",
      value: "Advanced KPI Bundle",
      metrics: [
        { value: "totalPl", label: "Total P/L" },
        { value: "avgPl", label: "Avg. P/L" },
        { value: "avgHoldingTime", label: "Avg. Holding Time" },
        { value: "tfIndex", label: "TradeFuse Index" },
        { value: "tfGoldenRatio", label: "TradeFuse Golden Ratio" },
        { value: "totalEff", label: "Avg. Total Efficiency" },
        {
          value: "probabilityOfRandomChance",
          label: "Probability of Random Chance",
        },
        { value: "sharpeRatio", label: "Sharpe Ratio" },
        { value: "maxDD", label: "Max. Drawdown" },
      ],
    },
  ];
  const [selectMetricsPackage, setselectMetricsPackage] = useState();

  const zIndexValue = props.zIndexValue;
  const selectAllFunction = () => {
    let allLabels = [];
    if (selectCheck) {
      setselectCheck(false);
      allLabels = [];
    } else {
      setselectCheck(true);
      allLabels = selectOptions.map((option) => {
        return {
          label: option.label,
          value: option.label,
        };
      });
    }
    setMethod(allLabels);
  };
  const setLoaderText = (text) => {
    setTimeout(() => {
      const element = document.getElementById("loadertext");
      element &&
        (element.innerHTML = `Your ${text} are being analyzed by an AI. Please wait for at least 30 seconds.`);
    }, 7011);
  };
  const handleTypeChange = (newValue) => {
    setComparerType(newValue);
    if (!newValue) {
      handleMethodChange("");
      handleMetricChange("");
    }
    if (newValue !== comparerType) {
      handleMethodChange("");
      setselectCheck(false);
    }
    const inLabel = !newValue ? "" : newValue.label;
    const sessions = [
      { label: "New York", value: "newyork" },
      { label: "London", value: "london" },
      { label: "Sydney", value: "sydney" },
      { label: "Tokyo", value: "tokyo" },
    ];
    switch (inLabel) {
      case "Portfolios":
        setselectOptions(portfoliosDropdownItem);
        break;
      case "Strategies":
        setselectOptions(dropDownItems.strategy);
        break;
      case "Mistakes":
        setselectOptions(dropDownItems.mistake);
        break;
      case "Trading Sessions":
        setselectOptions(sessions);
        break;
      case "Symbols":
        setselectOptions(dropDownItems.symbol);
        break;
      case "Asset Classes":
        setselectOptions(assetClasses);
        break;
      case "Order Types":
        let filterOnlyTrade = dropDownItems.orderType.slice();
        filterOnlyTrade = filterOnlyTrade.filter(
          (orderType) =>
            orderType.label !== "Deposit" &&
            orderType.label !== "Withdrawal" &&
            orderType.label !== "Funding Payment" &&
            orderType.label !== "Commit" &&
            orderType.label !== "Approval" &&
            orderType.label !== "Wrap" &&
            orderType.label !== "Self"
        );
        setselectOptions(filterOnlyTrade);
        break;
      case "Tags":
        setselectOptions(dropDownItems.tags);
        break;
      case "Emotional States":
        setselectOptions(dropDownItems.emotion);
        break;
      case "Physical States":
        setselectOptions(dropDownItems.physical);
        break;
      case "Time Frames":
        setselectOptions(dropDownItems.timeframeWithDefault);
        break;
      case "Confidences":
        setselectOptions(dropDownItems.confidenceWithDefault);
        break;
      case "Market States":
        setselectOptions(dropDownItems.marketStateWithDefault);
        break;
      case "Days of Week":
        setselectOptions(DayofWeekOptions);
        break;
      case "Months":
        setselectOptions(MonthOptions);
        break;
      case "Years":
        setselectOptions(dropDownItems.years);
        break;
      default:
        break;
    }
  };

  const handleMethodChange = (newValue) => {
    setMethod(newValue);
    if (!newValue) {
      //handleMetricChange("");
    }
  };

  const handleMetricChange = (newValue) => {
    setMetric(newValue);
  };
  const handleMetricPackage = (newValue) => {
    const selectMetricsPackage = newValue.metrics.map((metric) => metric);
    setselectMetricsPackage("");
    setMetric(selectMetricsPackage);
  };
  const handleDeleteClick = (controller) => {
    props.deleteItem(props.selectionId, controller);
  };

  const whatToCompareOptions = [
    {
      label: "Portfolios",
      value: "portfolios",
      prop: "selectedPortfolio",
      single: "Portfolio",
    },
    {
      label: "Strategies",
      value: "strategies",
      prop: "strategy",
      single: "Strategy",
    },
    {
      label: "Trading Sessions",
      value: "trading sessions",
      prop: "trading sessions",
      single: "Trading Session",
    },
    {
      label: "Days of Week",
      value: "days of week",
      prop: "dayOfWeek",
      single: "Day of Week",
    },
    {
      label: "Months",
      value: "months",
      prop: "monthOfYear",
      single: "Month",
    },
    { label: "Years", value: "years", prop: "year", single: "Year" },
    {
      label: "Order Types",
      value: "order types",
      prop: "orderType",
      single: "Order Type",
    },
    {
      label: "Asset Classes",
      value: "asset classes",
      prop: "selectedPortfolioType",
      single: "Asset Class",
    },
    {
      label: "Symbols",
      value: "symbols",
      prop: "symbol",
      single: "Symbol",
    },
    { label: "Tags", value: "tags", prop: "tags", single: "Tag" },
    {
      label: "Mistakes",
      value: "mistakes",
      prop: "selectedMistake",
      single: "Mistake",
    },
    {
      label: "Emotional States",
      value: "emotional states",
      prop: "selectedEmotion",
      single: "Emotional State",
    },
    {
      label: "Physical States",
      value: "physical states",
      prop: "selectedPhysical",
      single: "Physical State",
    },
    {
      label: "Time Frames",
      value: "time frames",
      prop: "selectedTimeframe",
      single: "Time Frame",
    },
    {
      label: "Confidences",
      value: "confidences",
      prop: "selectedConfidence",
      single: "Confidence",
    },
    {
      label: "Market States",
      value: "market states",
      prop: "selectedMarket",
      single: "Market State",
    },
  ];

  let metricOptions = [
    { value: "totalPl", label: "Total P/L" },
    { value: "stdDevDollar", label: `Std. Dev.` },
    { value: "avgHoldingTime", label: "Avg. Holding Time" },
    { value: "tfIndex", label: "TradeFuse Index" },
    { value: "sharpeRatio", label: "Sharpe Ratio" },
    { value: "maxDD", label: "Max. Drawdown" },
    { value: "volPercent", label: `Volatility %` },
    { value: "dailyPL", label: "Daily Avg. P/L" },
    { value: "weeklyPl", label: "Weekly Avg. P/L" },
    { value: "monthlyPl", label: "Monthly Avg. P/L" },
    { value: "avgPl", label: "Avg. P/L" },
    { value: "totalEff", label: "Avg. Total Efficiency" },
    { value: "volDollar", label: `Volatility` },
    { value: "tfGoldenRatio", label: "TradeFuse Golden Ratio" },
    { value: "rmultiple", label: "R-Multiple" },
    { value: "rewardriskratio", label: "Reward-Risk Ratio" },

    { value: "profitFactor", label: "Profit Factor" },
    { value: "winRate", label: "Win Rate" },
    { value: "recoveryFactor", label: "Recovery Factor" },
    {
      value: "returnOverMaxDrawdown",
      label: "Return Over Max Drawdown",
    },
    { value: "stdDevPercent", label: `Std. Dev. %` },

    { value: "profitLossRatio", label: "Profit/Loss Ratio" },
    { value: "CPCIndex", label: "CPC Index" },
    { value: "expectation", label: "Expectation" },
    { value: "kellypercent", label: "Kelly %" },
    {
      value: "normalizedExpectancypercent",
      label: "Normalized Expectancy %",
    },
    { value: "ulcerIndex", label: "Ulcer Index" },
    {
      value: "sortinoRatio",
      label: "Sortino Ratio",
    },
    {
      value: "probabilityOfRandomChance",
      label: "Probability of Random Chance",
    },
    { value: "zScore", label: "Z-Score" },
    { value: "LRCorrelation", label: "LR Correlation" },
    { value: "LRStandardError", label: "LR Standard Error" },
    { value: "KRatio", label: "K-Ratio" },
  ];
  if (comparerType && comparerType.label === "Days of Week") {
    metricOptions = metricOptions.filter(
      (option) => option.value !== "weeklyPl"
    );
  } else if (comparerType && comparerType.label === "Months") {
    metricOptions = metricOptions.filter(
      (option) => option.value !== "monthlyPl"
    );
  }
  metricOptions.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  function addValueAtIndex(arr1, arr2, value, searchValue) {
    // Find the index of the search value in arr1
    const index = arr1.indexOf(searchValue);

    // If the search value is not found in arr1, return -1
    if (index === -1) {
      return -1;
    }

    arr2[index] = value;

    // Return the index
    return index;
  }

  return (
    <div
      className={`selectComparerWrapper noselect`}
      id={`selectComparerWrapper${props.selectionId}`}
      key={`selectComparerWrapper${props.selectionId}`}
      style={{ zIndex: zIndexValue }}
    >
      <label className="Comparerselectionfields zindex5">
        What do you want to compare?
        <CreatableSelect
          styles={dropdownStyles}
          isClearable
          value={comparerType}
          onChange={handleTypeChange}
          theme={theme}
          options={whatToCompareOptions}
        />
      </label>
      {comparerType && (
        <div className="Comparerselectionfields2Wrapper">
          <label className="Comparerselectionfields2 zindex4">
            {`Select ${comparerType.value} to compare:`}
            <CreatableSelect
              isMulti
              styles={dropdownStyles}
              isClearable
              value={method}
              onChange={handleMethodChange}
              theme={theme}
              options={selectOptions.sort()}
            />
          </label>
          <label className="container checkscomparer">
            <input
              type="checkbox"
              id="boxNoEntrySlip"
              name="hasEntrySlippage"
              checked={selectCheck}
              onChange={(e) => selectAllFunction(e)}
            />
            <span className="checkmark checkmark-col1-2"></span>
            <span style={{ marginTop: "0px", marginRight: 4 }}>
              &emsp;&ensp;Select All {comparerType && comparerType.label}
            </span>
          </label>
        </div>
      )}
      {method && comparerType && (
        <div className="Comparerselectionfields22Wrapper">
          <label className="Comparerselectionfields2 zindex2">
            Select KPI's to analyze:
            <CreatableSelect
              isMulti
              styles={dropdownStyles}
              isClearable
              value={metric}
              onChange={handleMetricChange}
              theme={theme}
              options={metricOptions}
            />
          </label>
          <div className="Comparerselectionfields2ortext">OR</div>
          <label className="Comparerselectionfields2">
            Select KPI bundle:
            <CreatableSelect
              styles={dropdownStyles}
              isClearable
              value={selectMetricsPackage}
              onChange={handleMetricPackage}
              theme={theme}
              options={defaultKPIbundle}
            />
          </label>
        </div>
      )}
      {metric && (
        <button
          className="generatecomparisonbutton"
          onClick={async () => {
            //setaiMessageHasGot(false);
            //store.dispatch(setLoaderState({ loading: true }));
            $(window).bind("beforeunload", function () {
              return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
            });
            const element = document.querySelector("#fetchingInsightsloader3");
            if (element) {
              element.style.opacity = 0.8;
              element.style.transform = "translate(0, 0)";
              element.style.transition = "opacity 1s, transform 1s";
            }
            localStorage.setItem("autoiterationMessage", `Creating graphs...`);
            const whatToCompare = comparerType.label;
            const whatToCompareProp = comparerType.prop;
            const settings = portfolio.settings;
            const calType = settings.calculationType;
            setLoaderText(whatToCompare.toLowerCase());
            const metricValues = (data, selectedItems, metrics) => {
              let metricValues = [];
              for (let ix = 0, jx = selectedItems.length; ix < jx; ix++) {
                let dataValues = [];
                const selectedItem = selectedItems[ix].label;
                let accountbalancearr = [];
                let plArr = [];
                let percentGainArr = [];
                let percentgainLOSSArray = [];
                let percentgainWINArray = [];
                let totalPL_dollar = 0;
                let totalLosses = 0;
                let totalTrades = 0;
                let totalProfits = 0;
                let riskedPercentArr = [];
                let efficiencesArr = [];
                let timeInMarketArr = [];
                let timeInMarket = 0;
                let numWins = 0;
                let numLosses = 0;
                let numTrades = 0;
                let profitFactor = 0;
                let profitLossRatio = 0;
                let volatility = 0;
                let volatilityPercent = 0;
                let avgHoldingTime = 0;
                let tfIndex = 0;
                let xArray = [];
                let yArray = [];
                let iterationarray = [];
                let dailyPL = {},
                  weeklyPL = {},
                  monthlyPL = {};
                let dailyCount = {},
                  weeklyCount = {},
                  monthlyCount = {};
                //First filter by the selected item
                let filteredTrades = "";
                let filteredTradesPre = data.slice();
                filteredTrades = filterTheTrades(
                  whatToCompare,
                  whatToCompareProp,
                  selectedItem,
                  filteredTradesPre
                );

                for (let i = 0, j = filteredTrades.length; i < j; i++) {
                  const thisAccountBalance = Number(filteredTrades[i].balance);
                  /*                   const entryId = filteredTrades[i].entryId;
                   */ let orderType = filteredTrades[i].entry.orderType;
                  let multiExecution = filteredTrades[i].entry.multiExecution;
                  let exitExecution = filteredTrades[i].entry.exitExecution;
                  const entryExitValues = getEntryExitLots(
                    multiExecution,
                    exitExecution
                  );
                  /*                   const entryLots = entryExitValues.entryLots;
                   */ const exitLots = entryExitValues.exitLots;
                  /*                   let x100profitLoss = Number(
                    filteredTrades[i].entry.profitLoss * 100
                  );
                  let x100commissions = Number(
                    filteredTrades[i].entry.commissions * 100
                  );
                  let x100fees = Number(filteredTrades[i].entry.fees * 100);
 */ const correctedSUM = returnNetPLDollar(
                    filteredTrades[i].entry.profitLoss,
                    filteredTrades[i].entry.commissions,
                    filteredTrades[i].entry.fees,
                    calType
                  );
                  const thisTotalDws = Number(filteredTrades[i].totaldw);

                  if (
                    filteredTrades[i].entry.orderType === "Deposit" ||
                    filteredTrades[i].entry.orderType === "Withdrawal" ||
                    filteredTrades[i].entry.orderType === "Funding Payment" ||
                    filteredTrades[i].entry.orderType === "Commit" ||
                    filteredTrades[i].entry.orderType === "Approval" ||
                    filteredTrades[i].entry.orderType === "Wrap" ||
                    filteredTrades[i].entry.orderType === "Self"
                  ) {
                    continue;
                  } else if (exitLots === 0) {
                    continue; // exclude trades with no exit executions
                  } else {
                    iterationarray.push(i);

                    accountbalancearr.push(thisAccountBalance);
                    let percentgain =
                      (correctedSUM / (thisAccountBalance - correctedSUM)) *
                      100;
                    totalPL_dollar += correctedSUM;
                    totalTrades += 1;
                    plArr.push(correctedSUM);
                    percentGainArr.push(percentgain);
                    if (correctedSUM < 0) {
                      totalLosses += correctedSUM;
                      numLosses += 1;
                    } else if (correctedSUM > 0) {
                      totalProfits += correctedSUM;
                      numWins += 1;
                    }
                  }

                  let eachStartDateTime = new Date(
                    filteredTrades[i].entry.startDateTime
                  ); // or startDateTimez
                  let eachEndDateTime = new Date(
                    filteredTrades[i].entry.endDateTime
                  );
                  let day = eachEndDateTime.toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
                  let week = getWeek(eachEndDateTime); // Format as 'YYYY-WW'
                  let month = eachEndDateTime.toISOString().slice(0, 7); // Format as 'YYYY-MM'
                  // Calculate daily P/L
                  dailyPL[day] = (dailyPL[day] || 0) + correctedSUM;
                  dailyCount[day] = (dailyCount[day] || 0) + 1;

                  // Calculate weekly P/L
                  weeklyPL[week] = (weeklyPL[week] || 0) + correctedSUM;
                  weeklyCount[week] = (weeklyCount[week] || 0) + 1;

                  // Calculate monthly P/L
                  monthlyPL[month] = (monthlyPL[month] || 0) + correctedSUM;
                  monthlyCount[month] = (monthlyCount[month] || 0) + 1;

                  for (let i = 0, j = percentGainArr.length; i < j; i++) {
                    if (percentGainArr[i] < 0) {
                      percentgainLOSSArray.push(percentGainArr[i]);
                    } else if (percentGainArr[i] > 0) {
                      percentgainWINArray.push(percentGainArr[i]);
                    }
                  }
                  // Calculations
                  let winRate = numWins / totalTrades;
                  let averagePLPerTradedollar = totalPL_dollar / totalTrades;
                  let averagePLPerWindollar = totalProfits / numWins;
                  let averagePLPerLossdollar = totalLosses / numLosses;
                  let avgPL = arrAvg(plArr);
                  let stdDev = getStandardDeviation(plArr);
                  let averagePLPerTradepercent = arrAvg(percentGainArr);
                  /*                   let averagePLPerWinpercent = arrAvg(percentgainWINArray);
                   */ let averagePLPerLosspercent =
                    arrAvg(percentgainLOSSArray);
                  let maxDD = getMaxDD(plArr);
                  volatility = stdDev / totalTrades;
                  let stdDevPercent = getStandardDeviation(percentGainArr);
                  volatilityPercent = stdDevPercent / totalTrades;
                  profitFactor = totalProfits / Math.abs(totalLosses);
                  profitLossRatio =
                    averagePLPerWindollar / Math.abs(averagePLPerLossdollar);
                  tfIndex = winRate * (1 + profitLossRatio);
                  let winRateasNumber = winRate * 100;
                  let cummulativeGain = (totalPL_dollar / thisTotalDws) * 100;
                  let dailyAvgPL = calculateAverage(dailyPL, dailyCount);
                  let weeklyAvgPL = calculateAverage(weeklyPL, weeklyCount);
                  let monthlyAvgPL = calculateAverage(monthlyPL, monthlyCount);

                  // linear array
                  let lineararr = [];
                  const linearRegressionReturn = linearRegression(
                    accountbalancearr,
                    iterationarray
                  );
                  const slope = linearRegressionReturn.slope;
                  const intercept = linearRegressionReturn.intercept;
                  for (let i = 0, j = accountbalancearr.length; i < j; i++) {
                    let lineararrentry = slope * i + intercept;
                    lineararr.push(lineararrentry);
                  }
                  // --------
                  let recoveryFactor =
                    totalPL_dollar / Math.abs(getMaxDD(plArr));
                  let expectation =
                    averagePLPerTradepercent /
                    Math.abs(averagePLPerLosspercent);

                  // COMPUTE TIME IN MARKET
                  let eachTime = (eachEndDateTime - eachStartDateTime) / 1000;
                  timeInMarketArr.push(eachTime);

                  avgHoldingTime = arrAvg(timeInMarketArr);
                  timeInMarket += eachTime;

                  let entryAvg = dollarCostAverage(
                    filteredTrades[i].entry.multiExecution,
                    "entry",
                    filteredTrades[i].entry.orderType
                  );
                  let exitAvg = dollarCostAverage(
                    filteredTrades[i].entry.exitExecution,
                    "exit",
                    filteredTrades[i].entry.orderType
                  );
                  let RRR =
                    (Number(filteredTrades[i].entry.takeProfit) - entryAvg) /
                    (entryAvg - Number(filteredTrades[i].entry.stopLoss));

                  let RMultiple =
                    (exitAvg - entryAvg) /
                    (entryAvg - Number(filteredTrades[i].entry.stopLoss));

                  xArray.push(RRR);
                  yArray.push(RMultiple);

                  // COMPUTE AVG EFFICIENCIES
                  let efficiency = 0;
                  if (
                    longShortDelineation(
                      filteredTrades[i].entry.orderType,
                      multiExecution
                    ) === "Long"
                  ) {
                    efficiency =
                      100 *
                      ((exitAvg - entryAvg) /
                        (filteredTrades[i].entry.maxFavEx -
                          filteredTrades[i].entry.maxAdEx));
                  } else if (
                    longShortDelineation(
                      filteredTrades[i].entry.orderType,
                      multiExecution
                    ) === "Short"
                  ) {
                    efficiency =
                      100 *
                      ((entryAvg - exitAvg) /
                        (filteredTrades[i].entry.maxAdEx -
                          filteredTrades[i].entry.maxFavEx));
                  }
                  efficiencesArr.push(efficiency);
                  const avgEff = arrAvg(efficiencesArr);
                  // COMPUTE RISK %
                  let adjrrr =
                    -entryAvg /
                    (entryAvg - Number(filteredTrades[i].entry.stopLoss));

                  let divisor =
                    filteredTrades[i].entry.selectedPortfolioType === "Forex"
                      ? RMultiple
                      : adjrrr;

                  let initPl = profitLossCalculation(
                    multiExecution,
                    [],
                    orderType,
                    filteredTrades[i].entry.selectedPortfolioType,
                    filteredTrades[i].entry.symbol.pointValue,
                    true
                  );
                  // If the asset class if forex we have to calculate risk a slightly different way
                  let dividend =
                    filteredTrades[i].entry.selectedPortfolioType === "Forex"
                      ? correctedSUM
                      : returnNetPLDollar(
                          initPl,
                          filteredTrades[i].entry.commissions,
                          filteredTrades[i].entry.fees,
                          calType
                        );
                  let percent =
                    Number(
                      dividend / (thisAccountBalance - Number(correctedSUM))
                    ) * 100;
                  let riskedPercent = percent / divisor;
                  riskedPercentArr.push(riskedPercent);
                  let goldenRatio =
                    averagePLPerWindollar /
                    Math.abs(averagePLPerLossdollar) /
                    arrAvg(xArray);
                  let sharpeRatioArith = calculateSharpeRatios(
                    accountbalancearr,
                    Number(riskFreeRate)
                  ).arithmeticSharpeRatio;
                  let returnOverMaxDrawdown =
                    cummulativeGain /
                    Math.abs(getMaxDDPercent(accountbalancearr)); //maxxdd percent needs a special array
                  let CPCIndex = profitFactor * profitLossRatio * winRate;
                  let kellypercent =
                    (averagePLPerTradedollar / averagePLPerWindollar) * 100;
                  let normalizedExpectancypercent =
                    (averagePLPerTradedollar /
                      Math.abs(averagePLPerLossdollar)) *
                    100;
                  let ulcerIndexVal = ulcerIndex(accountbalancearr);
                  let sortinoRatio = calculateSortinoRatios(
                    accountbalancearr,
                    Number(riskFreeRate)
                  ).arithmeticSortinoRatio;
                  let probabilityOfRandomChance =
                    totalTrades <= 0
                      ? 0
                      : 1 -
                        studentsTCumulativeValue(
                          averagePLPerTradepercent /
                            (stdDevPercent / Math.sqrt(totalTrades - 1)),
                          totalTrades - 1
                        );
                  let zScore = calculateZScore(accountbalancearr).zScore;
                  let LRCorrelation = pcorr(accountbalancearr, lineararr);
                  let LRStandardError = STEYX(accountbalancearr, lineararr);
                  let KRatio =
                    slope /
                    ((STEYX(accountbalancearr, lineararr) /
                      Math.sqrt(DEVSQ(lineararr))) *
                      totalTrades);
                  const mapDataValues = (dataValuesArr, metricsToPush) => {
                    metricsToPush.forEach((metric) => {
                      if (metric === "Profit Factor") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          profitFactor,
                          metric
                        );
                      } else if (metric === "Profit/Loss Ratio") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          profitLossRatio,
                          metric
                        );
                      } else if (metric === "Total P/L") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          totalPL_dollar,
                          metric
                        );
                      } else if (metric === "Avg. P/L") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          avgPL,
                          metric
                        );
                      } else if (metric === "Daily Avg. P/L") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          dailyAvgPL,
                          metric
                        );
                      } else if (metric === "Weekly Avg. P/L") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          weeklyAvgPL,
                          metric
                        );
                      } else if (metric === "Monthly Avg. P/L") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          monthlyAvgPL,
                          metric
                        );
                      } else if (metric === "Sharpe Ratio") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          sharpeRatioArith,
                          metric
                        );
                      } else if (metric === "TradeFuse Index") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          tfIndex,
                          metric
                        );
                      } else if (metric === "TradeFuse Golden Ratio") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          goldenRatio,
                          metric
                        );
                      } else if (metric === "Win Rate") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          winRateasNumber,
                          metric
                        );
                      } else if (metric === "Std. Dev.") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          stdDev,
                          metric
                        );
                      } else if (metric === "Std. Dev. %") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          stdDevPercent,
                          metric
                        );
                      } else if (metric === "Volatility") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          volatility,
                          metric
                        );
                      } else if (metric === "Volatility %") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          volatilityPercent,
                          metric
                        );
                      } else if (metric === "Avg. Holding Time") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          avgHoldingTime,
                          metric
                        );
                      } else if (metric === "Max. Drawdown") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          maxDD,
                          metric
                        );
                      } else if (metric === "Avg. Total Efficiency") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          avgEff,
                          metric
                        );
                      } else if (metric === "Recovery Factor") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          recoveryFactor,
                          metric
                        );
                      } else if (metric === "Expectation") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          expectation,
                          metric
                        );
                      } else if (metric === "Return Over Max Drawdown") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          returnOverMaxDrawdown,
                          metric
                        );
                      } else if (metric === "CPC Index") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          CPCIndex,
                          metric
                        );
                      } else if (metric === "Kelly %") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          kellypercent,
                          metric
                        );
                      } else if (metric === "Normalized Expectancy %") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          normalizedExpectancypercent,
                          metric
                        );
                      } else if (metric === "Ulcer Index") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          ulcerIndexVal,
                          metric
                        );
                      } else if (metric === "Sortino Ratio") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          sortinoRatio,
                          metric
                        );
                      } else if (metric === "Probability of Random Chance") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          probabilityOfRandomChance,
                          metric
                        );
                      } else if (metric === "Z-Score") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          zScore,
                          metric
                        );
                      } else if (metric === "LR Correlation") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          LRCorrelation,
                          metric
                        );
                      } else if (metric === "LR Standard Error") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          LRStandardError,
                          metric
                        );
                      } else if (metric === "K-Ratio") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          KRatio,
                          metric
                        );
                      } else if (metric === "R-Multiple") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          RMultiple,
                          metric
                        );
                      } else if (metric === "Reward-Risk Ratio") {
                        addValueAtIndex(
                          metricsToPush,
                          dataValuesArr,
                          RRR,
                          metric
                        );
                      }
                    });
                  };

                  if (
                    whatToCompare === "Confidences" ||
                    whatToCompare === "Days of Week" ||
                    whatToCompare === "Months" ||
                    whatToCompare === "Time Frames" ||
                    whatToCompare === "Order Types" ||
                    whatToCompare === "Asset Classes" ||
                    whatToCompare === "Portfolios" ||
                    whatToCompare === "Market States"
                  ) {
                    if (
                      selectedItem ===
                      filteredTrades[i].entry[whatToCompareProp]
                    ) {
                      mapDataValues(dataValues, metrics);
                    }
                  } else if (whatToCompare === "Symbols") {
                    if (
                      selectedItem === filteredTrades[i].entry.symbol.symbols[0]
                    ) {
                      mapDataValues(dataValues, metrics);
                    }
                  } else if (
                    whatToCompare === "Strategies" ||
                    whatToCompare === "Emotional States" ||
                    whatToCompare === "Tags" ||
                    whatToCompare === "Physical States" ||
                    whatToCompare === "Mistakes"
                  ) {
                    if (
                      filteredTrades[i].entry[whatToCompareProp] != null &&
                      filteredTrades[i].entry[whatToCompareProp].includes(
                        selectedItem
                      )
                    ) {
                      mapDataValues(dataValues, metrics);
                    }
                  } else if (whatToCompare === "Trading Sessions") {
                    var dateTime = new Date(
                      dateToggle === "Trade Open"
                        ? filteredTrades[i].entry.startDateTime
                        : filteredTrades[i].entry.endDateTime
                    );

                    // Add condition to filter trades based on trading session
                    const tradingSession = getSession(dateTime);

                    const selectedSession = selectedItem; // Replace with the selected trading session

                    if (tradingSession === selectedSession) {
                      mapDataValues(dataValues, metrics);
                    }
                  } else if (whatToCompare === "Years") {
                    // Extract the year from trade's startDateTime or endDateTime
                    const tradeYear = new Date(
                      filteredTrades[i].entry.startDateTime // Assuming filtering based on startDateTime, adjust if necessary
                    ).getFullYear();

                    const selectedYear = parseInt(selectedItem, 10); // Ensure selectedItem is treated as a year
                    if (tradeYear === selectedYear) {
                      mapDataValues(dataValues, metrics);
                    }
                  }
                }
                const dataObj = {
                  label: selectedItem,
                  data: dataValues,
                  trades: totalTrades,
                  untamperedData: dataValues.slice(),
                };
                metricValues.push(dataObj);
              }

              return metricValues;
            };
            const metricsToCompare = metric.slice();
            const metricArr = metricsToCompare.map((metric) => {
              return metric.label;
            });
            const metricVals = metricValues(
              whatToCompare === "Portfolios"
                ? allEntries
                : allEntriesButFiltered,
              method.slice(),
              metricArr
            );
            const messageIn = generateReport(
              metricVals,
              metricArr,
              comparerType
            );

            props.setcomparisonObjs({
              whatToCompare: comparerType,
              selectedItems: method,
              metricsToCompare: metric,
              aiObjs: [],
              graphType: "line",
              id: props.selectionId,
              zIndex: zIndexValue,
            });
            //showConfirmedActionButton("Comparison created!");
            //handleDeleteClick(controller);
            props.setAIFetching(true);
            localStorage.setItem(
              "autoiterationMessage",
              `Comparison created. Generating AI scores...`
            );
            const completion = await getOpenAIMessage(messageIn, signal);
            let parsedObject;
            try {
              if (completion !== "err") {
                function removeFormatting(text) {
                  // Replace the beginning "```json" with an empty string
                  let result = text.replace("```json", "");

                  // Replace the ending "```" with an empty string
                  result = result.replace("```", "");

                  return result;
                }

                // Example usage
                const formattedString = completion.content;
                const cleanString = removeFormatting(formattedString);
                parsedObject = JSON.parse(cleanString);
              }
            } catch (error) {
              // Handle the error here
              console.error("Error parsing JSON:", error);
              // Set a default value or perform any other necessary actions
              parsedObject = null; // or any other appropriate default value
              controller.abort();
            }

            let insightsText =
              ""; /* parsedObject ? parsedObject.insights : ""; */
            let probArr = parsedObject ? parsedObject.probarr : [];
            let insights = parsedObject
              ? insightsText +
                "\n\n**Remember that past performance does not guarantee future results and this is not financial advice."
              : "";

            // add show prop to each aiobj
            if (probArr && isArray(probArr)) {
              for (let i = 0; i < probArr.length; i++) {
                probArr[i].show = true;
              }
            }
            const canSendAI2 = localStorage.getItem("canSendAI");
            const graphType2 = props.graphType;

            if (canSendAI2 === "no") {
              controller.abort();
            } else {
              props.setcomparisonObjs({
                whatToCompare: comparerType,
                selectedItems: method,
                metricsToCompare: metric,
                aiObjs: probArr,
                graphType: graphType2 || "line",
                id: props.selectionId,
                zIndex: zIndexValue,
              });
              if (completion === "err") {
                props.setAiError(true);
                if (element) {
                  element.style.opacity = 0;
                  element.style.transform = "translate(100%, 0)";
                  element.style.transition = "none";
                }
                setTimeout(() => {
                  localStorage.removeItem("autoiterationMessage");
                }, 1);
                setTimeout(() => props.setAiError(false), 12000);
              } else {
                setTimeout(() => {
                  localStorage.setItem(
                    "autoiterationMessage",
                    `AI scores ready!`
                  );
                }, 1);
                setTimeout(() => {
                  const element = document.querySelector(
                    "#fetchingInsightsloader3"
                  );

                  const ellipsisElement =
                    element &&
                    element.querySelector(".fetchingInsightsloader3x");
                  ellipsisElement && (ellipsisElement.textContent = "");

                  if (element) {
                    element.style.opacity = 0;
                    element.style.transform = "translate(100%, 0)";
                    element.style.transition = "none";
                  }
                  localStorage.removeItem("autoiterationMessage");
                }, 8000);
              }
            }
            if (completion === "err") {
              props.setAiError(true);
              if (element) {
                element.style.opacity = 0;
                element.style.transform = "translate(100%, 0)";
                element.style.transition = "none";
              }
              setTimeout(() => {
                localStorage.removeItem("autoiterationMessage");
              }, 1);
              setTimeout(() => props.setAiError(false), 12000);
            } else {
            }
            $(window).unbind("beforeunload");
            store.dispatch(setLoaderState({ loading: false }));
            localStorage.removeItem("autoiterationMessage");
            localStorage.setItem("canSendAI", "yes");
          }}
        >
          Generate Comparison
        </button>
      )}
      <PopoverStickOnHover
        placement="bottom"
        delay={300}
        xAdjust={23}
        yAdjust={0}
        keepOpen={false}
        component={<div style={{ color: "#fa798b" }}>Delete Comparison</div>}
        //show={showState}
      >
        <button
          className="deletecomparisonbutton"
          onClick={() => {
            handleDeleteClick(controller);
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </PopoverStickOnHover>
    </div>
  );
};
export default SelectComparison;
