import React, { useRef, useEffect } from "react";
import "./index.scss";
import usePrevious from "../../hooks/usePrevious";

export const Themes = {
  LIGHT: "Light",
  DARK: "Dark",
};

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const getTimeFrame = (selectedTimeFrame) => {
  switch (selectedTimeFrame) {
    case "1-Minute":
      return "1";
    case "5-Minute":
      return "5";
    case "15-Minute":
      return "15";
    case "30-Minute":
      return "30";
    case "1-Hour":
      return "60";
    case "4-Hour":
      return "240";
    case "Daily":
      return "D";
    case "Weekly":
      return "W";
    case "Monthly":
      return "M";
    default:
      return "60";
  }
};

export const AdvancedChart = (props) => {
  const { symbol, datafeed, currentTrade } = props;
  const chartContainerRef = useRef(null);
  const lastSymbol = usePrevious(symbol);
  const tvWidget = useRef(null);
  const timeFrameIn = currentTrade.selectedTimeframe;
  const timeFrame = getTimeFrame(timeFrameIn);

  useEffect(() => {
    const createWidget = () => {
      const widgetOptions = {
        symbol: symbol,
        datafeed: datafeed
          ? datafeed
          : new window.Datafeeds.UDFCompatibleDatafeed(props.datafeedUrl),
        interval: timeFrame ? timeFrame : 60,
        container: chartContainerRef.current,
        library_path: props.libraryPath,
        locale: getLanguageFromURL() || "en",
        disabled_features: ["use_localstorage_for_settings"],
        enabled_features: ["study_templates"],
        charts_storage_url: props.chartsStorageUrl,
        charts_storage_api_version: props.chartsStorageApiVersion,
        client_id: props.clientId,
        user_id: props.userId,
        fullscreen: props.fullscreen,
        autosize: props.autosize,
        theme: Themes.DARK,
        studies_overrides: props.studiesOverrides,
        supported_resolutions: [
          "1",
          "5",
          "15",
          "30",
          "60",
          "240",
          "1D",
          "1W",
          "1M",
        ],
      };
      tvWidget.current = new window.TradingView.widget(widgetOptions);
    };

    const removeWidget = () => {
      if (tvWidget.current) {
        tvWidget.current.remove();
        tvWidget.current = null;
      }
    };

    createWidget();
    return () => {
      removeWidget();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastSymbol !== symbol) {
      const removeWidget = () => {
        if (tvWidget.current) {
          tvWidget.current.remove();
          tvWidget.current = null;
        }
      };

      const createWidget = () => {
        const widgetOptions = {
          symbol: symbol,
          datafeed: datafeed
            ? datafeed
            : new window.Datafeeds.UDFCompatibleDatafeed(props.datafeedUrl),
          interval: timeFrame ? timeFrame : 60,
          container: chartContainerRef.current,
          library_path: props.libraryPath,
          locale: getLanguageFromURL() || "en",
          disabled_features: ["use_localstorage_for_settings"],
          enabled_features: ["study_templates"],
          charts_storage_url: props.chartsStorageUrl,
          charts_storage_api_version: props.chartsStorageApiVersion,
          client_id: props.clientId,
          user_id: props.userId,
          fullscreen: props.fullscreen,
          autosize: props.autosize,
          theme: Themes.DARK,
          studies_overrides: props.studiesOverrides,
          supported_resolutions: [
            "1",
            "5",
            "15",
            "30",
            "60",
            "240",
            "1D",
            "1W",
            "1M",
          ],
        };
        tvWidget.current = new window.TradingView.widget(widgetOptions);
      };

      removeWidget();
      createWidget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]);

  return <div ref={chartContainerRef} className="TVChartContainer" />;
};

AdvancedChart.defaultProps = {
  datafeedUrl: "https://demo_feed.tradingview.com",
  libraryPath: "/charting_library/",
  chartsStorageUrl: "https://saveload.tradingview.com",
  chartsStorageApiVersion: "1.1",
  clientId: "tradingview.com",
  userId: "public_user_id",
  fullscreen: false,
  autosize: true,
  studiesOverrides: {},
};

export default AdvancedChart;
