import { ethers } from "ethers";

// ERC20 ABI with necessary functions and events
const ERC20_ABI = [
  "function decimals() view returns (uint8)",
  "function symbol() view returns (string)",
  "function name() view returns (string)",
  "event Transfer(address indexed from, address indexed to, uint256 value)",
];

class BlockchainDataFeed {
  /**
   * Constructs the BlockchainDataFeed instance.
   * @param {string} symbol - The symbol of the ERC20 token.
   * @param {string} address - The contract address of the ERC20 token.
   * @param {string} startDateTime - ISO string representing the start datetime.
   * @param {string|null} endDateTime - ISO string representing the end datetime. If null, open position logic is applied.
   * @param {number} [numLogs=1000] - Total number of logs to fetch regardless of endDateTime.
   */
  constructor(symbol, address, startDateTime, endDateTime, numLogs = 1000) {
    const ethereum = window.ethereum;
    this.provider = ethereum && new ethers.providers.Web3Provider(ethereum);
    this.erc20Interface = new ethers.utils.Interface(ERC20_ABI);

    if (!this.provider) {
      //console.log("Ethereum provider not found.");
    }

    this.symbol = symbol;
    this.address = address;

    // Convert ISO strings to UNIX timestamps
    this.startTimestamp = this.parseDateTime(startDateTime);
    this.endTimestamp = endDateTime ? this.parseDateTime(endDateTime) : null;

    // Total number of logs to fetch
    this.numLogs = numLogs;
    if (!Number.isInteger(this.numLogs) || this.numLogs <= 0) {
      throw new Error("numLogs must be a positive integer.");
    }

    // Fetch decimals dynamically
    this.decimals = 18; // Default to 18
    this.fetchDecimals();

    // TradingView configuration
    this.config = {
      supported_resolutions: ["1", "5", "15", "30", "60", "D", "W", "M"],
      exchanges: [],
      symbols_types: [],
    };
  }

  /**
   * Fetches the decimals from the ERC20 contract.
   */
  async fetchDecimals() {
    try {
      const contract = new ethers.Contract(
        this.address,
        ERC20_ABI,
        this.provider
      );
      this.decimals = await contract.decimals();
    } catch (error) {
      console.warn(
        "Failed to fetch decimals from the contract. Using default value of 18."
      );
    }
  }

  /**
   * Parses an ISO datetime string to a UNIX timestamp.
   * @param {string} isoString - The ISO datetime string.
   * @returns {number} - The UNIX timestamp in seconds.
   */
  parseDateTime(isoString) {
    const date = new Date(isoString);
    if (isNaN(date.getTime())) {
      throw new Error(`Invalid ISO datetime string: ${isoString}`);
    }
    return Math.floor(date.getTime() / 1000);
  }

  /**
   * TradingView: Config for the data feed
   * @param {function} callback - The callback function to pass the config.
   */
  onReady(callback) {
    setTimeout(() => callback(this.config), 0);
  }

  /**
   * TradingView: Resolve symbol metadata
   * @param {string} symbol - The symbol to resolve.
   * @param {function} onSymbolResolvedCallback - Callback with symbol info.
   * @param {function} onResolveErrorCallback - Callback for errors.
   */
  resolveSymbol(symbol, onSymbolResolvedCallback, onResolveErrorCallback) {
    const symbolInfo = {
      ticker: this.symbol,
      name: this.symbol,
      type: "crypto",
      session: "24x7",
      timezone: "Etc/UTC",
      minmov: 1,
      pricescale: 1000000,
      has_intraday: true,
      has_daily: true,
      supported_resolutions: this.config.supported_resolutions,
      volume_precision: 2,
    };
    setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
  }

  /**
   * TradingView: Fetch historical OHLCV data
   * @param {object} symbolInfo - Symbol information.
   * @param {string} resolution - The resolution for the bars.
   * @param {number} from - Start timestamp.
   * @param {number} to - End timestamp.
   * @param {function} onHistoryCallback - Callback with the OHLCV data.
   * @param {function} onErrorCallback - Callback for errors.
   */
  async getBars(
    symbolInfo,
    resolution,
    from,
    to,
    onHistoryCallback,
    onErrorCallback
  ) {
    try {
      const tokenAddress = this.address;

      let ohlcvData;

      if (this.endTimestamp === null) {
        // Case 1: endDateTime is null, fetch last n logs
        ohlcvData = await this.fetchLastNLogs(
          tokenAddress,
          resolution,
          this.numLogs
        );
      } else {
        // Case 2: endDateTime is provided
        ohlcvData = await this.fetchLogsAroundEndDateTime(
          tokenAddress,
          resolution,
          this.startTimestamp,
          this.endTimestamp,
          this.numLogs
        );
      }

      if (ohlcvData.length === 0) {
        onHistoryCallback([], { noData: true });
        return;
      }

      const bars = ohlcvData.map((bar) => ({
        time: bar.timestamp, // Unix timestamp in milliseconds
        open: bar.open,
        high: bar.high,
        low: bar.low,
        close: bar.close,
        volume: bar.volume,
      }));

      onHistoryCallback(bars, { noData: false });
    } catch (error) {
      console.error("Error fetching OHLCV data:", error);
      onErrorCallback(error);
    }
  }

  /**
   * Fetches the last `n` Transfer logs.
   * @param {string} contractAddress - The ERC20 contract address.
   * @param {string} resolution - The resolution for the bars.
   * @param {number} n - Number of logs to fetch.
   * @returns {Promise<Array>} - Array of OHLCV data.
   */
  async fetchLastNLogs(contractAddress, resolution, n) {
    const latestBlock = await this.provider.getBlock("latest");
    let toBlock = latestBlock.number;
    let fromBlock = Math.max(toBlock - 100000, 0); // Initial estimation: last 100,000 blocks

    let fetchedLogs = [];

    while (fetchedLogs.length < n && fromBlock < toBlock) {
      const logs = await this.fetchLogs(contractAddress, fromBlock, toBlock);
      fetchedLogs = fetchedLogs.concat(logs);

      if (logs.length < 10000) {
        // No more logs in this range
        break;
      }

      // Increase the range exponentially if not enough logs are fetched
      fromBlock = Math.max(fromBlock - 100000, 0);
    }

    // Slice the last `n` logs
    const desiredLogs = fetchedLogs.slice(-n);

    // Calculate OHLCV
    const ohlcvData = this.calculateOHLCV(
      desiredLogs,
      this.getResolutionInSeconds(resolution),
      null,
      null
    );

    return ohlcvData;
  }

  /**
   * Fetches logs around the `endDateTime` based on the total number of logs `n`.
   * @param {string} contractAddress - The ERC20 contract address.
   * @param {string} resolution - The resolution for the bars.
   * @param {number} startTimestamp - Start timestamp in seconds.
   * @param {number} endTimestamp - End timestamp in seconds.
   * @param {number} n - Total number of logs to fetch.
   * @returns {Promise<Array>} - Array of OHLCV data.
   */
  async fetchLogsAroundEndDateTime(
    contractAddress,
    resolution,
    startTimestamp,
    endTimestamp,
    n
  ) {
    const halfLogs = Math.floor(n / 2);
    const remainingLogs = n - halfLogs;

    // Fetch `halfLogs` before endTimestamp
    const beforeLogs = await this.fetchNLogsBefore(
      contractAddress,
      endTimestamp,
      halfLogs
    );

    // Fetch `remainingLogs` after endTimestamp
    let afterLogs = await this.fetchNLogsAfter(
      contractAddress,
      endTimestamp,
      remainingLogs
    );

    // Adjust if afterLogs are less than remainingLogs due to reaching current date
    if (afterLogs.length < remainingLogs) {
      const extra = remainingLogs - afterLogs.length;
      const additionalBeforeLogs = await this.fetchNLogsBefore(
        contractAddress,
        endTimestamp,
        extra
      );
      beforeLogs.unshift(...additionalBeforeLogs); // Add to the beginning
    }

    // If still not enough logs, attempt to fetch more after
    if (beforeLogs.length + afterLogs.length < n) {
      const additionalAfterLogs = await this.fetchNLogsAfter(
        contractAddress,
        endTimestamp,
        n - (beforeLogs.length + afterLogs.length)
      );
      afterLogs.push(...additionalAfterLogs);
    }

    // Combine logs
    const combinedLogs = beforeLogs.concat(afterLogs);

    // Calculate OHLCV
    const ohlcvData = this.calculateOHLCV(
      combinedLogs,
      this.getResolutionInSeconds(resolution),
      startTimestamp,
      endTimestamp
    );

    return ohlcvData;
  }

  /**
   * Fetches `n` Transfer logs before a specific timestamp.
   * @param {string} contractAddress - The ERC20 contract address.
   * @param {number} timestamp - The reference timestamp in seconds.
   * @param {number} n - Number of logs to fetch.
   * @returns {Promise<Array>} - Array of logs.
   */
  async fetchNLogsBefore(contractAddress, timestamp, n) {
    const estimatedBlocks = Math.floor(n / 10); // Assuming ~10 logs per block
    const toBlock = await this.getBlockNumberForTimestamp(timestamp);
    const fromBlock = Math.max(toBlock - estimatedBlocks, 0);

    const logs = await this.fetchLogs(contractAddress, fromBlock, toBlock);
    const filteredLogs = logs.filter(
      (log) => Number(log.timeStamp) <= timestamp
    );

    // Sort logs in ascending order
    filteredLogs.sort((a, b) => Number(a.timeStamp) - Number(b.timeStamp));

    return filteredLogs.slice(-n);
  }

  /**
   * Fetches `n` Transfer logs after a specific timestamp.
   * @param {string} contractAddress - The ERC20 contract address.
   * @param {number} timestamp - The reference timestamp in seconds.
   * @param {number} n - Number of logs to fetch.
   * @returns {Promise<Array>} - Array of logs.
   */
  async fetchNLogsAfter(contractAddress, timestamp, n) {
    const latestBlock = await this.provider.getBlock("latest");
    const fromBlock = await this.getBlockNumberForTimestamp(timestamp);
    const toBlock = latestBlock.number;

    const logs = await this.fetchLogs(contractAddress, fromBlock, toBlock);
    const filteredLogs = logs.filter(
      (log) => Number(log.timeStamp) >= timestamp
    );

    // Sort logs in ascending order
    filteredLogs.sort((a, b) => Number(a.timeStamp) - Number(b.timeStamp));

    return filteredLogs.slice(0, n);
  }

  /**
   * Fetches Transfer logs from a contract between specified blocks using Etherscan API.
   * @param {string} contractAddress - The ERC20 contract address.
   * @param {number} fromBlock - Starting block number.
   * @param {number} toBlock - Ending block number.
   * @returns {Promise<Array>} - Array of logs.
   */
  async fetchLogs(contractAddress, fromBlock, toBlock) {
    const eventSignature =
      "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";
    const APIKEY = process.env.REACT_APP_ETHERSCAN_KEY;

    if (!APIKEY) {
      throw new Error(
        "Etherscan API key is not set in the environment variables."
      );
    }

    try {
      const response = await fetch(
        `https://api.etherscan.io/api?module=logs&action=getLogs&fromBlock=${fromBlock}&toBlock=${toBlock}&address=${contractAddress}&topic0=${eventSignature}&apikey=${APIKEY}`
      );

      const data = await response.json();

      if (data.status !== "1" && data.message !== "No records found") {
        throw new Error(`Etherscan API Error: ${data.message}`);
      }

      const events = data.result;
      console.log(
        `Fetched ${events.length} events from blocks ${fromBlock} to ${toBlock}.`
      );

      return events;
    } catch (error) {
      console.error("Error fetching logs from Etherscan:", error);
      return [];
    }
  }

  /**
   * Fetches the block number closest to the given timestamp using binary search.
   * @param {number} timestamp - The UNIX timestamp in seconds.
   * @returns {Promise<number>} - The block number.
   */
  async getBlockNumberForTimestamp(timestamp) {
    let latestBlock = await this.provider.getBlock("latest");
    let latestTimestamp = latestBlock.timestamp;

    if (timestamp > latestTimestamp) {
      return latestBlock.number;
    }

    let lowerBound = 0;
    let upperBound = latestBlock.number;
    let targetBlock = 0;

    while (lowerBound <= upperBound) {
      const mid = Math.floor((lowerBound + upperBound) / 2);
      const block = await this.provider.getBlock(mid);

      if (!block) {
        throw new Error(`Block not found: ${mid}`);
      }

      if (block.timestamp === timestamp) {
        return mid;
      } else if (block.timestamp < timestamp) {
        lowerBound = mid + 1;
        targetBlock = mid;
      } else {
        upperBound = mid - 1;
      }
    }

    return targetBlock;
  }

  /**
   * Converts resolution to seconds.
   * @param {string} resolution - The resolution string.
   * @returns {number} - The resolution in seconds.
   */
  getResolutionInSeconds(resolution) {
    const resolutionMap = {
      1: 60,
      5: 300,
      15: 900,
      30: 1800,
      60: 3600,
      D: 86400,
      W: 604800,
      M: 2592000,
    };
    return resolutionMap[resolution] || 3600; // Default to 1 hour
  }

  /**
   * Calculates OHLCV data from Transfer events.
   * @param {Array} events - Array of Transfer event logs.
   * @param {number} intervalSeconds - The interval in seconds.
   * @param {number|null} startTimestamp - Start timestamp in seconds. If null, not used.
   * @param {number|null} endTimestamp - End timestamp in seconds. If null, not used.
   * @returns {Array} - Array of OHLCV objects.
   */
  calculateOHLCV(events, intervalSeconds, startTimestamp, endTimestamp) {
    const intervalsMap = new Map();

    events.forEach((event) => {
      const timestamp = Number(event.timeStamp);
      const parsedLog = this.erc20Interface.parseLog(event);
      const value = parsedLog.args.value;

      const amount = parseFloat(ethers.utils.formatUnits(value, this.decimals));

      // Determine the interval
      const intervalStart =
        Math.floor(timestamp / intervalSeconds) * intervalSeconds;

      if (!intervalsMap.has(intervalStart)) {
        intervalsMap.set(intervalStart, {
          timestamp: intervalStart * 1000, // Convert to milliseconds
          open: amount,
          high: amount,
          low: amount,
          close: amount,
          volume: amount,
        });
      } else {
        const interval = intervalsMap.get(intervalStart);
        interval.high = Math.max(interval.high, amount);
        interval.low = Math.min(interval.low, amount);
        interval.close = amount;
        interval.volume += amount;
      }
    });

    // Convert Map to sorted array
    const ohlcvArray = Array.from(intervalsMap.values()).sort(
      (a, b) => a.timestamp - b.timestamp
    );

    return ohlcvArray;
  }

  /**
   * Subscribe to real-time updates (optional).
   * @param {object} symbolInfo - Symbol information.
   * @param {string} resolution - The resolution for the bars.
   * @param {function} onRealtimeCallback - Callback for real-time data.
   * @param {string} subscriberUID - Unique identifier for the subscriber.
   */
  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID) {
    console.log("Subscribing to bars:", symbolInfo, resolution);
    // Implement real-time updates here if needed
  }

  /**
   * Unsubscribe from real-time updates (optional).
   * @param {string} subscriberUID - Unique identifier for the subscriber.
   */
  unsubscribeBars(subscriberUID) {
    console.log("Unsubscribing from bars:", subscriberUID);
    // Implement unsubscription logic here if needed
  }
}

export default BlockchainDataFeed;
