import { ethers, Contract } from "ethers";
import { montharr, weekday } from "../../../arrays/weekandmonth";
import { v4 as uuidv4 } from "uuid";
import { getEndDateTime, getStartDateTime } from "../../getStartorEndDateTime";
import profitLossCalculation from "../../profitLossCalculation";
import { filterByID } from "../../filterByID";
import dollarCostAverage from "../../../functions/dollarCostAvg";
import { handleNANGOOD } from "../../../functions/handleUndefined";

const ethereum = window.ethereum;
const provider = ethereum && new ethers.providers.Web3Provider(ethereum);

const ERC20_ABI = [
  "function decimals() view returns (uint8)",
  "function symbol() view returns (string)",
  "function name() view returns (string)",
  "event Transfer(address indexed from, address indexed to, uint256 value)",
];

const erc20Interface = new ethers.utils.Interface(ERC20_ABI);

// For NFT contracts (ERC721 and ERC1155)
const NFT_ABI = [
  "function name() view returns (string)",
  "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
  "event TransferSingle(address indexed operator, address indexed from, address indexed to, uint256 id, uint256 value)",
  "event TransferBatch(address indexed operator, address indexed from, address indexed to, uint256[] ids, uint256[] values)",
];

const nftInterface = new ethers.utils.Interface(NFT_ABI);

const fixRound = (num) => {
  return Math.round(num * 1e10) / 1e10;
};

const fetchTokenData = async (tokenAddress) => {
  const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
  let symbol = "";
  let decimals = 18;
  try {
    symbol = await tokenContract.symbol();
    decimals = await tokenContract.decimals();
  } catch (e) {
    // Handle error if needed
  }
  return { symbol, decimals };
};

const fetchNFTData = async (tokenAddress) => {
  const nftContract = new ethers.Contract(tokenAddress, NFT_ABI, provider);
  let name = "";
  try {
    name = await nftContract.name();
  } catch (e) {
    // Handle error if needed
  }
  return { name };
};

const convertWeiToETH = (wei) => {
  let ethamount = ethers.utils.formatEther(wei);
  return ethamount;
};

export const parseEtherOrders = async (
  tradesIn,
  idsIn,
  portfolioIn,
  tradedata,
  userId,
  verified,
  addressIn,
  importType
) => {
  let trades = tradesIn;
  let ids = idsIn;
  let portfolio = portfolioIn;
  let changedIds = [];
  const address = addressIn.toLowerCase();
  const WETH_ADDRESS =
    "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2".toLowerCase();

  for (let i = 0; i < tradedata.length; i++) {
    const currentIteration = i + 1;
    if (importType === "initialLink") {
      localStorage.setItem(
        "autoiterationMessage",
        `Importing ${currentIteration}/${tradedata.length} executions...`
      );
    }

    const tx = tradedata[i];
    const hash = tx.hash;
    const isInternal = tx.isInternal;

    if (ids.includes(hash)) {
      continue;
    }

    const timestamp = tx.timeStamp || tx.timestamp;
    const startDateTime = new Date(timestamp * 1000);
    const fromAddress = tx.from.toLowerCase();
    const toAddress = tx.to ? tx.to.toLowerCase() : null;

    // Ensure tx.value is defined
    if (typeof tx.value === "undefined") {
      console.error(`Transaction ${hash} has undefined value`);
      continue;
    }
    const valueWei = ethers.BigNumber.from(tx.value);
    const valueEth = ethers.utils.formatEther(valueWei);

    const receipt = await provider.getTransactionReceipt(hash);
    if (!receipt) {
      console.error(`Receipt not found for transaction ${hash}`);
      continue;
    }

    const gasUsed = receipt.gasUsed;

    // Ensure gasPrice is defined
    const gasPriceHex = tx.gasPrice || tx.effectiveGasPrice;
    if (typeof gasPriceHex === "undefined") {
      console.error(`Transaction ${hash} has undefined gasPrice`);
      // Check if it's an internal transaction first
      if (!isInternal) {
        continue;
      }
    }
    const gasPriceWei = !isInternal
      ? ethers.BigNumber.from(gasPriceHex)
      : ethers.BigNumber.from(0);
    const gasFeeWei = gasUsed.mul(gasPriceWei);
    const gasFeeEth = ethers.utils.formatEther(gasFeeWei);

    const logs = receipt.logs;

    let transfersIn = [];
    let transfersOut = [];
    let allTransfers = [];

    let nftTransfersIn = [];
    let nftTransfersOut = [];
    let suspiciousContracts = [];

    for (let log of logs) {
      let isERC20 = false;
      let isERC721 = false;
      let isERC1155 = false;

      // Try to parse as ERC20 Transfer
      try {
        const parsedLog = erc20Interface.parseLog(log);
        if (parsedLog.name === "Transfer") {
          isERC20 = true;
          const from = parsedLog.args.from.toLowerCase();
          const to = parsedLog.args.to.toLowerCase();
          const value = parsedLog.args.value;
          const tokenAddress = log.address.toLowerCase();

          // Push all transfers
          allTransfers.push({ tokenAddress, to, from, value });

          if (from === address) {
            transfersOut.push({ tokenAddress, to, value });
          }
          if (to === address) {
            transfersIn.push({ tokenAddress, from, value });
          }
        }
      } catch (e) {
        // Not an ERC20 Transfer event, try ERC721
      }

      // Parse Exchange logs for token swaps

      if (!isERC20) {
        // Try to parse as ERC721 Transfer
        try {
          const parsedLog = nftInterface.parseLog(log);
          if (parsedLog.name === "Transfer") {
            isERC721 = true;
            const from = parsedLog.args.from.toLowerCase();
            const to = parsedLog.args.to.toLowerCase();
            const tokenId = parsedLog.args.tokenId;
            const tokenAddress = log.address.toLowerCase();

            if (from === address) {
              nftTransfersOut.push({ tokenAddress, to, tokenId });
            }
            if (to === address) {
              nftTransfersIn.push({ tokenAddress, from, tokenId });
            }
          } else if (parsedLog.name === "TransferSingle") {
            // ERC1155 Single Transfer
            isERC1155 = true;
            const from = parsedLog.args.from.toLowerCase();
            const to = parsedLog.args.to.toLowerCase();
            const id = parsedLog.args.id;
            const value = parsedLog.args.value;
            const tokenAddress = log.address.toLowerCase();

            if (from === address) {
              nftTransfersOut.push({ tokenAddress, to, id, value });
            }
            if (to === address) {
              nftTransfersIn.push({ tokenAddress, from, id, value });
            }
          } else if (parsedLog.name === "TransferBatch") {
            // ERC1155 Batch Transfer
            isERC1155 = true;
            const from = parsedLog.args.from.toLowerCase();
            const to = parsedLog.args.to.toLowerCase();
            const ids = parsedLog.args.ids;
            const values = parsedLog.args.values;
            const tokenAddress = log.address.toLowerCase();

            for (let idx = 0; idx < ids.length; idx++) {
              const id = ids[idx];
              const value = values[idx];
              if (from === address) {
                nftTransfersOut.push({ tokenAddress, to, id, value });
              }
              if (to === address) {
                nftTransfersIn.push({ tokenAddress, from, id, value });
              }
            }
          }
        } catch (e) {
          // Not an ERC721 or ERC1155 Transfer event, skip
        }
      }

      // Check for suspicious contracts (e.g., if the contract has low code size)
      if (!isERC20 && !isERC721 && !isERC1155) {
        const code = await provider.getCode(log.address);
        if (code === "0x") {
          suspiciousContracts.push(log.address);
        }
      }
    }

    let trade = null;
    let openTradesPre = [...trades];
    let openTradesPre2 = [...trades];

    // Handle NFT transfers
    if (nftTransfersIn.length > 0 || nftTransfersOut.length > 0) {
      // Process NFT transfers
      for (let nftTransfer of nftTransfersIn) {
        const { tokenAddress, from, tokenId } = nftTransfer;
        const { name } = await fetchNFTData(tokenAddress);
        const symbol = name || "Unknown NFT";

        trade = {
          entryId: String(hash),
          entry: {
            pictures: ["N/A"],
            symbol: {
              symbols: [symbol],
              pointValue: 0,
            },
            strategy: "",
            selectedConfidence: "",
            selectedEmotion: "",
            selectedPhysical: "",
            selectedMarket: "",
            selectedTimeframe: "",
            selectedMistake: "",
            selectedPortfolio: portfolio.name,
            selectedPortfolioType: "Crypto",
            orderType: "Buy",
            orderNumber: "",
            dayOfWeek: weekday[startDateTime.getDay()],
            monthOfYear: montharr[startDateTime.getMonth()],
            stopLoss: "",
            takeProfit: "",
            profitLoss: 0,
            commissions: 0,
            fees: -parseFloat(gasFeeEth),
            maxAdEx: "",
            maxFavEx: "",
            comments: `Received NFT Token ID: ${tokenId}`,
            multiExecution: [],
            exitExecution: [],
            startDateTime: startDateTime,
            endDateTime: startDateTime,
            tags: suspiciousContracts.length > 0 ? ["Suspicious contract"] : [],
            verifiedLevel: verified,
            idLinks: [],
          },
        };
        trades.push(trade);
        ids.push(hash);
        changedIds.push(hash);
      }

      for (let nftTransfer of nftTransfersOut) {
        const { tokenAddress, to, tokenId } = nftTransfer;
        const { name } = await fetchNFTData(tokenAddress);
        const symbol = name || "Unknown NFT";

        trade = {
          entryId: String(hash),
          entry: {
            pictures: ["N/A"],
            symbol: {
              symbols: [symbol],
              pointValue: 0,
            },
            strategy: "",
            selectedConfidence: "",
            selectedEmotion: "",
            selectedPhysical: "",
            selectedMarket: "",
            selectedTimeframe: "",
            selectedMistake: "",
            selectedPortfolio: portfolio.name,
            selectedPortfolioType: "Crypto",
            orderType: "Sell",
            orderNumber: "",
            dayOfWeek: weekday[startDateTime.getDay()],
            monthOfYear: montharr[startDateTime.getMonth()],
            stopLoss: "",
            takeProfit: "",
            profitLoss: 0,
            commissions: 0,
            fees: -parseFloat(gasFeeEth),
            maxAdEx: "",
            maxFavEx: "",
            comments: `Sent NFT Token ID: ${tokenId}`,
            multiExecution: [],
            exitExecution: [],
            startDateTime: startDateTime,
            endDateTime: startDateTime,
            tags: suspiciousContracts.length > 0 ? ["Suspicious contract"] : [],
            verifiedLevel: verified,
            idLinks: [],
          },
        };
        trades.push(trade);
        ids.push(hash);
        changedIds.push(hash);
      }
      continue; // Skip further processing for NFT transfers
    }

    // Check for internal transactions first
    if (isInternal) {
      const gasPre = convertWeiToETH(String(tx.gas));
      const amountPre = convertWeiToETH(tx.value);
      var startDateTimePre = new Date(tx.timeStamp * 1000);

      trade = {
        entryId: String(hash),
        entry: {
          pictures: ["N/A"],
          symbol: {
            symbols: [],
            pointValue: 0,
          },
          strategy: "",
          selectedConfidence: "",
          selectedEmotion: "",
          selectedPhysical: "",
          selectedMarket: "",
          selectedTimeframe: "",
          selectedMistake: "",
          selectedPortfolio: portfolio.name,
          selectedPortfolioType: "Crypto",
          orderType: "Deposit",
          orderNumber: "",
          dayOfWeek: weekday[startDateTimePre.getDay()],
          monthOfYear: montharr[startDateTimePre.getMonth()],
          stopLoss: "",
          takeProfit: "",
          profitLoss: Number(amountPre),
          commissions: 0,
          fees: -Number(gasPre),
          maxAdEx: "",
          maxFavEx: "",
          comments: "",
          multiExecution: [],
          exitExecution: [],
          startDateTime: startDateTimePre,
          endDateTime: startDateTimePre,
          tags: suspiciousContracts.length > 0 ? ["Suspicious contract"] : [],
          verifiedLevel: verified, // because it was a fully automated trade,
          idLinks: [],
        },
      };
      trades.push(trade);
      ids.push(hash);
      changedIds.push(hash);
    } else {
      if (fromAddress === address && toAddress === address) {
        // Self transfer
        trade = {
          entryId: String(hash),
          entry: {
            pictures: ["N/A"],
            symbol: {
              symbols: [],
              pointValue: 0,
            },
            strategy: "",
            selectedConfidence: "",
            selectedEmotion: "",
            selectedPhysical: "",
            selectedMarket: "",
            selectedTimeframe: "",
            selectedMistake: "",
            selectedPortfolio: portfolio.name,
            selectedPortfolioType: "Crypto",
            orderType: "Self",
            orderNumber: "",
            dayOfWeek: weekday[startDateTime.getDay()],
            monthOfYear: montharr[startDateTime.getMonth()],
            stopLoss: "",
            takeProfit: "",
            profitLoss: 0,
            commissions: 0,
            fees: -parseFloat(gasFeeEth),
            maxAdEx: "",
            maxFavEx: "",
            comments: "",
            multiExecution: [],
            exitExecution: [],
            startDateTime: startDateTime,
            endDateTime: startDateTime,
            tags: suspiciousContracts.length > 0 ? ["Suspicious contract"] : [],
            verifiedLevel: verified,
            idLinks: [],
          },
        };
        trades.push(trade);
        ids.push(hash);
        changedIds.push(hash);
      } else {
        if (transfersIn.length === 0 && transfersOut.length === 0) {
          if (valueWei.gt(0)) {
            if (toAddress === address) {
              // ETH deposit
              trade = {
                entryId: String(hash),
                entry: {
                  pictures: ["N/A"],
                  symbol: {
                    symbols: [],
                    pointValue: 0,
                  },
                  strategy: "",
                  selectedConfidence: "",
                  selectedEmotion: "",
                  selectedPhysical: "",
                  selectedMarket: "",
                  selectedTimeframe: "",
                  selectedMistake: "",
                  selectedPortfolio: portfolio.name,
                  selectedPortfolioType: "Crypto",
                  orderType: "Deposit",
                  orderNumber: "",
                  dayOfWeek: weekday[startDateTime.getDay()],
                  monthOfYear: montharr[startDateTime.getMonth()],
                  stopLoss: "",
                  takeProfit: "",
                  profitLoss: parseFloat(valueEth),
                  commissions: 0,
                  fees: -parseFloat(gasFeeEth),
                  maxAdEx: "",
                  maxFavEx: "",
                  comments: "",
                  multiExecution: [],
                  exitExecution: [],
                  startDateTime: startDateTime,
                  endDateTime: startDateTime,
                  tags:
                    suspiciousContracts.length > 0
                      ? ["Suspicious contract"]
                      : [],
                  verifiedLevel: verified,
                  idLinks: [],
                },
              };
              trades.push(trade);
              ids.push(hash);
              changedIds.push(hash);
            } else if (fromAddress === address) {
              // ETH withdrawal
              trade = {
                entryId: String(hash),
                entry: {
                  pictures: ["N/A"],
                  symbol: {
                    symbols: [],
                    pointValue: 0,
                  },
                  strategy: "",
                  selectedConfidence: "",
                  selectedEmotion: "",
                  selectedPhysical: "",
                  selectedMarket: "",
                  selectedTimeframe: "",
                  selectedMistake: "",
                  selectedPortfolio: portfolio.name,
                  selectedPortfolioType: "Crypto",
                  orderType: "Withdrawal",
                  orderNumber: "",
                  dayOfWeek: weekday[startDateTime.getDay()],
                  monthOfYear: montharr[startDateTime.getMonth()],
                  stopLoss: "",
                  takeProfit: "",
                  profitLoss: -parseFloat(valueEth),
                  commissions: 0,
                  fees: -parseFloat(gasFeeEth),
                  maxAdEx: "",
                  maxFavEx: "",
                  comments: "",
                  multiExecution: [],
                  exitExecution: [],
                  startDateTime: startDateTime,
                  endDateTime: startDateTime,
                  tags:
                    suspiciousContracts.length > 0
                      ? ["Suspicious contract"]
                      : [],
                  verifiedLevel: verified,
                  idLinks: [],
                },
              };
              trades.push(trade);
              ids.push(hash);
              changedIds.push(hash);
            } else {
              // Possible Wrap transaction (e.g., wrapping ETH to WETH)
              const isWrap = (await provider.getCode(toAddress)) !== "0x";
              if (isWrap) {
                // Handle Wrap transaction
                trade = {
                  entryId: String(hash),
                  entry: {
                    pictures: ["N/A"],
                    symbol: {
                      symbols: ["WETHETH"],
                      pointValue: 0,
                    },
                    strategy: "",
                    selectedConfidence: "",
                    selectedEmotion: "",
                    selectedPhysical: "",
                    selectedMarket: "",
                    selectedTimeframe: "",
                    selectedMistake: "",
                    selectedPortfolio: portfolio.name,
                    selectedPortfolioType: "Crypto",
                    orderType: "Wrap",
                    orderNumber: "",
                    dayOfWeek: weekday[startDateTime.getDay()],
                    monthOfYear: montharr[startDateTime.getMonth()],
                    stopLoss: "",
                    takeProfit: "",
                    profitLoss: 0,
                    commissions: 0,
                    fees: -parseFloat(gasFeeEth),
                    maxAdEx: "",
                    maxFavEx: "",
                    comments: "",
                    multiExecution: [],
                    exitExecution: [],
                    startDateTime: startDateTime,
                    endDateTime: startDateTime,
                    tags:
                      suspiciousContracts.length > 0
                        ? ["Suspicious contract"]
                        : [],
                    verifiedLevel: verified,
                    idLinks: [],
                  },
                };
                trades.push(trade);
                ids.push(hash);
                changedIds.push(hash);
              } else {
                // Other types of transactions
                // Handle accordingly or skip
              }
            }
          } else {
            // Possibly an approval transaction
            trade = {
              entryId: String(hash),
              entry: {
                pictures: ["N/A"],
                symbol: {
                  symbols: [],
                  pointValue: 0,
                },
                strategy: "",
                selectedConfidence: "",
                selectedEmotion: "",
                selectedPhysical: "",
                selectedMarket: "",
                selectedTimeframe: "",
                selectedMistake: "",
                selectedPortfolio: portfolio.name,
                selectedPortfolioType: "Crypto",
                orderType: "Approval",
                orderNumber: "",
                dayOfWeek: weekday[startDateTime.getDay()],
                monthOfYear: montharr[startDateTime.getMonth()],
                stopLoss: "",
                takeProfit: "",
                profitLoss: 0,
                commissions: 0,
                fees: -parseFloat(gasFeeEth),
                maxAdEx: "",
                maxFavEx: "",
                comments: "",
                multiExecution: [],
                exitExecution: [],
                startDateTime: startDateTime,
                endDateTime: startDateTime,
                tags:
                  suspiciousContracts.length > 0 ? ["Suspicious contract"] : [],
                verifiedLevel: verified,
                idLinks: [],
              },
            };
            trades.push(trade);
            ids.push(hash);
            changedIds.push(hash);
          }
        } else {
          // Handling token transfers and swaps
          // For ERC20 to ERC20 swaps where tx.value is zero,
          // we need to extract the ETH value from the logs (e.g., WETH transfers)
          let ethSpent = 0;
          for (let transferOut of transfersOut) {
            const tokenAddress = transferOut.tokenAddress;
            const value = transferOut.value;
            if (
              tokenAddress.toLowerCase() ===
              "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2".toLowerCase()
            ) {
              // WETH address on Ethereum mainnet
              const amountFormatted = ethers.utils.formatEther(value);
              ethSpent += parseFloat(amountFormatted);
            }
          }
          // Similarly, calculate ETH received
          let ethReceived = 0;
          for (let transferIn of transfersIn) {
            const tokenAddress = transferIn.tokenAddress;
            const value = transferIn.value;
            if (
              tokenAddress.toLowerCase() ===
              "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2".toLowerCase()
            ) {
              // WETH address on Ethereum mainnet
              const amountFormatted = ethers.utils.formatEther(value);
              ethReceived += parseFloat(amountFormatted);
            }
          }
          // The net ETH involved is ethSpent - ethReceived
          const netEth = ethSpent - ethReceived;

          // Before the transfersOut loop, initialize totalEthOut and an array to collect transfer data
          let totalEthOut = 0;
          let transfersOutData = [];

          if (transfersOut.length > 0) {
            for (let transferOut of transfersOut) {
              const tokenAddress = transferOut.tokenAddress;
              const amount = transferOut.value;
              const { symbol, decimals } = await fetchTokenData(tokenAddress);
              const amountFormatted = ethers.utils.formatUnits(
                amount,
                decimals
              );

              const orderType = "Short";
              const newsymbol = symbol + "ETH";

              const relevantLotSize = parseFloat(amountFormatted);
              totalEthOut += relevantLotSize;

              // Collect transfer data for processing after calculating relevantPrice
              transfersOutData.push({
                orderType,
                newsymbol,
                relevantLotSize,
                address,
              });
            }

            // After processing all transfersOut, calculate relevantPrice using totalEthOut
            let relevantPrice = 0;

            if (Number(netEth) !== 0) {
              relevantPrice = Math.abs(netEth) / totalEthOut;
            } else {
              // If netEth is zero, attempt to calculate relevantPrice using WETH transfers
              for (let transfer of allTransfers) {
                const tokenAddress2 = transfer.tokenAddress;
                const value = transfer.value;
                if (
                  tokenAddress2.toLowerCase() === WETH_ADDRESS.toLowerCase()
                ) {
                  const amountFormatted2 = ethers.utils.formatEther(value);
                  relevantPrice =
                    Math.abs(Number(amountFormatted2)) / totalEthOut;
                  break;
                }
              }
            }

            // Now process each transferOut using the calculated relevantPrice
            for (let data of transfersOutData) {
              const { orderType, newsymbol, relevantLotSize, address } = data;

              // Check for Wrap transactions
              if (newsymbol === "WETHETH") {
                continue;
              }

              let executionsArray = [
                {
                  id: uuidv4(),
                  lotSize: relevantLotSize,
                  entryPrice: relevantPrice,
                  startDateTime: startDateTime,
                  expectedEntry: "",
                  strikePrice: "",
                  expirationDate: "",
                  legType: "",
                  misctype: relevantPrice === 0 ? "Deposit" : "",
                },
              ];

              let openTradesMatch = openTradesPre.filter((trade) =>
                filterByID(trade, false, newsymbol)
              );
              let openTradesNOTMatch = openTradesPre2.filter((trade) =>
                filterByID(trade, true, newsymbol)
              );

              if (!openTradesMatch.length) {
                // Open a new trade
                trade = {
                  entryId: String(hash),
                  entry: {
                    pictures: ["N/A"],
                    symbol: {
                      symbols: [newsymbol],
                      pointValue: 0,
                    },
                    strategy: "",
                    selectedConfidence: "",
                    selectedEmotion: "",
                    selectedPhysical: "",
                    selectedMarket: "",
                    selectedTimeframe: "",
                    selectedMistake: "",
                    selectedPortfolio: portfolio.name,
                    selectedPortfolioType: "Crypto",
                    orderType: orderType,
                    orderNumber: "",
                    dayOfWeek: weekday[startDateTime.getDay()],
                    monthOfYear: montharr[startDateTime.getMonth()],
                    stopLoss: "",
                    takeProfit: "",
                    profitLoss: 0,
                    commissions: 0,
                    fees: -parseFloat(gasFeeEth),
                    maxAdEx: "",
                    maxFavEx: "",
                    comments: "",
                    multiExecution: executionsArray,
                    exitExecution: [],
                    startDateTime: startDateTime,
                    endDateTime: "",
                    tags:
                      suspiciousContracts.length > 0
                        ? ["Suspicious contract"]
                        : [],
                    verifiedLevel: verified,
                    idLinks: [],
                    contractAddress: address,
                  },
                };
                trades.push(trade);
                openTradesPre = trades;
                openTradesPre2 = trades;
              } else {
                // Update existing trade
                const openTrade = openTradesMatch[0];
                const openTradeEntry = openTrade?.entry;
                const opentradeOrderType = openTrade?.entry.orderType;
                const opentradeSymbol = openTrade?.entry.symbol;
                const opentradeFees = openTrade?.entry.fees;
                const opentrademultiExecution =
                  openTrade?.entry.multiExecution || [];
                const opentradeexitExecution =
                  openTrade?.entry.exitExecution || [];

                if (orderType !== opentradeOrderType) {
                  let execsBeforei = opentrademultiExecution.filter(
                    (exec) =>
                      new Date(exec.startDateTime) < new Date(startDateTime)
                  );

                  const psuedoPrice = handleNANGOOD(
                    dollarCostAverage(execsBeforei, "entry", orderType),
                    0
                  );

                  // Closing the trade
                  let exitexecutionPRE = {
                    id: uuidv4(),
                    exitLotSize: relevantLotSize,

                    // If the price from relevantPrice is zero, then its a withdrawal of the token,
                    // and we need to calculate the avg price of buys in prior to the withdrawal
                    exitPrice:
                      relevantPrice === 0 ? psuedoPrice : relevantPrice,
                    endDateTime: startDateTime,
                    expectedExit: "",
                    exitstrikePrice: "",
                    exitexpirationDate: "",
                    exitlegType: "",
                    equityComponents: [],
                    exercised: "",
                    misctype: relevantPrice === 0 ? "Withdrawal" : "",
                  };
                  let newExitExecutionPRE = [
                    ...opentradeexitExecution,
                    exitexecutionPRE,
                  ];

                  let entryLots = 0;
                  opentrademultiExecution.forEach((exec) => {
                    entryLots += Number(exec.lotSize);
                  });

                  let exitLots = 0;
                  newExitExecutionPRE.forEach((exec) => {
                    exitLots += Number(exec.exitLotSize);
                  });

                  entryLots = fixRound(entryLots);
                  exitLots = fixRound(exitLots);

                  let startDateTimez = new Date(
                    getStartDateTime(opentrademultiExecution)
                  );
                  let endDateTimez = new Date(
                    getEndDateTime(newExitExecutionPRE)
                  );

                  let finalFees =
                    parseFloat(opentradeFees) - parseFloat(gasFeeEth);

                  const profitLoss = Number(
                    profitLossCalculation(
                      opentrademultiExecution,
                      newExitExecutionPRE,
                      opentradeOrderType,
                      "Crypto",
                      opentradeSymbol.pointValue,
                      false
                    )
                  );

                  const entry = Object.assign({}, openTradeEntry, {
                    exitExecution: newExitExecutionPRE,
                    profitLoss: profitLoss,
                    endDateTime: endDateTimez,
                    startDateTime: startDateTimez,
                    fees: finalFees,
                  });

                  const closedTrade = Object.assign({}, openTrade, {
                    entry: entry,
                  });

                  openTradesNOTMatch.push(closedTrade);
                  trades = openTradesNOTMatch;
                  openTradesPre = openTradesNOTMatch;
                  openTradesPre2 = openTradesNOTMatch;
                } else {
                  // Adding to existing position
                  const opentradeFeesin = parseFloat(opentradeFees);
                  const opentrademultiExecutionNew = [
                    ...opentrademultiExecution,
                    ...executionsArray,
                  ];
                  const entry = Object.assign({}, openTradeEntry, {
                    multiExecution: opentrademultiExecutionNew,
                    fees: opentradeFeesin - parseFloat(gasFeeEth),
                  });
                  const updatedTrade = Object.assign({}, openTrade, {
                    entry: entry,
                  });
                  openTradesNOTMatch.push(updatedTrade);
                  trades = openTradesNOTMatch;
                  openTradesPre = openTradesNOTMatch;
                  openTradesPre2 = openTradesNOTMatch;
                }
              }
              ids.push(hash);
              changedIds.push(hash);
            }
          }

          // Before the transfersIn loop, initialize totalEthIn and an array to collect transfer data
          let totalEthIn = 0;
          let transfersInData = [];

          if (transfersIn.length > 0) {
            for (let transferIn of transfersIn) {
              const tokenAddress = transferIn.tokenAddress;
              const amount = transferIn.value;
              const { symbol, decimals } = await fetchTokenData(tokenAddress);
              const amountFormatted = ethers.utils.formatUnits(
                amount,
                decimals
              );

              const orderType = "Long";
              const newsymbol = symbol + "ETH";

              const relevantLotSize = parseFloat(amountFormatted);
              totalEthIn += relevantLotSize;

              // Collect transfer data for processing after calculating relevantPrice
              transfersInData.push({
                tokenAddress,
                amount,
                symbol,
                decimals,
                amountFormatted,
                orderType,
                newsymbol,
                relevantLotSize,
              });
            }

            // After processing all transfersIn, calculate relevantPrice using totalEthIn
            let relevantPrice = 0;
            if (Number(netEth) !== 0) {
              relevantPrice = Math.abs(Number(netEth)) / totalEthIn;
            } else {
              // If netEth is zero, attempt to calculate relevantPrice using WETH transfers
              for (let transfer of allTransfers) {
                const tokenAddress = transfer.tokenAddress;
                const value = transfer.value;
                if (tokenAddress.toLowerCase() === WETH_ADDRESS.toLowerCase()) {
                  const amountFormatted = ethers.utils.formatEther(value);
                  relevantPrice =
                    Math.abs(Number(amountFormatted)) / totalEthIn;
                  break;
                }
              }
            }

            // Now process each transferIn using the calculated relevantPrice
            for (let data of transfersInData) {
              const {
                tokenAddress,
                amount,
                symbol,
                decimals,
                amountFormatted,
                orderType,
                newsymbol,
                relevantLotSize,
              } = data;

              // Check for Unwrap transactions
              if (newsymbol === "WETHETH") {
                // Handle Unwrap transaction
                continue;
              }

              let executionsArray = [
                {
                  id: uuidv4(),
                  lotSize: relevantLotSize,
                  entryPrice: relevantPrice,
                  startDateTime: startDateTime,
                  expectedEntry: "",
                  strikePrice: "",
                  expirationDate: "",
                  legType: "",
                  misctype: relevantPrice === 0 ? "Deposit" : "",
                },
              ];

              let openTradesMatch = openTradesPre.filter((trade) =>
                filterByID(trade, false, newsymbol)
              );
              let openTradesNOTMatch = openTradesPre2.filter((trade) =>
                filterByID(trade, true, newsymbol)
              );

              if (!openTradesMatch.length) {
                // Open a new trade
                trade = {
                  entryId: String(hash),
                  entry: {
                    pictures: ["N/A"],
                    symbol: {
                      symbols: [newsymbol],
                      pointValue: 0,
                    },
                    strategy: "",
                    selectedConfidence: "",
                    selectedEmotion: "",
                    selectedPhysical: "",
                    selectedMarket: "",
                    selectedTimeframe: "",
                    selectedMistake: "",
                    selectedPortfolio: portfolio.name,
                    selectedPortfolioType: "Crypto",
                    orderType: orderType,
                    orderNumber: "",
                    dayOfWeek: weekday[startDateTime.getDay()],
                    monthOfYear: montharr[startDateTime.getMonth()],
                    stopLoss: "",
                    takeProfit: "",
                    profitLoss: 0,
                    commissions: 0,
                    fees: -parseFloat(gasFeeEth),
                    maxAdEx: "",
                    maxFavEx: "",
                    comments: "",
                    multiExecution: executionsArray,
                    exitExecution: [],
                    startDateTime: startDateTime,
                    endDateTime: "",
                    tags:
                      suspiciousContracts.length > 0
                        ? ["Suspicious contract"]
                        : [],
                    verifiedLevel: verified,
                    idLinks: [],
                    contractAddress: tokenAddress,
                  },
                };
                trades.push(trade);
                openTradesPre = trades;
                openTradesPre2 = trades;
              } else {
                // Update existing trade
                const openTrade = openTradesMatch[0];
                const openTradeEntry = openTrade?.entry;
                const opentradeOrderType = openTrade?.entry.orderType;
                const opentradeSymbol = openTrade?.entry.symbol;
                const opentradeFees = openTrade?.entry.fees;
                const opentrademultiExecution =
                  openTrade?.entry.multiExecution || [];
                const opentradeexitExecution =
                  openTrade?.entry.exitExecution || [];

                if (orderType !== opentradeOrderType) {
                  // Closing the trade

                  let execsBefore = opentrademultiExecution.filter(
                    (exec) =>
                      new Date(exec.startDateTime) < new Date(startDateTime)
                  );
                  const psuedoPrice = handleNANGOOD(
                    dollarCostAverage(execsBefore, "entry", orderType),
                    0
                  );

                  let exitexecutionPRE = {
                    id: uuidv4(),
                    exitLotSize: relevantLotSize,
                    exitPrice:
                      relevantPrice === 0 ? psuedoPrice : relevantPrice,
                    endDateTime: startDateTime,
                    expectedExit: "",
                    exitstrikePrice: "",
                    exitexpirationDate: "",
                    exitlegType: "",
                    equityComponents: [],
                    exercised: "",
                    misctype: relevantPrice === 0 ? "Withdrawal" : "",
                  };
                  let newExitExecutionPRE = [
                    ...opentradeexitExecution,
                    exitexecutionPRE,
                  ];

                  let entryLots = 0;
                  opentrademultiExecution.forEach((exec) => {
                    entryLots += Number(exec.lotSize);
                  });

                  let exitLots = 0;
                  newExitExecutionPRE.forEach((exec) => {
                    exitLots += Number(exec.exitLotSize);
                  });

                  entryLots = fixRound(entryLots);
                  exitLots = fixRound(exitLots);

                  let startDateTimez = new Date(
                    getStartDateTime(opentrademultiExecution)
                  );
                  let endDateTimez = new Date(
                    getEndDateTime(newExitExecutionPRE)
                  );

                  let finalFees =
                    parseFloat(opentradeFees) - parseFloat(gasFeeEth);

                  const profitLoss = Number(
                    profitLossCalculation(
                      opentrademultiExecution,
                      newExitExecutionPRE,
                      opentradeOrderType,
                      "Crypto",
                      opentradeSymbol.pointValue,
                      false
                    )
                  );

                  const entry = Object.assign({}, openTradeEntry, {
                    exitExecution: newExitExecutionPRE,
                    profitLoss: profitLoss,
                    endDateTime: endDateTimez,
                    startDateTime: startDateTimez,
                    fees: finalFees,
                  });

                  const closedTrade = Object.assign({}, openTrade, {
                    entry: entry,
                  });

                  openTradesNOTMatch.push(closedTrade);
                  trades = openTradesNOTMatch;
                  openTradesPre = openTradesNOTMatch;
                  openTradesPre2 = openTradesNOTMatch;
                } else {
                  // Adding to existing position
                  const opentradeFeesin = parseFloat(opentradeFees);
                  const opentrademultiExecutionNew = [
                    ...opentrademultiExecution,
                    ...executionsArray,
                  ];
                  const entry = Object.assign({}, openTradeEntry, {
                    multiExecution: opentrademultiExecutionNew,
                    fees: opentradeFeesin - parseFloat(gasFeeEth),
                  });
                  const updatedTrade = Object.assign({}, openTrade, {
                    entry: entry,
                  });
                  openTradesNOTMatch.push(updatedTrade);
                  trades = openTradesNOTMatch;
                  openTradesPre = openTradesNOTMatch;
                  openTradesPre2 = openTradesNOTMatch;
                }
              }
              ids.push(hash);
              changedIds.push(hash);
            }
          }
        }
      }
    }
  }

  return { trades: trades, ids: ids, changedIds: changedIds };
};

export default parseEtherOrders;
