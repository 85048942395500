import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import CreatableSelect from "react-select/creatable";
import { v4 as uuidv4 } from "uuid";
import dropdownStyles from "../../functions/dropdownStyles";
import { theme } from "../../components/selectTheme";
import {
  faRedo,
  faPlus,
  faTrashAlt,
  faPlay,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import ComparisonGraph from "./ComparisonGraph";
import generateRandomColors from "../../functions/generateRandomColors";
import filterTheTrades from "./filterTheTrades";
import PopoverStickOnHover from "../../components/PopoverStickOnHover";
import AIGraph from "./AIGraph";
import RadarGraph from "./RadarGraph";
import goldbrain from "./../../images/goldbrain2.png";
import Modal from "react-modal";
function getRandomColor(colors) {
  return colors[Math.floor(Math.random() * colors.length)];
}
function addColorsToArray(colors, selectedItemsLength) {
  const newColorArray = [];
  const colorsLength = colors.length;

  for (let i = 0; i < selectedItemsLength; i++) {
    const colorIndex = i % colorsLength;
    newColorArray.push(colors[colorIndex]);
  }

  return newColorArray;
}
const colorINITNEW = [
  "#444b99",
  "#fbbf54",
  "#15c286",
  "#ec0f47",
  "#003ba7",
  "#c93875",
  "#ee6b3b",
  "#abd96d",
  "#53448a",
  "#079ba6",
  "#c731b0",
  "#087353",
];
const ComparisonReport = (props) => {
  let wrapperId = uuidv4();
  const comparisonId = props.comparisonId;
  const portfolio = props.processedData.portfolio;
  const selectComparisons = props.whatToCompare;
  const aiObjsPre = props.aiObjs;
  const whatToCompare = selectComparisons.label;
  const whatToCompareProp = selectComparisons.prop;
  const selectedItemsPre = props.selectedItems;
  const metricsToCompare = props.metricsToCompare;
  const allEntriesButFiltered = portfolio.entries; // used in every other comparison
  const globalCalculations = props.globalCalculations;
  const riskFreeRate = globalCalculations.riskFreeRate;
  const graphType = props.graphType;
  const createdPortfolios = props.allData.createdPortfolios;
  const allEntries = createdPortfolios.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.entries);
  }, []);

  // Can be either Line, Matrix, or Bar
  const [graphState, setgraphState] = useState(graphType);
  const [aiObjs, setaiObjs] = useState(aiObjsPre);
  const [selectedItemsToggler, setselectedItemsToggler] = useState(1);
  const [modalIsOpen, setIsOpen2] = useState(false);

  const [selectedItems, setselectedItems] = useState(selectedItemsPre);
  const [selectedItemsMapped, setselectedItemsMapped] = useState(
    Object.fromEntries(selectedItemsPre.map((item) => [item.label, false]))
  );

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const toggleDropdown = useCallback(() => setIsOpen((prev) => !prev), []);

  const onEllipsisClick = (e) => {
    e.stopPropagation();
    toggleDropdown();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (props.aiObjs) {
      setaiObjs(props.aiObjs);
    }
  }, [props.aiObjs]); // Only re-run this effect when props.aiObjs changes


  const handleDeleteClick = () => {
    //props.setzIndexValue(props.zIndexValue + 1);
    props.deletecomparisonObjs(comparisonId);
  };
  const colorINIT = useMemo(() => {
    return generateRandomColors(selectedItems.length);
  }, [metricsToCompare]);

  // Create array of Portfolio colors from the Portfolio setting
  const portfolioColors = useMemo(() => {
    return selectedItemsPre.map((portfolio) => {
      const matchingPort = createdPortfolios.find(
        (port) => port.name === portfolio.label
      );
      const randomColor = "#" + parseInt(Math.random() * 0xffffff).toString(16);
      const returnObj = matchingPort
        ? !matchingPort?.settings.portfolioColor
          ? randomColor // Random color
          : matchingPort?.settings.portfolioColor
        : null;
      return returnObj;
    });
  }, [metricsToCompare]); // dependencies that trigger recalculation of portfolioColors

  const randomColors = useMemo(() => {
    return addColorsToArray(colorINIT, selectedItems.length);
  }, [metricsToCompare]); // dependencies that trigger recalculation of randomColors

  const lineColorMain = useMemo(() => {
    return whatToCompare === "Portfolios" ? portfolioColors : randomColors;
  }, [metricsToCompare]); // Recalculate only if whatToCompare changes

  const valuesArray = Object.entries(selectedItemsMapped).map(
    ([key, value]) => !value && { label: key, value: key }
  );
  const toggleGraphState = () => {
    if (graphState === "line") {
      props.togglecomparisonObjs(comparisonId, "bar");
      setgraphState("bar");
    } else {
      props.togglecomparisonObjs(comparisonId, "line");
      setgraphState("line");
    }
  };
  const NewlineText = ({ text }) => {
    const lines = text.split(/\\n|\n/).map((line, i) => {
      // Replace dash at the beginning of the line with unicode character
      const modifiedLine = line.replace(/^-/, "⚡");
      return (
        <React.Fragment key={i}>
          {modifiedLine}
          <br />
        </React.Fragment>
      );
    });
    return <div>{lines}</div>;
  };

  const metricsCompareElem = metricsToCompare.map((metric) => (
    <ComparisonGraph
      key={metric.label}
      selectComparisons={selectComparisons}
      selectedItems={valuesArray}
      metricToCompare={metric.label}
      userData={portfolio.entries}
      defaultSymbol={String(portfolio.settings.currencySymbol)}
      type={graphState}
      userSettings={portfolio.settings}
      lineColors={lineColorMain}
      riskFreeRate={riskFreeRate}
      allEntries={allEntries}
      setselectedItemsToggler={(e) => setselectedItemsToggler(e)}
      processedData={props.processedData}
      metricsToCompare={metricsToCompare}
    />
  ));
  const selectedItemsElem = selectedItems.map((item, index) => {
    const selectedItem = String(item.label);

    //First filter by the selected item
    /// calulcate the number of trades in every category here
    let filteredTrades = "";
    let filteredTradesPre =
      whatToCompare === "Portfolios"
        ? allEntries.slice()
        : allEntriesButFiltered.slice();
    filteredTrades = filterTheTrades(
      whatToCompare,
      whatToCompareProp,
      selectedItem,
      filteredTradesPre
    );

    let totalTrades = 0;
    for (let i = 0, j = filteredTrades.length; i < j; i++) {
      if (
        filteredTrades[i].entry.orderType === "Deposit" ||
        filteredTrades[i].entry.orderType === "Withdrawal" ||
        filteredTrades[i].entry.orderType === "Funding Payment" ||
        filteredTrades[i].entry.orderType === "Commit" ||
        filteredTrades[i].entry.orderType === "Approval" ||
        filteredTrades[i].entry.orderType === "Wrap" ||
        filteredTrades[i].entry.orderType === "Self"
      ) {
        continue;
      } else {
        totalTrades += 1;
      }
    }

    /*     const handleToggleItem = (item) => {
      setselectedItemsMapped((prev) => ({
        ...prev,
        [item.label]: !prev[item.label],
      }));
    }; */

    const elementStyle = {
      backgroundColor: !selectedItemsMapped[selectedItem]
        ? lineColorMain[index]
        : "transparent",
      border: !selectedItemsMapped[selectedItem]
        ? `1px solid transparent`
        : `1px solid ${lineColorMain[index]}`,
      color: !selectedItemsMapped[selectedItem] ? "#14181e" : `#556171`,
    };

    return (
      <div
        key={selectedItem + index}
        disabled={selectedItemsMapped[selectedItem]}
        onClick={(e) => {
          e.stopPropagation(); // Add this line
          if (!selectedItemsMapped[selectedItem]) {
            setselectedItemsMapped((prevState) => ({
              ...prevState,
              [selectedItem]: true,
            }));

            let aiObjsPre3 = aiObjs.slice();
            const updatedObjects = aiObjsPre3.map((obj) => {
              if (obj.category === selectedItem) {
                return {
                  ...obj,
                  show: false,
                };
              }
              return obj;
            });
            setaiObjs(updatedObjects);
          } else {
            setselectedItemsMapped((prevState) => ({
              ...prevState,
              [selectedItem]: false,
            }));

            let aiObjsPre32 = aiObjs.slice();
            const updatedObjects = aiObjsPre32.map((obj) => {
              if (obj.category === selectedItem) {
                return {
                  ...obj,
                  show: true,
                };
              }
              return obj;
            });
            setaiObjs(updatedObjects);
          }
        }}
        style={elementStyle}
        className={`comparerReportInfo_selectedItemWrapper noselect`}
      >
        <div>
          <b>{selectedItem}</b>
        </div>
        <div>{totalTrades === 1 ? "1 trade" : `${totalTrades} trades`}</div>
      </div>
    );
  });

  const regenerateAIAnalysis = (id) => {};
  const aigraphMemo = useMemo(() => {
    return (
      <AIGraph
        selectComparisons={selectComparisons}
        selectedItems={valuesArray}
        userData={portfolio.entries}
        defaultSymbol={String(portfolio.settings.currencySymbol)}
        type={graphState}
        userSettings={portfolio.settings}
        lineColors={lineColorMain}
        metricsToCompare={metricsToCompare}
        aiObjs={aiObjs}
        allEntries={allEntries}
      />
    );
  }, [aiObjs, selectComparisons, props]);
  return (
    <div
      className={`comparerReportWrapper`}
      id={`comparerReportWrapper${comparisonId}`}
      key={`comparerReportWrapper${comparisonId}`}
      style={{ zIndex: props.zIndexValue }}
    >
      {/*       <Modal
        ariaHideApp={false}
        id={"TrialExpiredModal"}
        className={"TrialExpiredModal"}
        isOpen={modalIsOpen}
        closeTimeoutMS={200}
        contentLabel="Trade"
        overlayClassName="OverlayModal"
        onRequestClose={setIsOpen2(false)}
      >
        <ComparisonGraph
          key={uuidv4()}
          selectComparisons={selectComparisons}
          selectedItems={valuesArray}
          metricToCompare={selectedItemsToggler}
          userData={portfolio.entries}
          defaultSymbol={String(portfolio.settings.currencySymbol)}
          type={graphState}
          userSettings={portfolio.settings}
          lineColors={lineColorMain}
          riskFreeRate={riskFreeRate}
          allEntries={allEntries}
          processedData={props.processedData}
          metricsToCompare={metricsToCompare}
        />
      </Modal> */}
      <div className={`comparerReportInfoWrapper`}>
        <div className={`comparerReportInfo_ComparingWrapper`}>
          <div className={`comparerReportInfo_Comparingheader`}>Comparing:</div>
          <div className={`comparerReportInfo_Comparingtext`}>
            {whatToCompare}
          </div>
        </div>
        {/*         <div className={`regenerateAIbutton`} onClick={() => {}}>
          Regenerate
        </div> */}
        <div className={`comparerReportInfo_selectedWrapper`}>
          {selectedItemsElem}
        </div>{" "}
        {/*         <div className={`image-letter`}>
          <img className={`image-letter-image`} alt="0" src={goldbrain} />
        </div> */}
        {/*         {
          <div
            className={`regenerateAI-button`}
            onClick={() => {
              regenerateAIAnalysis(comparisonId);
            }}
          >
            <div>Regenerate</div>
          </div>
        } */}
        {/*         <div className={`comparerReportInfo_aiinsighttext`}>
          <NewlineText text={aiInsight} />
        </div> */}
        <div className={`comparerReportInfo_Comparinggraphswrapper`}>
          {aigraphMemo}
          <RadarGraph
            selectComparisons={selectComparisons}
            selectedItems={valuesArray}
            userData={portfolio.entries}
            defaultSymbol={String(portfolio.settings.currencySymbol)}
            type={graphState}
            userSettings={portfolio.settings}
            lineColors={lineColorMain}
            metricsToCompare={metricsToCompare}
            riskFreeRate={riskFreeRate}
            allEntries={allEntries}
            processedData={props.processedData}
            className={"AIComparerGraph"}
            showTitle={true}
          />
        </div>
      </div>
      {/* {selectComparisons} */}

      <div className={`comparerReportGraphWrapper`}>{metricsCompareElem}</div>
      <button
        ref={buttonRef}
        className="showcomparisonbutton"
        onClick={(e) => {
          onEllipsisClick(e);
        }}
      >
        <div className="showcomparisonbuttoninner">
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </button>
      {isOpen && (
        <div className="comparisonbuttonwrapper" ref={dropdownRef}>
          <button
            className="togglecomparisonbutton"
            onClick={() => {
              toggleGraphState();
            }}
          >
            {graphState === "line" ? "Show Bar Graphs" : "Show Line Graphs"}
          </button>
          {/*           <button
            className="togglecomparisonbutton"
            onClick={() => {
              toggleGraphState();
            }}
          >
            {graphState === "line" ? "Show Bar Graphs" : "Show Line Graphs"}
          </button> */}
          <button
            className="togglecomparisonbutton"
            onClick={() => {
              handleDeleteClick();
            }}
          >
            {" "}
            Delete Comparison
          </button>
        </div>
      )}
    </div>
  );
};
export default React.memo(ComparisonReport);
