import React, { useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  deleteNOTtNONEVisible,
  isCheckedFunction,
} from "./deleteTradesFunctions";
import $ from "jquery";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";
import DeleteButton from "../../components/deleteButton";
import { DataContext } from "../../DataContext";
import { useAuth0 } from "@auth0/auth0-react";
import putUserData from "../../utils/AWS/putS3UserObject";
import deletePictures from "../../utils/AWS/deletePictures";
import showConfirmedActionButton from "./../../functions/showConfirmedActionButton";
import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";

const DeleteTradesComponent = (props) => {
  const allData = props.userData;
  const createdPortfolios = allData.allData.createdPortfolios;
  const chosenPortfolios = allData.allData.chosenPortfolios;
  const history = useHistory();
  const allGlobalData = useContext(DataContext);
  const { user /* isAuthenticated */ } = useAuth0();
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      var addedType = localStorage.getItem("deletedTrade");
      if (addedType === "Deleted") {
        showConfirmedActionButton("Trades Deleted!");
        localStorage.removeItem("deletedTrade");
      }
    }
  }, []);
  const handleCloseDeleteTrades = () => {
    $("#delete-trades-class").fadeOut(300);
    $("#overlay").fadeOut(300);
  };
  const handleDeleteTrades = async () => {
    store.dispatch(setLoaderState({ loading: true }));
    const isDeleteChecked = document.getElementById("deleteTradeButton");
    const isTagChecked = document.getElementById("tagTradeButton");
    const isShareChecked = document.getElementById("shareTradeButton");
    const isAccessorChecked = document.querySelectorAll("#boxTradeChecks");
    const isEntryIDSChecked = document.querySelectorAll("#entryidvalue");
    $(`.history-table > table > tbody > tr`).css({
      "box-shadow": "",
      backgroundColor: "",
    });
    deleteNOTtNONEVisible(isDeleteChecked, isTagChecked, isShareChecked);
    var entryidsforlambda = [];
    var h = 0;

    for (var i = 0, j = isAccessorChecked.length; i < j; i++) {
      if (isAccessorChecked[i].checked === true) {
        entryidsforlambda[h] = isEntryIDSChecked[i].innerHTML;
        h += 1;
      } else {
        continue;
      }
    }
    let temparr = createdPortfolios;
    let picKeys = [];

    for (var ie = 0, je = createdPortfolios.length; ie < je; ie++) {
      let currentData = createdPortfolios[ie].entries;
      let settingsTemp = createdPortfolios[ie].settings;

      if (createdPortfolios[ie].name === "Default") {
      } else {
        let tempAll = createdPortfolios[ie].name;
        temparr = temparr.filter((x) => x.name !== tempAll);
        for (let ii in entryidsforlambda) {
          // need to create array of pictures to delete as well
          const sliced = currentData.slice();
          const trade = sliced.filter(
            (entry) => entry.entryId === entryidsforlambda[ii]
          );
          trade[0] && picKeys.push(...trade[0].entry.pictures);
          // ------------

          // This is where the trade is actually removed
          let removeIndex = currentData
            .map((item) => item.entryId)
            .indexOf(entryidsforlambda[ii]);
          ~removeIndex && currentData.splice(removeIndex, 1);
          ~removeIndex && (settingsTemp.verified = 0);
        }
        const portfolio = {
          name: createdPortfolios[ie].name,
          assetClasses: createdPortfolios[ie].assetClasses,
          settings: settingsTemp,
          entries: currentData,
        };
        temparr = temparr.concat(portfolio);
      }
    }
    const uploadData = {
      chosenPortfolios: chosenPortfolios,
      createdPortfolios: temparr,
      globalSettings: allData.allData.globalSettings,
      version: Number(allData.allData.version) + 1,
      sharedTrades: allData.allData.sharedTrades,
    };
    $("#delete-trades-class").fadeOut(300);
    $("#overlay").fadeOut(300);
    const S3Data = {
      data: uploadData,
      userId: user.sub,
    };
    const S3PictureDataDelete = {
      data: {
        pictureKeys: picKeys,
      },
    };
    const customerId =
      uploadData === null ? "" : uploadData.globalSettings.stripeId;
    await updateStripeCustomerWrapper(
      customerId,
      uploadData,
      "trades",
      user.sub,
      history
    );

    await deletePictures(S3PictureDataDelete, history);
    await putUserData(S3Data, history);
    allGlobalData.updateAllData(uploadData);
    isCheckedFunction(false, isAccessorChecked);
    store.dispatch(setLoaderState({ loading: false }));
    localStorage.setItem("deletedTrade", "Deleted");
    history.push("/BlankPage");
    history.goBack();
  };
  return (
    <div id="delete-trades-class">
      <span className="deletetradesheading">Delete Entries</span>
      <div>
        {
          "Are you sure you want to delete the selected entries? This CANNOT be undone!"
        }
      </div>
      <button
        className="deletetradesclosebutton"
        onClick={handleCloseDeleteTrades}
      >
        <DeleteButton />
      </button>
      <button className="deletetradenobutton" onClick={handleCloseDeleteTrades}>
        Cancel
      </button>
      <button className="deletetradesubmitbutton" onClick={handleDeleteTrades}>
        Delete
      </button>
    </div>
  );
};

export default DeleteTradesComponent;
